.custom-popup {
  display: block;
}

.popup-content h2 {
  margin-top: 0;
}

.popup-content p {
  margin-bottom: 10px;
}

.popup-content button {
  cursor: pointer;
}

/* screen - fromu47to-dropdown-hover-lare-text-nexa */
/* Custom scrollbar styles */
.custom-popup .mainPopup {
  width: 345.5px;
  max-height: 332px;
  overflow: hidden auto;
}

.custom-popup .mainPopup::-webkit-scrollbar {
  width: 8px;
}

.custom-popup .mainPopup::-webkit-scrollbar-thumb {
  background-color: var(--eerie-black); /* Black color for scrollbar thumb */
  border-radius: 10px;
}

.custom-popup .mainPopup::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Light grey color for scrollbar track */
}

.custom-popup .mainPopup::-webkit-scrollbar-button:single-button {
  background-color: #000; /* Black color for scrollbar buttons */
  display: block;
  height: 16px;
  /* width: 10px !important; */
}

.custom-popup
  .mainPopup::-webkit-scrollbar-button:single-button:vertical:increment {
  background: url(../../../Assets/Images/arrow-1.svg);
}
.custom-popup
  .mainPopup::-webkit-scrollbar-button:single-button:vertical:decrement {
  background: url(../../../Assets/Images/arrow-2.svg);
}

/* Styles for scrollbar button when hovered */
.custom-popup .mainPopup::-webkit-scrollbar-button:hover {
  background-color: #555; /* Darker color for hovered scrollbar buttons */
}

/* Styles for scrollbar button when clicked */
.custom-popup .mainPopup::-webkit-scrollbar-button:active {
  background-color: #777; /* Even darker color for active scrollbar buttons */
}


.fromToPopup .airprot-content {
  width: 338px;
  min-height: 45px;
  padding-left: 12px;
  padding-right: 15px;
  padding-top: 4px;
  padding-bottom: 5px;
  transition: all 0.1s ease;
}
.fromToPopup .airportContentContainer {
display: flex;
min-height: 45px;
}
.fromToPopup .main-head {
padding-bottom: 5px;
}
.fromToPopup .airprot-content p{
 /* margin-top: 1px !important;
 margin-bottom: 1px !important; */
}

.custom-popup .airline-content-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .fromToPopup .airprot-content:hover .airline-content-main{
  border-left: 4px solid var(--endeavour);
  
} */



.fromToPopup .airprot-content .main-content{
  /* margin-left: 5px; */
}

.fromToPopup .airportContentContainer.proxima:hover {
  background-color: var(--light-sky-bg-hov);
}
.fromToPopup .airportContentContainer.averta:hover {
  background-color: var(--light-pale-bg-hov);
}
.fromToPopup .recent-searches {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}
.fromToPopup .recent-searches-2 {
  width: 338px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 12px;
  padding-bottom: 9px;
  height: 39px;
}
.fromToPopup .thin-line {
  /* margin-top: 5px;
  margin-bottom: 5px; */
  height: 0.5px;
  width: 100%;
  margin-left: 0.5px;
  background-color: #e6e6e6;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);  */
}
.fromToPopup .searchWrapper {
  padding-top: 12.5px;
  padding-bottom: 13px;
  padding-left: 9px;
  padding-right: 10px;
}
.fromToPopup .searchwrapper-item {
  align-items: center;
  justify-content: center;
  gap: 8px;
  display: flex;
}

.fromToPopup .bar-line {
position: relative;
}

.fromToPopup .search-input {
  width: 150% !important;
  font-size: medium;
  text-transform: capitalize;
}
.fromToPopup .search-input:focus {
  border: none !important;
  box-shadow: none;
  outline: none !important;
}

.fromToPopup .fromu47to-dropdown-hover-lare-text-nexa {
  align-items: center;
  border-color: transparent;
  border-left-style: solid;
  border-left-width: 2px;
  display: inline-flex;
  gap: 14px;
  min-height: 48px;
  min-width: 342px;
  overflow: hidden;
  padding: 4px 18px 5px 16px;
  position: relative;
  width: 100%;
}

.fromToPopup #overlay-fromu47to-dropdown-hover-lare-text-nexa {
  align-items: center;
  background-color: #ffffff00;
  justify-content: center;
}

.fromToPopup
  .fromu47to-dropdown-hover-lare-text-nexa
  .fromto-dropdown-hover-lare-text-nexa {
  align-items: center;
  background-color: #f3f3f3;
  display: inline-flex;
  gap: 14px;
  padding: 4px 18px 5px 16px;
}

.fromToPopup .fromu47to-dropdown-hover-lare-text-nexa .frame-102 {
  align-items: center;
  justify-content: space-between;
  width: 308px;
}

.fromToPopup .fromu47to-dropdown-hover-lare-text-nexa .frame-103 {
  align-items: flex-start;
  flex-direction: column;
  gap: 1px;
  width: 266px;
}

.fromToPopup .fromu47to-dropdown-hover-lare-text-nexa .indira-gandhi-airpor {
  align-self: stretch;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.fromToPopup .fromu47to-dropdown-hover-lare-text-nexa .frame {
  display: flex;
  position: relative;
}
