.traveller-row {
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
  }
.traveller-row .selection-bar{
   height: 24px;
   width: 4px;
   border-top: 50%;
   border-bottom: 50%;
   background: inherit;
  }
.traveller-row .selection-bar.selected{
   background: var(--black);
  }
.traveller-row .traveller-row-name{
  text-transform: capitalize;
  }
  
.traveller-row .traveller-initials {
  border: 0.6px solid var(--dark-purple);
  height: 34px;
  text-transform: uppercase;
  width: 34px;
  padding: 6px 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  }
  
.traveller-row .text-inital-content {
text-align: center;
display: flex;
text-wrap: nowrap;
  }
  
.traveller-row .traveller-row-content {
  display: flex;
  flex-direction: column;
  gap: 3px;
  }
.traveller-row .traveller-row-data {
  display: flex;
 align-items: center;
  }
.traveller-row .traveller-row-data .price{
 width: max-content;
  }
