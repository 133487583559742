/* .--light-pale-border */

.information-wrapper ul{
    display: block;
    list-style-type: disc;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 15px;
    unicode-bidi: isolate;
}

.information-wrapper .frame-177 {
    align-items: center;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    min-width: 123px;
    padding-bottom: 13px;
    position: relative;
    width: 100%;
}

.information-wrapper .frame-177.proxima{
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-color: var(--spindle);
  }
.information-wrapper .frame-177.averta{
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-color: var(--light-pale-border);
  }

  .information-wrapper .frame-170-2 {
    gap: 10px;
    /* padding: 0px 15px; */
  }

  .information-wrapper .frame-170-3 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    position: relative;
  }
  
  .information-wrapper .important-information {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -3.00px;
    position: relative;
    width: 300px;
  }
  .information-wrapper .frame-2-12 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 25px;
    padding: 15px;
    position: relative;
    width: 100%;
  }
  .information-wrapper .frame-243-19 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
    width: 100%;
  }
  
  .information-wrapper .frame-243-3 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    position: relative;
  }

  .information-wrapper .frame-243-4 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    /* padding: 0px 6px; */
    position: relative;
    width: 100%;
  }
  .information-wrapper .frame-243-5 {
    flex: 0 0 auto;
    position: relative;
  }  

  .information-wrapper .custom-combination {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  .information-wrapper .frame-243-6 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    gap: 7px;
    position: relative;
    width: 100%;
  }

  .information-wrapper .you-may-be-required {
    flex: 1;
    letter-spacing: 0;
    line-height: 20px;
    /* margin-top: -1.00px; */
    position: relative;
  }


  .information-wrapper .frame-244-4 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
    width: 100%;
  }

  .information-wrapper .custom-combination-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  .information-wrapper .frame-269 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--white);
    border-color: var(--spindle);
    border-top-style: solid;
    border-top-width: 1px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 15px;
    padding: 15px 15px 15px 20px;
    position: relative;
    width: 100%;
  }
  .information-wrapper .frame-269.proxima {

    border-color: var(--spindle);
    border-top-style: solid;
    border-top-width: 1px;

  }
  .information-wrapper .frame-269.averta {

    border-color: var(--light-pale-border);
    border-top-style: solid;
    border-top-width: 1px;

  }

  .information-wrapper .buttons {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
    width: 100%;
  }

  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
  }
  
  .information-wrapper .shaketheinfo {
    animation: shake 0.5s ease-in-out infinite;
  }


  .information-wrapper .frame-243-20 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 15px;
    justify-content: center;
    position: relative;
  }
  .information-wrapper .frame-246-4 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 14px;
    position: relative;
    width: 100%;
  }

  .information-wrapper .frame-202-1 {
    cursor: pointer;
    align-items: center;
    border: 1px solid;
    border-color: var(--stack);
    height: 17px;
    width: 17px;
    display: flex;
    flex: 0 0 auto;
    /* gap: 8.1px; */
    align-items: center;
    justify-content: center;
    /* position: relative; */
  }

  
  .information-wrapper .group-46 {
    height: 17.32px;
    position: relative;
    width: 17px;
  }

  .information-wrapper .i-have-read-and-unde {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.80px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .information-wrapper .please-select-this-checkbox-to-proceed {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

