.four-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.four-container img{
   width: 12px;
   height: 12px;
}
.four-container .logo-4{
 margin-right: 2px;
}
.three-container {
    display: flex;
    flex-direction: column;
    width: 20px;
}
.three-container img{
    width: 12px;
    height: 12px;
}
.three-container .logo-3-img-1{
align-self: end;
}
.three-container .logo-3-img-2{
    align-self: right;
}

.two-container {
    width: max-content;
    display: flex;
    flex-direction: column;
}

.two-container img{
    
  width: 15px;
  height: 15px;
}