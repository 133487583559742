.flights-section {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--concrete);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--black);
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    gap: 40px 40px;
    padding: 60px 85px;
    position: relative;
    width: 100%;
    margin-bottom: -28px;
  }

  
.all {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -3.00px;
    position: relative;
    width: fit-content;
  }
  
  .frame-106 {
    align-items: center;
    background-color: var(--white);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 15px 60px;
    position: relative;
  }
  
  .flights-2 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -3.00px;
    position: relative;
    width: fit-content;
  }
  
  .hotels-2 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -3.00px;
    position: relative;
    width: fit-content;
  }
  
  .packages-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -3.00px;
    position: relative;
    width: fit-content;
  }
  
  .arrows-light {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 40px;
    overflow: hidden;
    padding: 40px 20px;
    position: relative;
    width: 100%;
  }

  .rectangle-23 {
    align-self: stretch;
    position: relative;
    width: 235px;
  }
  
  .live-now-republic-d {
    align-self: stretch;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
  }
  
  .economy-from-1999 {
    align-self: stretch;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
  }
  
  .book-now {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    width: fit-content;
  }
  
  .rectangle-23-1 {
    align-self: stretch;
    position: relative;
    width: 228px;
  }
  
  .arrow_left-light {
    background-color: var(--white);
    border: 1px solid;
    border-color: var(--alto);
    border-radius: 20px;
    box-shadow: 0px 3px 8px #0000001f;
    height: 40px;
    left: 0;
    position: absolute;
    top: 120px;
    width: 40px;
  }
  
  .arrow_right-light {
    background-color: var(--white);
    border: 1px solid;
    border-color: var(--alto);
    border-radius: 20px;
    box-shadow: 0px 3px 8px #0000001f;
    height: 40px;
    left: 1302px;
    position: absolute;
    top: 120px;
    width: 40px;
  }
  
  .popular-destinations {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: -1.00px;
    position: relative;
  }