/* screen - month-popup-calendar-months-3-proxima */

.month-popup::-webkit-scrollbar {
    width: 8px;
  }
  
  .month-popup::-webkit-scrollbar-thumb {
    background-color: var(--eerie-black); /* Black color for scrollbar thumb */
    border-radius: 10px;
  }
  
  .month-popup::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Light grey color for scrollbar track */
  }
  
  /* .month-popup::-webkit-scrollbar-button:single-button {
    background-color: #000;
    display: block;
    height: 16px;
  }
  
  .month-popup
::-webkit-scrollbar-button:single-button:vertical:increment {
    background: url(../../../../../../Assets/Images/arrow-1.svg);
  }
  .month-popup
::-webkit-scrollbar-button:single-button:vertical:decrement {
    background: url(../../../../../../Assets/Images/arrow-2.svg);
  } */
  
  /* Styles for scrollbar button when hovered */
  .month-popup::-webkit-scrollbar-button:hover {
    background-color: #555; /* Darker color for hovered scrollbar buttons */
  }
  
  /* Styles for scrollbar button when clicked */
  .month-popup::-webkit-scrollbar-button:active {
    background-color: #777; /* Even darker color for active scrollbar buttons */
  }

.month-popup-calendar-months-3-proxima {
    border-right: none;
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    padding: 0px 4px 4px;
    position: relative;
  }
  
  .month-popup-calendar-months-3-proxima .month-popup-atoms-mouth {
    align-items: center;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    gap: 5px;
    height: 32.54px;
    justify-content: center;
    padding: 2.48px 9.9px;
    position: relative;
    width: 100%;
  }
  
  .month-popup-calendar-months-3-proxima .month-popup-text {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .month-popup-calendar-months-3-proxima .month-popup-arrow {
    flex: 0 0 auto;
    position: relative;
  }
  
  .month-popup-calendar-months-3-proxima .month-popup-months {
    align-items: center;
    background-color: var(--white);
    /* box-shadow: 0px 1px 4px #00000040; */
    display: inline-flex;
    flex: 0 0 auto;
    padding: 0px 0px 5px;
    position: relative;
  }


  .month-popup-calendar-months-3-proxima .month-popup-frame-147 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .month-popup-calendar-months-3-proxima .month-popup-frame-1 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    padding: 12px 20px;
    width: 100%;
  }
  .month-popup-calendar-months-3-proxima .month-popup-frame-1.proxima:hover {
    
    background-color: var(--light-sky-bg-hov);
  }
  .month-popup-calendar-months-3-proxima .month-popup-frame-1.averta:hover {
    
    background-color: var(--light-pale-bg-hov);
  }
  
  .month-popup-calendar-months-3-proxima .month-popup-uary-2024 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .month-popup-calendar-months-3-proxima .month-popup-frame-148 {
    align-items: flex-start;
    background-color: #f3f3f3;
    display: inline-flex;
    justify-content: center;
    padding: 12px 20px;
  }
  
  .month-popup-calendar-months-3-proxima .month-popup-march-2024 {
    flex: 1;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
  }
  
  .month-popup-calendar-months-3-proxima .month-popup-name {
    flex: 1;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
  }
  
  .month-popup-calendar-months-3-proxima .month-popup-frame-204 {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .month-popup-calendar-months-3-proxima .month-popup-frame-204-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
  }
  
  .month-popup-calendar-months-3-proxima .month-popup-rectangle-14 {
    background-color: var(--eerie-black);
    cursor: pointer;
    height: 109px;
    position: relative;
    width: 5px;
  }
  
  .month-popup-calendar-months-3-proxima .month-popup-arrow-1 {
    height: 13px;
    position: relative;
  }
  
  .month-popup-calendar-months-3-proxima .month-popup-frame {
    flex: 0 0 auto;
    position: relative;
  }
  
  .month-popup-calendar-months-3-proxima .month-popup-name-3 {
    flex: 1;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
  }
  