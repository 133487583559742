




.filters-section .frame-129 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.filters-section .frame-176 {
  cursor: pointer;
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--magic-potion-2);
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  justify-content: center;
  padding: 8px 20px;
  position: relative;
}
.filters-section .frame-176-2 {
  cursor: pointer;
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  border: 1.5px solid;
  display: flex;
  flex: 1;
  flex-grow: 1;
  height: 41px;
  gap: 8px;
  justify-content: center;
  padding: 15px 20px;
  position: relative;
}

.filters-section .frame-176-2.proxima {
  border-color: var(--endeavour);
}
.filters-section .frame-176-2.averta {
  border-color: var(--burning-gold);
}

.filters-section .onward {
  color: var(--shady-lady);
  font-family: var(--font-family-proxima_nova-semibold);
  font-size: var(--font-size-16);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -3.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.filters-section .frame-175-5 {
  cursor: pointer;
  align-items: center;
  height: 49px !important;
  border: 1.5px solid;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  justify-content: center;
  padding: 15px 20px;
  position: relative;
}
.filters-section .frame-175-5.proxima {
  background-color: var(--white);
  border-color: var(--endeavour);
}
.filters-section .frame-175-5.averta {
  background-color: var(--white);
  border-color: var(--burning-gold);
}

.filters-section .return {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -3.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}


.filters-section .frame-260 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
    width: 100%;
  }
  
  .filters-section .frame-104-3 {
    align-items: flex-start;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    gap: 20px;
    padding: 20px 0px;
    width: 100%;
  }

  .filters-section .frame-104-5 {
    flex-direction: column;
    position: relative;
  }

  .filters-section .frame-175 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 0px 20px 0px 0px;
    position: relative;
    width: 100%;
  }

  .filters-section .frame-201 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 0px 0px 0px 15px;
    position: relative;
  }
  
  .filters-section .popular-filters {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -3.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .filters-section .frame-1 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    padding: 0px 15px;
    position: relative;
    width: 100%;
  }
  
  .filters-section .frame-201-6 {
    cursor: pointer;
    align-items: center;
    align-self: stretch;
    display: flex;
    height: 21.4px;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }


  .filters-section .morning-image {
    content: url('../../../../../Assets/Images/morning-normal.svg');
  }
  .filters-section .morning-image.proxima:hover {
    content: url('../../../../../Assets/Images/morning-hovered.svg');
  }
  .filters-section .morning-image.proxima.selected {
    content: url('../../../../../Assets/Images/morning-selected.svg');
  }
  .filters-section .morning-image.averta:hover {
    content: url('../../../../../Assets/Images/morning-hovered-gold.svg');
  }
  .filters-section .morning-image.averta.selected {
    content: url('../../../../../Assets/Images/morning-selected-gold.svg');
  }
  
    .filters-section .afternoon-image {
    content: url('../../../../../Assets/Images/afternoon-normal.svg');
  }
    .filters-section .afternoon-image.proxima:hover {
    content: url('../../../../../Assets/Images/afternoon-hovered.svg');
  }
    .filters-section .afternoon-image.proxima.selected {
    content: url('../../../../../Assets/Images/afternoon-selected.svg');
  }
    .filters-section .afternoon-image.averta:hover {
    content: url('../../../../../Assets/Images/afternoon-hovered-gold.svg');
  }
    .filters-section .afternoon-image.averta.selected {
    content: url('../../../../../Assets/Images/afternoon-selected-gold.svg');
  }
  
   .filters-section .evening-image {
    content: url('../../../../../Assets/Images/evening-normal.svg');
  }
   .filters-section .evening-image.proxima:hover {
    content: url('../../../../../Assets/Images/evening-hovered.svg');
  }
   .filters-section .evening-image.proxima.selected {
    content: url('../../../../../Assets/Images/evening-selected.svg');
  }
   .filters-section .evening-image.averta:hover {
    content: url('../../../../../Assets/Images/evening-hovered-gold.svg');
  }
   .filters-section .evening-image.averta.selected {
    content: url('../../../../../Assets/Images/evening-selected-gold.svg');
  }
  
   .filters-section .night-image {
    content: url('../../../../../Assets/Images/night-normal.svg');
  }
   .filters-section .night-image.proxima:hover {
    content: url('../../../../../Assets/Images/night-hovered.svg');
  }
   .filters-section .night-image.proxima.selected {
    content: url('../../../../../Assets/Images/night-selected.svg');
  }
   .filters-section .night-image.averta:hover {
    content: url('../../../../../Assets/Images/night-hovered-gold.svg');
  }
   .filters-section .night-image.averta.selected {
    content: url('../../../../../Assets/Images/night-selected-gold.svg');
  }

  

  
  .filters-section .frame-1-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
  }
  
  .filters-section .frame {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  .filters-section .frame-3 {
    align-items: center;
    border: 1px solid;
    border-color: var(--stack);
    display: flex;
    /* flex: 0 0 auto; */
    align-self: center;
    align-items: center;
    /* gap: 10px; */
    /* position: relative; */
    height: 21.4px;
    /* position: relative; */
    width: 21.4px;
    justify-content: center;
  }

  .filters-section .group-46 {
  }

  .filters-section .check {
    height: 15px;
    left: 4px;
    position: absolute;
    top: 3px;
    width: 14px;
  }

  .filters-section .non-stop-flights {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.filters-section .vector {
  height: 10.99px;
  position: relative;
  width: 9px;
}

.filters-section .number {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .filters-section .frame-4 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 2px;
    position: relative;
  }
  
  .filters-section .frame-201-7 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  

  .filters-section .frame-232-1 {
    align-self: stretch;
    background-color: var(--white);
    flex-direction: column;
    width: 100%;
  }


  .filters-section .frame-232-3 {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    position: relative;
  }



    
  .filters-section .frame-1-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 35px;
    padding: 30px 0px 15px;
    position: relative;
    width: 100%;
  }

  .filters-section .one-way-price {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -3.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .filters-section .frame-170 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    padding: 0px 15px;
    position: relative;
    width: 100%;
  }
  
  .filters-section .frame-170-1 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--alto);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
  }
  
  /* .filters-section .frame-169 {
    align-self: stretch;
    flex: 0 0 auto;
    position: relative;
    width: 100%;
  } */
  
  .filters-section .frame-175-1 {
    align-items: center;
    display: flex;
    width: 100%;
    /* left: 22px; */
    position: relative;
    /* top: -10px; */
    /* width: 159px; */
  }
  
  .filters-section .frame-201-1 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    position: relative;
    width: 100%;
    justify-content: center;
  }
  
  .filters-section .frame-178 {
    background-color: var(--white);
    border-radius: 21.34px;
    box-shadow: 0px 0px 2.93px 2.2px #00144629;
    position: relative;
    width: 22px;
  }
  
  .filters-section .frame-177 {
    background-color: var(--white);
    border-radius: 21.34px;
    box-shadow: 0px 0px 2.93px 2.2px #00144629;
    position: relative;
    width: 22px;
  }

  
.line-29 {
    flex: 1;
    flex-grow: 1;
    height: 2px;
    position: relative;
  }

  .filters-section .frame-202-7 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .filters-section .frame-5 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    position: relative;
  }
  
  .filters-section .frame-170-2 {
    align-items: center;
    background-color: var(--white);
    border-radius: 2px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    position: relative;
  }
  
  .filters-section .number-5 {
    margin-top: -0.50px;
  }

  .filters-section .vector-1 {
    height: 12.6px;
    position: relative;
    width: 10.32px;
  }

  .filters-section .number-34 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .filters-section .frame-1-3 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    padding: 30px 0px 15px;
    position: relative;
    width: 100%;
  }

.filters-section .frame-201-2 {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 21px;
  padding: 0px 15px;
  position: relative;
  width: 294px;
}

.filters-section .departure-time {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.50px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .filters-section .before-6 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .filters-section .am {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .filters-section .frame-20 {
    align-items: flex-start;
    cursor: pointer;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    padding: 0px 15px;
    position: relative;
    width: 100%;
  }

  .filters-section .frame-168 {
    align-items: center;
    align-self: stretch;
    /* background-color: var(--pumice); */
    border: 2.5px solid var(--pumice);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  .filters-section .frame-175-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 2px;
    position: relative;
    width: 100%;
  }

  .filters-section .frame-1-4 {
    align-items: center;
    background-color: var(--white);
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 3px 5px;
    position: relative;
    border-right: 2.5px solid var(--pumice);
    /* border: 2px solid var(--pumice); */
  }
  .filters-section .frame-1-4-1 {
    align-items: center;
    background-color: var(--white);
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 3px 5px;
    position: relative;
    /* border: 2px solid var(--pumice); */
  }
  .filters-section .frame-1-5 {
    height: 46.6px;
    position: relative;
    width: 46.6px;
  }
  .filters-section .frame-1-6 {
    font-size: x-small;
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 1px;
    justify-content: center;
    position: relative;
  }
  
  .filters-section .frame-17 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  .filters-section .layover-airports {
    margin-top: -1.50px;
  }

  .filters-section .frame-203 {
    cursor: pointer;
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 3px;
    height: 21px;
    justify-content: flex-end;
    position: relative;
    width: 100%;
  }
  .filters-section .plus {
    flex: 0 0 auto;
    position: relative;
  }
  
  .filters-section .address-1 {
    margin-top: -0.50px;
  }

  .filters-section .address-19 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .filters-section .frame-117 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    padding: 30px 15px;
    position: relative;
    width: 100%;
  }

  .filters-section .frame-201-11 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 100%;
  }
  
  .filters-section .frame-202-10 {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 10px;
    height: 21px;
    position: relative;
    width: 100%;
  }
  
  .filters-section .frame-6 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    position: relative;
    width: 100%;
  }

  .filters-section .airlines {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.50px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .filters-section .frame-17-1 {

    align-items: flex-start;
    gap: 10px;
  }
  
  .filters-section .frame-7 {
    cursor: pointer;
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    justify-content: space-between;
    position: relative;
  }

  .filters-section .frame-17-3 {
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    position: relative;
    width: 100%;
  }
  .filters-section .frame-20-1 {
    align-items: flex-start;
    gap: 8px;
  }
  
  .filters-section .unnamed-1 {
    height: 18.17px;
    width: 18.17px;
  }
  
  .filters-section .number-21 {
    margin-top: -1.00px;
  }

  .filters-section .frame-20-3 {
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
    /* margin-left: 5px; */
  }
  .filters-section .unnamed-5 {
    object-fit: cover;
    position: relative;
  }
  .filters-section .frame-201-3 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 1px;
    position: relative;
  }

  .filters-section .air-india {
    letter-spacing: 0;
    line-height: normal;
    margin-top: 3px;
    position: relative;
    white-space: nowrap;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;

  }

  















