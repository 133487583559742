.dateselector-wrapper {
  display: flex;
  flex-direction: column;
}

.dateselector {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 8px;
  padding-bottom: 16px;
  transition: border-color 0.3s, color 0.3s;
}
.dateselector.error {
  border: 1px solid var(--error-red);
}

.dateselector.error:hover {
  border: 1px solid var(--error-red);
}
.dateselector.proxima:hover {
  border: 1px solid rgba(0, 0, 0, 1);
}
.dateselector.averta:hover {
  border: 1px solid #C2A07C;
}

.dateselector .headbar {
  width: max-content;
  position: relative;
  top: -14px;
  background: white;
  padding-left: 5px;
  padding-right: 5px;
}
.dateselector .headbar.proxima {
  color: rgba(0, 0, 0, 0.8);
}
.dateselector .headbar.averta {
  color: #C2A07C;
}
.dateselector .headbar.error {
  color: var(--error-red);
}
.dateselector .headbar.error:hover {
  color: var(--error-red);
}
.dateselector .headbar:hover {
  color: rgba(0, 0, 0, 1);
}

/* Active state */
.dateselector.active.proxima {
  border-color: #1976d2;
}
.dateselector.active.averta {
  border-color: #C2A07C;
}
.dateselector.active.error {
  border-color: var(--error-red);
}
.dateselector.active .headbar.proxima {
  color: #1976d2;
}
.dateselector.active .headbar.averta {
  color:  #C2A07C;
}
.dateselector.active .headbar.error {
  color: var(--error-red);

}
.dateselector .databar {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.dateselector-wrapper .errorbar {
  font-size: small;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.dateselector .section {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.dateselector .dropdown {
  position: absolute;
  text-align: center;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin-top: 38px;
}

/* Custom Scrollbar */
.dateselector .dropdown::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.dateselector .dropdown::-webkit-scrollbar-track {
  background: var(--mystic); /* Color of the scrollbar track */
  border-radius: 10px;
}

.dateselector .dropdown::-webkit-scrollbar-thumb {
  background-color: var(--rhino); /* Color of the scrollbar thumb */
  border-radius: 10px;
  border: 2px solid var(--mystic); /* Adds space around the thumb */
}

.dateselector .dropdown::-webkit-scrollbar-thumb:hover {
  background-color: var(
    --rhino
  ); /* Color when hovering over the scrollbar thumb */
}

/* Scrollbar Buttons (Up Arrow) */
.dateselector .dropdown::-webkit-scrollbar-button:vertical:decrement {
  background: url("../../../../../Assets/Images/scroll-arrow-up-df.svg")
    no-repeat center center;
  /* background-size: 100% 100%; */
  transform: rotate(90deg);
  height: 16px;
  width: 10px;
  cursor: pointer;
}

/* Scrollbar Buttons (Down Arrow) */
.dateselector .dropdown::-webkit-scrollbar-button:vertical:increment {
  background: url("../../../../../Assets/Images/scroll-arrow-down-df.svg")
    no-repeat center center;
  background-size: 100% 100%;
  height: 16px;
  width: 10px;
  cursor: pointer;
}

.dateselector .dropdown-date {
  margin-left: -10px;
  width: 70px;
}

.dateselector .dropdown-month {
  margin-left: 0px;
  width: max-content;
}
.dateselector .dropdown-year {
  margin-left: 0px;
}

.dateselector .dd-arrow{
  /* max-width: fit-content; */
  width: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
  /* max-height: fit-content; */
}

.dateselector .dd-arrow.open {
  transform: rotate(180deg);
}

.dateselector .dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
}

.dateselector .dropdown-item:hover {
  background-color: #f0f0f0;
}
