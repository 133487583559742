.main-section-wrapper {
    display:flex;
    flex-direction:column; 
    gap:20px;
    width:75%;
    padding-bottom: 20px;
  
  }
  
.main-section-wrapper .travellers-info-section {
  display: flex;
  background-color: var(--dim-sunlight);
  margin-bottom: 15px;
  padding: 8px 12px;
  align-items: end;
  gap: 10px;
  
  }

  .main-section-wrapper .continue-section .info-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
  }


.main-section-wrapper .travellers-wrapper {
  margin-bottom: 20px;
  }
.main-section-wrapper .travellers-wrapper.proxima {
  border-bottom: 1px solid var(--spindle);
  }
.main-section-wrapper .travellers-wrapper.averta {
  border-bottom: 1px solid var(--light-pale-border);
  }
  
  .FD-wrapper.proxima {
    padding: 20px;
    border-left: 1px solid var(--mystic);
    border-right: 1px solid var(--mystic);
    border-bottom: 1px solid var(--mystic);
  }
  .FD-wrapper.averta {
    padding: 20px;
    border-left: 1px solid var(--light-pale-border);
    border-right: 1px solid var(--light-pale-border);
    border-bottom: 1px solid var(--light-pale-border);
  }
  
  
  
  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
  }
  
  .continue-section.shake{
    animation: shake 0.5s ease-in-out infinite;
  
  }
  
  .continue-section .frame-259 {
    align-items: center;
    /* border: 1px solid; */
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: center;
    padding: 17px 34px;
    position: relative;
    cursor: pointer;
  }
  .continue-section .frame-259.proxima {
    background-color: var(--endeavour);
  }
  .continue-section .frame-259.averta {
    background-color: var(--text-pale-bg);
  }
  .continue-section .continue {
    display: flex;
    flex-direction: row;
    gap: 20px;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;

  }

  .main-section-wrapper .continue-section { 
    height: 45px;
    align-items: center;
    display: flex;
    gap: 10px;
    padding: 0px 2px;
    justify-content: space-between;
    flex: 0 0 auto;
    position: relative;
  }

  .valign-text-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .others-section {
    cursor:not-allowed;
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 15px;
    position: relative;
    width: 100%;
  }
  .others-section.proxima {
  
    background-color: var(--geyser);
  
  }
  .others-section.averta {
  
    background-color: var(--multani-mitti);
  
  }