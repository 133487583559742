.contact-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }
  .contact-details .header{
    padding-top: 10px;
  }
  .contact-details .contact{
    display: flex;
    gap : 15px;
  }

  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
  }
  
  .contact-details.shake {
    animation: shake 0.5s ease-in-out infinite;
  }
