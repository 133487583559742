.gst-details{
    padding-top: 20px;
    padding-bottom: 20px;
}


@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
  }
  
  .gst-details.shake {
    animation: shake 0.5s ease-in-out infinite;
  }

.gst-check{
    cursor: pointer;
}

.gst-details-bar {
    display: flex;
    gap: 10px;
    align-items: center;
}

.gst-details .gst{
  display: flex;
  gap: 15px;
}

.gst-details-container {
    padding: 25px 15px;
    max-height: max-content;
}
.gst-details-container .wrapper{
    display: flex;
    gap: 25px;
    flex-direction: column;
}