@keyframes progressAnimation {
     from{
        width: 5%;
     }
     to{
        width: 100%;
     }
  }
  
  .progressbar-aKn7aU {
    z-index: 1;
    align-items: center;
    align-self: stretch;
    display: flex;
    height: 5px;
    opacity: 1;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  
  .value-WUZgUu {
    z-index: 100000;
    align-self: stretch;
    opacity: 1;
    position: relative;
    width: 5%; /* Initial width */
    animation: progressAnimation 5s infinite ease-in-out; /* Apply animation */
  }
  .value-WUZgUu.proxima {
    background-color: var(--robins-egg-blue);
  }
  .value-WUZgUu.averta {
    background-color: var(--medium-pale-border);
  }
  
  .loading-container {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 50vh;
    background-color: #f5f5f5;
    color: #333;
  }

  
  .loading-message {
    margin-bottom: 20px;
    font-weight: bold;
  }
