.ff-content {
    align-items: flex-start;
    background-color: var(--white);
    display: inline-flex;
    flex: 0 0 auto;
    border: 0.6px solid;
    flex-direction: column;
    margin-left: -0.60px;
    overflow: hidden;
    position: relative;
    width: 350px;
  }
.ff-content.proxima {
    border-color: var(--rhino);
  }
.ff-content.averta {
    border-color: var(--light-pale-border);
  }
.ff-content.proxima.selected {
  box-shadow: 0px 1px 6px 1px #0057b766;
  }
.ff-content.averta.selected {
  box-shadow: 0px 1px 6px 1px var(--gold-shadow);
  }
  
.ff-content .frame-238-8 {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 351.73px;
  }

  
.ff-content .frame-241-7 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 12px;
    padding: 13.5px 20px;
    position: relative;
    width: 100%;
    cursor: pointer;
  }

.ff-content .frame-241-7.proxima {
    background-color: var(--tropical-blue-3);
    border-bottom-style: solid;
    border-bottom-width: 0.8px;
    border-color: var(--endeavour);
  }
.ff-content .frame-241-7.averta {
    background-color: var(--light-pale-bg);
    border-bottom-style: solid;
    border-bottom-width: 0.8px;
    border-color: var(--light-pale-border);
  }

.ff-content .frame-241-7-2 {
    cursor: pointer;
    align-items: center;
    align-self: stretch;
    border-bottom-style: solid;
    border-bottom-width: 0.8px;
    border-color: var(--endeavour);
    display: flex;
    flex: 0 0 auto;
    gap: 12px;
    padding: 13.5px 20px;
    position: relative;
    width: 100%;
  }

.ff-content .frame-241-7-2.proxima {
    border-bottom-style: solid;
    border-bottom-width: 0.8px;
    border-color: var(--endeavour);
  }
.ff-content .frame-241-7-2.averta {
    border-bottom-style: solid;
    border-bottom-width: 0.8px;
    border-color: var(--light-pale-border);
  }

  
.ff-content .frame-173-3 {
    align-items: center;
    display: flex;
    gap: 8.72px;
    height: 23.33px;
    position: relative;
    width: 22.89px;
  }

  
  .ff-content .rectangle-17-1 {
    border: 1.09px solid;
    border-color: var(--endeavour);
    border-radius: 28.89px;
    height: 23.33px;
    position: relative;
    width: 22.89px;
  }

  
  .ff-content .ellipse-22 {
    background-color: var(--endeavour);
    border-radius: 5.9px;
    height: 12px;
    left: 6px;
    position: absolute;
    top: 6px;
    width: 12px;
  }
  
  .ff-content .frame-24-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 2px;
    position: relative;
    width: 253.63px;
  }

  .ff-content .frame-173 {
    align-items: normal;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: flex-end;
    position: relative;
  }

  .ff-content .frame-241 {
    display: flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
  }

  .ff-content .frame-169 {
    flex: 0 0 auto;
    position: relative;
  }

  .ff-content .text-319 {
    margin-top: -1.00px;
    position: relative;
    width: fit-content;
  }
  
  .ff-content .valign-text-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  
  .ff-content .per-adult {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -2.74px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .ff-content .flexi-economy {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .ff-content .vector-2 {
    height: 6px;
    margin-right: -2.39px;
    position: relative;
    width: 12px;
  }

  
  .ff-content .frame-241-1 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    padding: 0px 18px 0px 16px;
    position: relative;
    width: 100%;
  }
  

  
  .ff-content .frame-242-13 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 7px;
    padding: 14px 0px;
    position: relative;
    width: 100%;
  }
  .ff-content .frame-242-13.proxima {
    border-bottom-style: solid;
    border-bottom-width: 0.8px;
    border-color: var(--tropical-blue-4);
  }
  .ff-content .frame-242-13.averta {
    border-bottom-style: solid;
    border-bottom-width: 0.8px;
    border-color: var(--light-pale-border);
  }

  .ff-content .frame-24-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
    width: 100%;
  }

  
  .ff-content .baggage {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .ff-content .frame-24-3 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    padding: 0px 5px;
    position: relative;
  }
  

  
  .ff-content .frame-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }

  .ff-content .check-1 {
    flex: 0 0 auto;
    position: relative;
  }

  .ff-content .check-in-46-kgs-2-pieces-x-23-kgs {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .ff-content .frame-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }

  
  .ff-content .check-2 {
    flex: 0 0 auto;
    position: relative;
  }

  
.ff-content .cabin-7-kgs {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  
.ff-content .frame-241-8 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 7px;
    padding: 14px 0px;
    position: relative;
    width: 100%;
  }
.ff-content .frame-241-8.proxima {
    border-bottom-style: solid;
    border-bottom-width: 0.8px;
    border-color: var(--tropical-blue-4);
  }
.ff-content .frame-241-8.averta {
    border-bottom-style: solid;
    border-bottom-width: 0.8px;
    border-color: var(--light-pale-border);
  }

  .ff-content .frame-24-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
    width: 100%;
  }

  .ff-content .fare {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  .ff-content .frame-24-3 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    padding: 0px 5px;
    position: relative;
  }

  .ff-content .frame-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }
  
  .ff-content .check-3 {
    flex: 0 0 auto;
    position: relative;
  }

  
  .ff-content .frame-2-7 {
    align-items: center;
    display: inline-flex;
    gap: 4px;
  }

  .ff-content .frame-2-10 {
    flex: 0 0 auto;
    position: relative;
  }

  .ff-content .free {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  
  .ff-content .cancellation {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  
  .ff-content .frame-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }

  
  .ff-content .check-4 {
    flex: 0 0 auto;
    position: relative;
  }

  .ff-content .non-changeable-date {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .ff-content .frame-243-1 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding: 14px 0px;
    width: 100%;
  }
  

  .ff-content .frame-243-6 {
    align-items: flex-start;
    flex: 0 0 auto;
    position: relative;
  }

  
.ff-content .frame-24-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
    width: 100%;
  }

  
  .ff-content .seats-meals-more {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  
  
.ff-content .frame-24-3 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    padding: 0px 5px;
    position: relative;
  }

  .ff-content .frame-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }

  
  .ff-content .check-5 {
    flex: 0 0 auto;
    position: relative;
  }
  

  .ff-content .frame-2-7 {
    align-items: center;
    display: inline-flex;
    gap: 4px;
  }

  .ff-content .free-7 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .ff-content .seats {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .ff-content .frame-24-4 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
    width: 100%;
  }

  .ff-content .check-6 {
    flex: 0 0 auto;
    position: relative;
  }

  .ff-content .complimentary-5 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .ff-content .frame-231 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: flex-end;
    padding: 5px 20px 13px;
    position: relative;
    width: 100%;
  }

  .ff-content .frame-230-1 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 8px;
    padding: 10px;
    position: relative;
  }
  .ff-content .frame-230-1.proxima {
    background-color: var(--mystic);
  }
  .ff-content .frame-230-1.averta {
    background-color: var(--light-pale-bg);
  }

  .ff-content .frame-2-8 {
    align-items: center;
    display: inline-flex;
    gap: 5px;
    padding: 0px 0px 7px;
  }
  

  .ff-content .group-227 {
    height: 14.9px;
    position: relative;
    width: 18px;
  }
  
  .ff-content .group-container {
    height: 15px;
    position: relative;
  }
  
  .ff-content .group-226 {
    align-items: flex-start;
    display: flex;
    height: 8px;
    justify-content: flex-end;
    left: 1px;
    min-width: 16px;
    position: absolute;
    top: 3px;
    transform: rotate(-26.17deg);
  }

  .ff-content .overlap-group-2 {
    height: 20px;
    margin-top: -6.21px;
    position: relative;
    width: 22px;
  }

  .ff-content .vector-90 {
    height: 14px;
    left: 2px;
    position: absolute;
    top: 3px;
    transform: rotate(26.17deg);
    width: 18px;
  }

  
.ff-content .group-224 {
    align-items: flex-start;
    display: flex;
    gap: 1px;
    height: 7px;
    left: 9px;
    min-width: 10px;
    padding: 0.3px 0;
    position: absolute;
    top: 7px;
  }
  
  .ff-content .line-65 {
    height: 6px;
    margin-left: -1px;
    transform: rotate(26.17deg);
    width: 3px;
  }

  .ff-content .text-32 {
    align-self: center;
    margin-bottom: 0.4px;
    min-height: 4px;
    min-width: 5px;
    text-align: right;
  }
  

  
  .ff-content .frame-209 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    position: relative;
  }
  
  .ff-content .frame-211 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: 5px 6px;
    position: relative;
  }
  
  .ff-content .frame-203 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    gap: 5px 5px;
    position: relative;
  }

  .ff-content .use-code-stdeals-for {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  
  .ff-content .frame-2-3 {
    align-items: center;
    display: inline-flex;
    gap: 3px;
  }

  .ff-content .text-3-1 {
    -webkit-text-stroke: 1px var(--black);
    margin-left: -1.00px;
    margin-top: -2.00px;
    position: relative;
    text-align: center;
    width: fit-content;
  }

  
  .ff-content .text-3-3 {
    letter-spacing: 0;
    line-height: normal;
  }
  .ff-content .frame-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    position: relative;
  }

  .ff-content .get-150-off-on-upi-payments {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
