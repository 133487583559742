.flight-detail-content {
    align-items: center;
    justify-content: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
    /* margin-left: 20px; */
}

.flight-detail-content .flight-content {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--black-haze);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 20px;
  width: 100%;
  flex: 0 0 auto;
  position: relative;
}

.flight-detail-content .frame-242-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 100%;
}

.flight-detail-content .frame-238 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 60px;
  position: relative;
  width: 100%;
}

.flight-detail-content .frame-238-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 50px;
  position: relative;
}

.flight-detail-content .frame-104 {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7px;
  justify-content: center;
  position: relative;
}

.flight-detail-content .frame-103 {
  /* align-items: center; */
  display: inline-flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  position: relative;
}

.flight-detail-content .frame-168 {
  align-items: center;
  align-self: stretch;
  /* display: flex; */
  gap: 8px;
  position: relative;
  width: 129px;
}
.flight-detail-content .line-through {

    height: 1px;
    background-color: var(--pumice);
    width: 15px;
}

.flight-detail-content .frame-245 {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  margin-right: -10px;
  position: relative;
}

.flight-detail-content .layover-img {
margin-left: -4px;
}

.flight-detail-content .width-of-img {
width: 30px;
}
.flight-detail-content .frame-237-2 {
display: flex;
gap: 10px;
align-items: baseline;
}
.flight-detail-content .frame-237-2-2 {
display: flex;
align-items: baseline;
gap: 6px;
}

.flight-detail-content .frame {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2px;
  position: relative;
}

.flight-detail-content .air-india {
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flight-detail-content .frame-1 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  position: relative;
}

.flight-detail-content .ai-123 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.flight-detail-content .frame-245-1 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
}

.flight-detail-content .frame-176 {
  align-items: center;
  align-self: stretch;
  border-color: var(--eerie-black);
  border-right-style: solid;
  border-right-width: 0.8px;
  display: flex;
  position: relative;
  width: 1px;
}

.flight-detail-content .frame {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2px;
  position: relative;
}

.flight-detail-content .airbus-a321 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.flight-detail-content .frame-174-1 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  padding: 1px 0px;
  position: relative;
}

.flight-detail-content .operated-by-air-canada-4 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flight-detail-content .frame-237 {
  align-items: baseline;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
}

.flight-detail-content .frame-242-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  width: 233px;
}

.flight-detail-content .frame-2-2 {
  align-items: baseline;
  display: inline-flex;
  gap: 8px;
}


.flight-detail-content .text-311 {
  margin-top: -1px;
  position: relative;
  width: fit-content;
}
.flight-detail-content .text {
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.flight-detail-content .tue-30-jan-2024-8 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.flight-detail-content .frame-2-3 {
  align-items: center;
  display: inline-flex;
  gap: 3px;
}


.flight-detail-content .del-4 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.flight-detail-content .new-delhi-india-4 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}


.flight-detail-content .frame-237-1 {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  margin-left: -12.37px;
  padding: 12px 0px 0px;
  position: relative;
}

.flight-detail-content .frame-170 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7px;
  justify-content: center;
  position: relative;
}

.flight-detail-content .line {
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 13px;
}

.flight-detail-content .frame-202 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  justify-content: center;
  padding: 0px 0px 0px 1.5px;
  position: relative;
}

.flight-detail-content .group-206 {
  height: 10.45px;
  position: relative;
  width: 10.45px;
}

.flight-detail-content .flight-detail-content .overlap-group {
  border: 0.79px solid;
  border-color: var(--eerie-black);
  border-radius: 6.01px;
  height: 12px;
  justify-content: flex-end;
  min-width: 12px;
  padding: 2.4px 4.9px;
}

.flight-detail-content .overlap {
  align-items: flex-start;
  display: flex;
  left: -1px;
  position: relative;
  top: -1px;
}

.flight-detail-content .vector-50 {
  height: 5px;
  width: 2px;
}

.flight-detail-content .x2h-40m {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.28px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.flight-detail-content .frame-243 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: -12.37px;
  position: relative;
  width: 233px;
}

.flight-detail-content .text-312 {
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.flight-detail-content .tue-30-jan-2024-8 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.flight-detail-content .frame-2-3 {
  align-items: center;
  display: inline-flex;
  gap: 3px;
}

.flight-detail-content .tvr-4 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.flight-detail-content .thiruvanathapuram-india-4 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.flight-detail-content .indira-gandhi-international-terminal-2-8 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flight-detail-content .frame-2-4 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--black-haze);
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 0px 20px;
  width: 100%;
}

.flight-detail-content .frame-248 {
  align-items: center;
  align-self: stretch;
  border-top-style: solid;
  border-top-width: 0.8px;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  padding: 15px 0px 10px 10px;
  position: relative;
  width: 100%;
}
.flight-detail-content .frame-248.proxima {
  border-color: var(--tropical-blue-2);
}
.flight-detail-content .frame-248.averta {
  border-color: var(--light-pale-border);
}
.flight-detail-content .frame-248-8 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  padding: 0px 0px 1px 10px;
  position: relative;
  width: 100%;
}

.flight-detail-content .frame-249 {
  align-items: center;
  display: flex;
  gap: 30px;
  position: relative;
  width: 716.02px;
}
.flight-detail-content .frame-249-1 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 8px;
  position: relative;
  width: 73.26px;
}

.flight-detail-content .group-246 {
  height: 19px;
  position: relative;
  width: 13.26px;
}

.flight-detail-content .group-248 {
  align-items: flex-start;
  display: flex;
  height: 19px;
  min-width: 13px;
}

.flight-detail-content .frame-246 {
  align-items: center;
  display: inline-flex;
  gap: 5px;
  position: relative;
}

.flight-detail-content .frame-247 {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 13.26px;
}

.flight-detail-content .rectangle-76 {
  border-color: var(--endeavour);
  border-left-style: solid;
  border-left-width: 0.6px;
  border-radius: 1.09px 1.09px 0px 0px;
  border-right-style: solid;
  border-right-width: 0.6px;
  border-top-style: solid;
  border-top-width: 0.6px;
  height: 2px;
  position: relative;
  width: 6px;
}

.flight-detail-content .frame-246-1 {
  height: 15.5px;
  position: relative;
}

.flight-detail-content .group-245 {
  height: 1.5px;
  position: relative;
  width: 9.77px;
}

.flight-detail-content .rectangle-79 {
  border-bottom-style: solid;
  border-bottom-width: 0.6px;
  border-color: var(--endeavour);
  border-left-style: solid;
  border-left-width: 0.6px;
  border-radius: 0px 0px 11.39px 11.39px;
  border-right-style: solid;
  border-right-width: 0.6px;
  height: 2px;
  left: 0;
  position: absolute;
  top: 0;
  width: 3px;
}

.flight-detail-content .rectangle-80 {
  border-bottom-style: solid;
  border-bottom-width: 0.6px;
  border-color: var(--endeavour);
  border-left-style: solid;
  border-left-width: 0.6px;
  border-radius: 0px 0px 11.39px 11.39px;
  border-right-style: solid;
  border-right-width: 0.6px;
  height: 2px;
  left: 7px;
  position: absolute;
  top: 0;
  width: 3px;
}

.flight-detail-content .group-247 {
  height: 13px;
  left: -8px;
  position: absolute;
  top: 7px;
  width: 19px;
}

.flight-detail-content .overlap-group-1 {
  height: 13px;
  position: relative;
}

.flight-detail-content .frame-248-1 {
  align-items: center;
  left: 0;
  position: absolute;
  top: 0;
}

.flight-detail-content .frame-248-3 {
  display: inline-flex;
  flex-direction: column;
}
.flight-detail-content .frame-247-1 {
  align-items: center;
  background-color: var(--cultured-pearl);
  flex: 0 0 auto;
  gap: 8.75px;
  padding: 2.62px 0px 0px;
  position: relative;
}

.flight-detail-content .frame-247-4 {
  display: inline-flex;
  flex-direction: column;
}

.flight-detail-content .frame-246-2 {
  align-items: center;
  background-color: var(--cultured-pearl);
  border: 0.52px solid;
  border-color: var(--endeavour);
  border-radius: 0.7px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 2.45px 2.62px;
  position: relative;
  width: 14px;
}

.flight-detail-content .rectangle-83 {
  align-self: stretch;
  border-color: var(--endeavour);
  border-left-style: solid;
  border-left-width: 0.52px;
  border-radius: 0.61px 0.61px 0px 0px;
  border-right-style: solid;
  border-right-width: 0.52px;
  border-top-style: solid;
  border-top-width: 0.52px;
  height: 3.5px;
  position: relative;
  width: 100%;
}

.flight-detail-content .group-245-1 {
  height: 1.31px;
  position: relative;
  width: 10.7px;
}

.flight-detail-content .rectangle-79-1 {
  background-color: var(--cultured-pearl);
  border-bottom-style: solid;
  border-bottom-width: 0.52px;
  border-color: var(--endeavour);
  border-left-style: solid;
  border-left-width: 0.52px;
  border-radius: 0px 0px 9.97px 9.97px;
  border-right-style: solid;
  border-right-width: 0.52px;
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 3px;
}

.flight-detail-content .rectangle-80-1 {
  background-color: var(--cultured-pearl);
  border-bottom-style: solid;
  border-bottom-width: 0.52px;
  border-color: var(--endeavour);
  border-left-style: solid;
  border-left-width: 0.52px;
  border-radius: 0px 0px 9.97px 9.97px;
  border-right-style: solid;
  border-right-width: 0.52px;
  height: 1px;
  left: 8px;
  position: absolute;
  top: 0;
  width: 3px;
}

.flight-detail-content .frame-247-2 {
  align-items: center;
  background-color: var(--cultured-pearl);
  gap: 8.75px;
  left: 4px;
  padding: 0.88px 0px 0px;
  position: absolute;
  top: 0;
}

.flight-detail-content .rectangle-82 {
  border-color: var(--endeavour);
  border-left-style: solid;
  border-left-width: 0.52px;
  border-radius: 2.62px 1.75px 0px 0px;
  border-right-style: solid;
  border-right-width: 0.52px;
  border-top-style: solid;
  border-top-width: 0.52px;
  height: 1.75px;
  position: relative;
  width: 5.69px;
}

.flight-detail-content .rectangle-83-1 {
  border-color: var(--endeavour);
  border-radius: 0.7px 0.7px 0px 0px;
  border-right-style: solid;
  border-right-width: 0.6px;
  border-top-style: solid;
  border-top-width: 0.6px;
  height: 8px;
  left: 11px;
  position: absolute;
  top: 0;
  width: 8px;
}

.flight-detail-content .adult-4 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.flight-detail-content .vector {
  height: 3px;
  position: relative;
  width: 6px;
}

.flight-detail-content .frame-246-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 30px;
  position: relative;
}
.flight-detail-content .frame-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.flight-detail-content .frame-24 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.flight-detail-content .check-in-baggage-4 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.flight-detail-content .frame-2-3 {
  align-items: center;
  display: inline-flex;
  gap: 3px;
}

.flight-detail-content .address-9 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.flight-detail-content .x2-pcs-x-23-kg-8 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.flight-detail-content .frame-2-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.flight-detail-content .frame-238-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18px;
  padding: 25px 0px;
  position: relative;
  width: 100%;
}

.flight-detail-content .frame-238-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  padding: 2px 15px;
  position: relative;
  width: 100%;
}

.flight-detail-content .frame-242-3 {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 31.68px;
}

.flight-detail-content .frame-207 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 9.41px;
  justify-content: center;
  padding: 0.47px 0.94px;
  position: relative;
}

.flight-detail-content .group-208 {
  height: 13.62px;
  position: relative;
  width: 10.64px;
}

.flight-detail-content .ellipse-30 {
  border: 0.75px solid;
  border-color: var(--endeavour);
  border-radius: 2.25px;
  height: 5px;
  left: 2px;
  position: absolute;
  top: -1px;
  width: 5px;
}

.flight-detail-content .vector-container {
  height: 9px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 11px;
}

.flight-detail-content .vector-84 {
  height: 6px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 5px;
}

.flight-detail-content .vector-85 {
  height: 9px;
  left: 2px;
  position: absolute;
  top: 0;
  width: 8px;
}

.flight-detail-content .group-206-1 {
  height: 5px;
  left: 9px;
  position: absolute;
  top: -1px;
  width: 5px;
}

.flight-detail-content .overlap-group1 {
  border: 0.66px solid;
  border-color: var(--endeavour);
  border-radius: 3.16px;
  height: 6px;
  min-width: 6px;
  padding: 1.4px 1.8px;
}

.flight-detail-content .overlap {
  align-items: flex-start;
  display: flex;
  left: -1px;
  position: relative;
  top: -1px;
}

.flight-detail-content .vector-50-1 {
  height: 2px;
  width: 1px;
}

.flight-detail-content .x3hr-45m-3 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.flight-detail-content .frame-2-3 {
  align-items: center;
  display: inline-flex;
  gap: 3px;
}


.flight-detail-content .text-313 {
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.flight-detail-content .layover-in-tashkent-3 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.flight-detail-content .frame-241-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  padding: 0.5px 0px;
  position: relative;
  width: 100%;
}

.flight-detail-content .frame-207-1 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 15px 15px;
  padding: 0px 15px;
  position: relative;
  width: 100%;
}

.flight-detail-content .frame-173-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
}

.flight-detail-content .frame-242-8 {
  position: relative;
  width: 31.68px;
}

.flight-detail-content .frame-242-9 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.flight-detail-content .self-transfer-baggage {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.flight-detail-content .you-must-self-transf {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.flight-detail-content .four-img-container {
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
}

.flight-detail-content .four-img-container.frame-174-7 {
  align-items: center;
  /* align-self: stretch; */
  display: flex;
  position: relative;
}

.flight-detail-content .four-img-container .frame-223-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 1px;
  position: relative;
  width: 100%;
}

.flight-detail-content .four-img-container .vistara_-logo-1-1 {
  height: 12px;
  margin-bottom: -1px;
  position: relative;
  width: 12px;
}

.flight-detail-content .four-img-container .frame-224-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 1px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.flight-detail-content .four-img-container .frame-173-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2.69px;
  height: 10.5px;
  position: relative;
}

.flight-detail-content .four-img-container .unnamed {
  height: 10.5px;
  object-fit: cover;
  position: relative;
  width: 10.5px;
}

.flight-detail-content .four-img-container .frame-224 {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.13px;
  justify-content: flex-end;
  position: relative;
}

.flight-detail-content .four-img-container .png-transparent-luft-5 {
  height: 12px;
  margin-top: -1px;
  width: 12px;
}

.flight-detail-content .four-img-container .png-transparent-luft-6 {
  object-fit: cover;
  position: relative;
}

.flight-detail-content .two-img-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 30px;
  position: relative;
  width: 30px;
}
.flight-detail-content .two-img-container .frame-233-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  position: relative;
  width: 100%;
}
.flight-detail-content .two-img-container .turkish-airlines-symbol-1-1 {
  height: 14.12px;
  position: relative;
  width: 15px;
}

.flight-detail-content .two-img-container .frame-234-1 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.flight-detail-content .two-img-container .png-transparent-luft-4 {
  height: 15px;
  width: 15px;
}

.flight-detail-content .two-img-container .png-transparent-luft-6 {
  object-fit: cover;
  position: relative;
}

.flight-detail-content .single-img-container.png-transparent-luft-5 {
  height: 24px;
  margin-top: -1px;
  width: 24px;
}

.flight-detail-content .single-img-container.png-transparent-luft-6 {
  object-fit: cover;
  position: relative;
}

.flight-detail-content .three-img-container {
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
}

.flight-detail-content .three-img-container.frame-174-7 {
  align-items: center;
  align-self: stretch;
  min-width: 20px;
  display: flex;
  position: relative;
}

.flight-detail-content .three-img-container .frame-223-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 1px;
  position: relative;
  width: 100%;
}

.flight-detail-content .three-img-container .vistara_-logo-1-1 {
  height: 12px;
  margin-bottom: -1px;
  position: relative;
  width: 12px;
}

.flight-detail-content .three-img-container .frame-224-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 1px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.flight-detail-content .three-img-container .frame-173-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2.69px;
  height: 10.5px;
  position: relative;
}

.flight-detail-content .three-img-container .unnamed {
  height: 10.5px;
  object-fit: cover;
  position: relative;
  width: 10.5px;
}

.flight-detail-content .three-img-container .frame-224 {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.13px;
  justify-content: flex-end;
  position: relative;
}

.flight-detail-content .three-img-container .png-transparent-luft-5 {
  height: 12px;
  margin-top: -1px;
  width: 12px;
}

.flight-detail-content .three-img-container .png-transparent-luft-6 {
  object-fit: cover;
  position: relative;
}
