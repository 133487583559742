/* screen - homepage */

.homepage {
    align-items: flex-start;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .homepage .frame-101 {
    align-items: flex-start;
    background-color: var(--magic-potion);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 29px;
    padding: 0px 0px 40px;
    position: relative;
    width: 100%;
  }
  
  
  .homepage .vector-68 {
    height: 4px;
    left: 1px;
    position: absolute;
    top: 27px;
    width: 36px;
  }
  
  .homepage .vector-69 {
    height: 4px;
    left: 1px;
    position: absolute;
    top: 29px;
    width: 37px;
  }
  
  .homepage .frame-9 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 25px;
    position: relative;
  }

  
  .homepage .frame-99-2 {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 6px;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  
  
  .homepage .frame-99-3 {
    align-items: flex-end;
    display: flex;
    flex: 0 0 auto;
    gap: 6px;
    justify-content: center;
    position: relative;
    width: 116px;
  }

  
  .homepage .frame-8 {
    align-items: center;
    background: linear-gradient(180deg, rgb(233, 215, 153) 0%, rgb(255, 239.47, 183.81) 52.15%, rgb(225, 207, 140) 100%);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 7px;
    padding: 10px 18px;
    position: relative;
  }
  
  .homepage .business-travel {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .homepage .vector {
    height: 4.74px;
    position: relative;
    width: 8.06px;
  }
  
  .homepage .frame-203 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 5px 0px 0px;
    position: relative;
    width: 100%;
  }
  
  .homepage .frame-97 {
    align-items: center;
    background-color: var(--white);
    box-shadow: -2px 3px 7px 2px #0067b840;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    position: relative;
  }
  
  .homepage .frame-27-1 {
    align-items: flex-start;
    background-color: var(--white);
    border-radius: 5px;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 25px;
    /* overflow: hidden; */
    padding: 15px 0px 20px;
    position: relative;
  }
  
  .homepage .one-way {
    letter-spacing: 0.18px;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .homepage .frame-33 {
    min-height: 96px;
    align-items: flex-start;
    cursor: pointer;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    padding: 0px 25px;
    position: relative;
  }
  
  .homepage .frame {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }
  
  .homepage .frame-99-5 {
    height: 96px;
    display: flex;
    border: 1px solid var(--pumice);
  }
  
  .homepage .overlap-group-1 {
    display: flex;
    height: 96px;
    position: relative;
  }
  
  .homepage .frame-99-6 {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    gap: 63px;
    padding: 0px 11px 0px 0px;
    position: relative;
    width: 186px;
  }
  
  .homepage .place-1 {
    align-self: stretch;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: 78.24px;
  }
  
  .homepage .frame-29 {
    align-items: flex-start;
    min-height: 94px;
    background-color: var(--white);
    border-right: 1px solid;
    border-color: var(--pumice);
    display: flex;
    flex-direction: column;
    gap: 9px;
    /* left: 299px; */
    padding: 9px 12px;
    /* position: absolute; */
    top: 0;
    width: 300px;
  }
  .homepage .frame-29:hover {
    background-color: #E8F3FF;
  }
  
  .homepage .frame-99-7 {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    padding: 0px 4px;
    position: relative;
    width: 186px;
  }
  
  .homepage .frame-100-2 {
    align-items: center;
    align-self: stretch;
    min-width: 330px;
    /* margin-top: 13px; */
    background-color: var(--white);
    display: inline-flex;
    justify-content: center;
  }
  
  .homepage .frame-103-9 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 6px;
    position: relative;
    width: 100%;
  }
  
  .homepage .address {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .homepage .text-9 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .homepage .tuesday {
    align-self: stretch;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
  }
  
  .homepage .add-return {
    align-self: stretch;
    color: var(--endeavour);
    font-family: var(--font-family-proxima_nova-regular);
    font-size: var(--font-size-24);
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
  }
  
  .homepage .to-save-more {
    align-self: stretch;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
  }
  
  .homepage .frame-32 {
    align-items: flex-start;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 9px;
    padding: 9px 12px;
    min-height: 94px;
    position: relative;
    width: 252px;
  }

  .homepage .frame-32:hover {
  background-color: #E8F3FF;
  }
  .homepage .frame-4-1 {
  cursor: pointer;
  }

  .homepage .frame-102 {
    align-items: flex-start;
    align-self: end;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    min-height: 93px;
    gap: 12px;
    justify-content: center;
    padding: 9px 12px;
    position: relative;
    width: 412px;
  }
  
  .homepage .address-1 {
    align-self: stretch;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
  }

  
  .homepage .frame-103-10 {
    align-items: center;
    display: inline-flex;
    /* flex: 0 0 auto; */
    flex-direction: column;
    gap: 2px;
    justify-content: center;
    text-align: center;
    padding: 6.5px 15px 3.5px;
    position: relative;
  }
  
  .homepage .check {
    height: 11px;
    right: 5px;
    position: absolute;
    top: 6px;
    width: 11px;
  }
  
  .homepage .frame-4 {
    align-items: center;
    background-color: var(--white);
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    position: relative;
  }
  
  .homepage .frame-103 {
    
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 2px;
    justify-content: center;
    padding: 6.5px 20px 3.5px;
    position: relative;
  }
  
  .homepage .frame-103-11 {
    align-items: center;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 2px;
    justify-content: center;
    padding: 6.5px 20px 3.5px;
    position: relative;
    width: 100%;
  }
  
  .homepage .frame-44 {
    align-items: center;
    background-color: var(--endeavour);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 14.5px 35px 12.5px;
    position: relative;
  }
  
  .homepage .add-city {
    color: var(--endeavour);
    font-family: var(--font-family-proxima_nova-bold);
    font-size: var(--font-size-16);
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  .homepage .remove-city {
    color: var(--lightlava);
    font-family: var(--font-family-proxima_nova-bold);
    font-size: var(--font-size-16);
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .homepage .frame-44-5 {
    align-items: center;
    border: 1.2px solid;
    border-color: var(--endeavour);
    cursor: pointer;
    display: inline-flex;
    gap: 6px;
    height: 36px;
    justify-content: center;
    padding: 6.5px 20px;
    position: relative;
  }
  .homepage .frame-44-6 {
    align-items: center;
    border: 1.2px solid;
    border-color: var(--lightlava);
    cursor: pointer;
    display: inline-flex;
    gap: 6px;
    height: 37px;
    justify-content: center;
    padding: 6.5px 20px;
    position: relative;
  }
  
  .homepage .search {
    color: var(--white);
    font-family: var(--font-family-proxima_nova-bold);
    font-size: var(--font-size-18);
    font-weight: 700;
    letter-spacing: 0.36px;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .homepage .frame-103-12 {
    align-items: flex-start;
    background-color: var(--rhino);
    display: flex;
    flex: 0 0 auto;
    /* flex-direction: column; */
    gap: 10px;
    padding: 8px;
    position: relative;
    min-width: 177px;
    width: max-content;
  }
  
  .homepage .frame-103-1 {
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex: 0 0 auto;
    gap: 6px;
    position: relative;
    width: 100%;
  }
  
  .homepage .place {
    letter-spacing: 0;
    line-height: 11.5px;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .homepage .frame-44-1 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    position: relative;
    width: 177px;
  }
  
  .homepage .frame-103-2 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--rhino);
    display: flex;
    flex: 0 0 auto;
    /* flex-direction: column; */
    gap: 10px;
    padding: 8px;
    position: relative;
    width: 100%;
  }

  .homepage .text-overflow-elipsis-search {
    white-space: pre;
    text-overflow: ellipsis;
    width: 250px;
    overflow: hidden;
  }
  
  .homepage .frame-45 {
    cursor: pointer;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    position: relative;
    min-width: 199px;
    width: max-content;
  }
  
  .homepage .frame-103-13 {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    flex: 0 0 auto;
    gap: 11px;
    position: relative;
    width: 100%;
  }
  
  .homepage .place-5 {
    /* flex: 1; */
    letter-spacing: 0;
    line-height: 11.5px;
    margin-top: -1.00px;
    position: relative;
  }
  
  .homepage .place-6 {
    letter-spacing: 0;
    line-height: 11.5px;
    margin-top: -1.00px;
    position: relative;
    width: max-content;
  }
  
  .homepage .frame-103-3 {
    align-items: center;
    border-radius: 8px;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 30px;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }
  
  .homepage .title {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    width: fit-content;
  }
  
  .homepage .frame-103-4 {
    align-items: center;
    border-radius: 8px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 2px;
    justify-content: center;
    position: relative;
  }
  
  .homepage .frame-103-5 {
    align-items: center;
    background-color: var(--white);
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-color: var(--deep-sapphire);
    border-radius: 8px 0px 0px 8px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    overflow: hidden;
    padding: 15px 60px;
    position: relative;
  }
  
  .homepage .flights-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -3.00px;
    position: relative;
    width: fit-content;
  }
  
  .homepage .frame-104 {
    align-items: center;
    background-color: var(--white);
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-color: transparent;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 15px 60px;
    position: relative;
  }
  
  .homepage .hotels-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -3.00px;
    position: relative;
    width: fit-content;
  }
  
  .homepage .frame-105 {
    align-items: center;
    background-color: var(--white);
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-color: transparent;
    border-radius: 0px 8px 8px 0px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    overflow: hidden;
    padding: 15px 60px;
    position: relative;
  }
  
  .homepage .frame-10 {
    align-items: flex-start;
    border-radius: 20px;
    display: flex;
    height: 200px;
    position: relative;
    width: 507px;
  }
  
  .homepage .frame-103-6 {
    align-items: center;
    align-self: stretch;
    background-color: var(--white);
    border-radius: 0px 12px 12px 0px;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    justify-content: center;
    padding: 20px 20px 25px;
    position: relative;
  }
  
  .homepage .frame-103-7 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  
  .homepage .frame-103-8 {
    align-items: flex-start;
    background-color: var(--deep-sapphire);
    border-radius: 5px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    overflow: hidden;
    padding: 6px 12px;
    position: relative;
  }
  
  .homepage .frame-110 {
    align-items: flex-start;
    border-radius: 20px;
    display: flex;
    height: 200px;
    margin-right: -20.00px;
    position: relative;
    width: 507px;
  }
  
  .homepage .arrow {
    height: 24px;
    left: 7px;
    position: absolute;
    top: 7px;
    width: 24px;
  }
  
  .homepage .arrow-1 {
    height: 25px;
    left: 6px;
    position: absolute;
    top: 6px;
    width: 25px;
  }
  
  .homepage .flights-section-1 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--white);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--black);
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    gap: 40px 40px;
    padding: 60px 85px;
    position: relative;
    width: 100%;
  }
  
  .homepage .frame-273 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 50px;
    position: relative;
    width: 100%;
  }
  
  .homepage .offers {
    color: var(--black);
    font-family: var(--font-family-proxima_nova-semibold);
    font-size: 35px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .homepage .all-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -3.00px;
    position: relative;
    width: fit-content;
  }
  
  .homepage .packages-2 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -3.00px;
    position: relative;
    width: fit-content;
  }
  
  .homepage .frame-100-3 {
    align-items: flex-start; 
    align-self:stretch;
    background-color: var(--white);
    display: flex; 
    flex-direction: column;
    gap: 30px;
    padding: 88px 85px;
    width: 100%;
  }
  
  .homepage .frame-9-1 {
    align-self: stretch;
    /* height: 87px; */
    position: relative;
    width: 100%;
  }
  
  .homepage .vector-1 {
    height: 17px;
    width: 9px;
  }
  
  .homepage .vector-5 {
    height: 19px;
    width: 19px;
  }
  
  .homepage .vector-6 {
    height: 16px;
    width: 20px;
  }
  
  .homepage .place-7 {
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 1px;
    white-space: nowrap;
  }
  
  .homepage .angle-right {
    height: 18px;
    width: 22px;
  }
  
  .homepage .offers-1 {
    height: 15px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 1.63px;
    white-space: nowrap;
    width: 106px;
  }
  
  .homepage .book-now-6 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    width: fit-content;
  }
  
  .homepage .economy-from-1999-6 {
    align-self: stretch;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
  }
  
  
  .homepage .live-now-republic-d-6 {
    align-self: stretch;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
  }
  
  .valign-text-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  

  .book-international-and-domestic-flights {
    flex: 1;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    margin-bottom: 0px;
  }


  
  .plus {
    flex: 0 0 auto;
    position: relative;
  }

  .minus {
    flex: 0 0 auto;
    position: relative;
  }
  