/* // Proxima  */

@font-face {
  font-family: "Proxima Nova-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../Fonts/proxima/Proxima\ Nova\ Font.otf") format("opentype");
}

@font-face {
  font-family: "Proxima Nova-bold";
  font-style: normal;
  font-weight: 400;
  src: url("../Fonts/proxima/FontsFree-Net-Proxima-Nova-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Proxima Nova-Light";
  font-style: normal;
  font-weight: 400;
  src: url("../Fonts/proxima/Proxima\ Nova\ Light.ttf") format("opentype");
}

@font-face {
  font-family: "Proxima Nova-SemiBold";
  font-style: normal;
  font-weight: 700;
  src: url("../Fonts/proxima/Proxima\ Nova\ Semibold.ttf") format("opentype");
}

@font-face {
  font-family: "Proxima Nova-Medium";
  font-style: normal;
  font-weight: 700;
  src: url("../Fonts/proxima/ProximaNova-Medium.ttf") format("opentype");
}


/* // Seuge UI  */


@font-face {
  font-family: "Segoe UI-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../Fonts/segoe/Segoe UI.ttf") format("truetype");
}

@font-face {
  font-family: "Segoe UI-Semibold";
  font-style: normal;
  font-weight: 400;
  src: url("../Fonts/segoe/seguisb.ttf") format("truetype");
}

@font-face {
  font-family: "Segoe UI-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../Fonts/segoe/segoeui.ttf") format("truetype");
}

/* // Averta  */

@font-face {
  font-family: "Averta Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../Fonts/averta/AvertaDemo-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Averta Bold";
  font-style: normal;
  font-weight: 400;
  src: url("../Fonts/averta/Averta-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "Averta Light";
  font-style: normal;
  font-weight: 400;
  src: url("../Fonts/averta/averta-light.otf") format("opentype");
}

@font-face {
  font-family: "Averta SemiBold";
  font-style: normal;
  font-weight: 700;
  src: url("../Fonts/averta/Averta-Semibold.ttf") format("opentype");
}



* {
  box-sizing: border-box;
}

body {
  margin: 0px !important;
}
