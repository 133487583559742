.content {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--white);
    box-shadow: 0px 2px 5px 1px #0057b733;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 15px;
    position: relative;
    /* margin-bottom: 20px; */
    max-height: 76%;
    width: 100%;
  }
  
  .content .frame-242-3-cc {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
   padding: 3px 0px;
    position: relative;
  }

  .content .results-cc {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 1;
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: -0.80px;
    margin-left: -0.80px;
    margin-right: -0.80px;
    margin-top: -0.80px;
    padding: 10px 0px;
    position: relative;
    width: 99.3%;
  }

  
.content .frame-238-3-cc {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    /* overflow-y: auto; */
    gap: 20px;
    margin-bottom: -62.20px;
    margin-left: -0.80px;
    margin-top: -0.80px;
    padding: 0px 20px;
    position: relative;
  }

    
/* Custom scrollbar styles for WebKit-based browsers */
.content .results-cc::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
    cursor: grab;
    height: 100px !important;
  }
  
  .content .results-cc::-webkit-scrollbar-track {
    height: 100px;
    background: var(--sky-light); /* Background of the scrollbar track */
    margin-top: 16px; /* Adjust to match button height */
    margin-bottom: 16px; /* Adjust to match button height */
  }
  
  .content .results-cc::-webkit-scrollbar-thumb {
    background: var(--deep-sapphire); /* Background of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
  }
  
  .content .results-cc::-webkit-scrollbar-button {
    display: none;
  }

  
  
  .content .frame-242-4-cc {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 20px;
    position: relative;
    width: 100%;
  }
  
  .content .results-1-cc {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
  }
  
  .content .frame-242-5-cc {
    align-items: flex-start;
    align-self: stretch;
    border: 1px solid;
    border-color: var(--alto);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    margin-bottom: -1.00px;
    margin-left: -1.00px;
    margin-right: -1.00px;
    margin-top: -1.00px;
    padding: 0.5px;
    position: relative;
    width: 100%;
  }
  
  .content .frame-242-6-cc {
    align-items: center;
    align-self: stretch;
    background-color: var(--gray-nurse);
    border-bottom-style: solid;
    border-bottom-width: 0.8px;
    border-color: var(--alto);
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 10px 15px;
    position: relative;
    width: 100%;
  }
  
  .content .frame-257-cc {
    align-items: center;
    display: flex;
    gap: 15px;
    position: relative;
    width: max-content;
    display: flex;
    flex-direction: row;
    background-color: var(--gray-nurse);
    /* width: 861.85px; */
  }

  .content .frame-2-cc {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }
  
  
.content .frame-174-cc {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 25px;
    justify-content: space-between;
    position: relative;
    width: 25px;
  }


  
.content .four-img-container {
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
  }
  
  .content .four-img-container.frame-174-7 {
    align-items: center;
    /* align-self: stretch; */
    display: flex;
    position: relative;
  }
  
  .content .four-img-container .frame-223-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 1px;
    position: relative;
    width: 100%;
  }
  
  .content .four-img-container .vistara_-logo-1-1 {
    height: 12px;
    margin-bottom: -1px;
    position: relative;
    width: 12px;
  }
  
  .content .four-img-container .frame-224-4 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 1px;
    justify-content: flex-end;
    position: relative;
    width: 100%;
  }
  
  .content .four-img-container .frame-173-4 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 2.69px;
    height: 10.5px;
    position: relative;
  }
  
  .content .four-img-container .unnamed {
    height: 10.5px;
    object-fit: cover;
    position: relative;
    width: 10.5px;
  }
  
  .content .four-img-container .frame-224 {
    align-items: flex-start;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 3.13px;
    justify-content: flex-end;
    position: relative;
  }
  
  .content .four-img-container .png-transparent-luft-5 {
    height: 12px;
    margin-top: -1px;
    width: 12px;
  }
  
  .content .four-img-container .png-transparent-luft-6 {
    object-fit: cover;
    position: relative;
  }
  
  .content .two-img-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 30px;
    position: relative;
    width: 30px;
  }
  .content .two-img-container .frame-233-5 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    position: relative;
    width: 100%;
  }
  .content .two-img-container .turkish-airlines-symbol-1-1 {
    height: 14.12px;
    position: relative;
    width: 15px;
  }
  
  .content .two-img-container .frame-234-1 {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    width: 100%;
  }
  
  .content .two-img-container .png-transparent-luft-4 {
    height: 15px;
    width: 15px;
  }
  
  .content .two-img-container .png-transparent-luft-6 {
    object-fit: cover;
    position: relative;
  }
  
  .content .single-img-container.png-transparent-luft-5 {
    height: 24px;
    margin-top: -1px;
    width: 24px;
  }
  
  .content .single-img-container.png-transparent-luft-6 {
    object-fit: cover;
    position: relative;
  }
  
  .content .three-img-container {
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
  }
  
  .content .three-img-container.frame-174-7 {
    align-items: center;
    align-self: stretch;
    min-width: 20px;
    display: flex;
    position: relative;
  }
  
  .content .three-img-container .frame-223-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 1px;
    position: relative;
    width: 100%;
  }
  
  .content .three-img-container .vistara_-logo-1-1 {
    height: 12px;
    margin-bottom: -1px;
    position: relative;
    width: 12px;
  }
  
  .content .three-img-container .frame-224-4 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 1px;
    justify-content: flex-end;
    position: relative;
    width: 100%;
  }
  
  .content .three-img-container .frame-173-4 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 2.69px;
    height: 10.5px;
    position: relative;
  }
  
  .content .three-img-container .unnamed {
    height: 10.5px;
    object-fit: cover;
    position: relative;
    width: 10.5px;
  }
  
  .content .three-img-container .frame-224 {
    align-items: flex-start;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 3.13px;
    justify-content: flex-end;
    position: relative;
  }
  
  .content .three-img-container .png-transparent-luft-5 {
    height: 12px;
    margin-top: -1px;
    width: 12px;
  }
  
  .content .three-img-container .png-transparent-luft-6 {
    object-fit: cover;
    position: relative;
  }

  
  
  .content .air-india-vistara-lufthansa {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .content .text-362-cc {
    width: fit-content;
  }
  .content .text-cc {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
  }
  
  .content .frame-252-cc {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }

  
  .content .frame-2-cc {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }

  .content .place-4-cc {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .content .exchange-alt-cc {
    position: relative;
    width: 15px;
  }
  .content .frame-24-cc {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    position: relative;
  }

  .content .fare-cc {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .content .frame-24-cc {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    position: relative;
  }
  .content .frame-666-cc {
display: flex;
gap: 5px;
  }
  .content .flexi-economy-cc {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .content .frame-242-7-cc {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
  }

  
  .content .frame-24-1-cc {
    align-self: stretch;
    display: flex;
    width: 100%;
  }
  
  .content .frame-242-8-cc {
    align-items: flex-start;
    align-self: stretch;
    border-bottom-style: solid;
    border-bottom-width: 0.8px;
    border-color: var(--alto);
    border-right-style: solid;
    border-right-width: 0.8px;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    padding: 15px;
    position: relative;
  }

  .content .frame-24-3-cc {
    align-items: flex-start;
    flex: 0 0 auto;
    position: relative;
  }
  

  .content .time-frame-cc {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.80px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  
  .content .from-scheduled-flight-departure-cc {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 198px;
  }
  .content .frame-242-9-cc {
    align-items: flex-start;
    border-bottom-style: solid;
    border-bottom-width: 0.8px;
    border-color: var(--alto);
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    padding: 15px;
    position: relative;
  }

  .content .airline-charges-st-fare-charges-cc {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.80px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .content .per-cc {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .content .frame-24-1-cc {
    align-self: stretch;
    display: flex;
    width: 100%;
  }

  
  
.content .frame-24-3-cc {
    align-items: flex-start;
    flex: 0 0 auto;
    position: relative;
  }

  
  .content .frame-242-10-cc {
    align-items: flex-start;
    align-self: stretch;
    border-bottom-style: solid;
    border-bottom-width: 0.8px;
    border-color: var(--alto);
    border-right-style: solid;
    border-right-width: 0.8px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    padding: 15px;
    position: relative;
  }

  .content .address-3-cc {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.80px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  .content .frame-242-11-cc {
    align-items: flex-start;
    border-bottom-style: solid;
    border-bottom-width: 0.8px;
    border-color: var(--alto);
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 11px;
    padding: 15px;
    position: relative;
  }
    
  .content .frame-24-2-cc {
    display: flex;
    align-items: flex-end;
    gap: 5px;
  }
  .content .adult-cc {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    width: 63px;
  }

  
.content .non-refundable-cc {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  
  .content .child-cc {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    width: 63px;
  }
  .content .frame-24-cc {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    position: relative;
  }

  .content .frame-17-cc {
    align-items: baseline;
    display: flex;
    gap: 4px;
  }

  
  .content .frame-169-cc {
    flex: 0 0 auto;
    position: relative;
  }
  .content .text-363-cc {
    margin-top: -2.74px;
    width: fit-content;
  }
  
  .content .valign-text-middle-cc {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .content .text-364-cc {
    margin-top: -2.74px;
    width: fit-content;
  }

  .content .number-cc {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -2.74px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  
  .content .infant-cc {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  .content .text-365-cc {
    margin-top: -2.74px;
    width: fit-content;
  }
  .content .text-366-cc {
    margin-top: -2.74px;
    width: fit-content;
  }
  .content .number-5-cc {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -2.74px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .content .frame-242-14-cc {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 5px;
    position: relative;
    width: 100%;
  }
  .content .terms-conditions-cc {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  .content .the-airline-fee-is-i-cc {
    align-self: stretch;
    /* margin-left: 10px; */
    letter-spacing: 0;
    line-height: 20px;
    position: relative;
  }

  