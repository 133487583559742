.FD-Header {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    padding: 10px 20px;
    position: relative;
    width: 100%;
  }
.FD-Header.proxima {
    background-color: var(--dark-purple);
  }
.FD-Header.averta {
    background-color: var(--dark-galaxy);
  }
  
  .FD-Header .ff-inf-tooltip {
    display: flex;
    /* position: relative;
    display: inline-block; */
    cursor: pointer;
  }
  
  /* .FD-Header .custom-tooltip {
  } */

  
  .FD-Header .frame-24 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .FD-Header .frame-2-1 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    justify-content: space-between;
    position: relative;
  }
  
  .x-flight-details {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.50px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  .FD-Header .frame-24-1 {
    align-items: center;
    display: inline-flex;
    gap: 10px;
    padding: 0px 0px 0px 30px;
  }
  
  .FD-Header .frame-24-8 {
    flex: 0 0 auto;
    position: relative;
  }
  .FD-Header .line-between {
    width: 10px;
    height: 1px;
    background-color: var(--white);
  }
  
  .FD-Header .frame-2-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    position: relative;
  }
  
  .FD-Header .frame-202 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    justify-content: center;
    padding: 0px 0px 0px 1.5px;
    position: relative;
  }
  
  .FD-Header .x20h-40m {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.50px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .FD-Header .frame-268-1 {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 8px;
    justify-content: center;
    width: 100%;
  }
  .FD-Header .frame-268-3 {
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
  }
  
  .FD-Header .frame-24 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  
  .FD-Header .frame-250 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }
  
  .FD-Header .frame-243 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 15px;
    padding: 0px 0px 0px 10px;
    position: relative;
  }
  
  .FD-Header .place {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -3.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .FD-Header .frame-170 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 2px;
    justify-content: center;
    position: relative;
  }
  
  .FD-Header .line {
    height: 1px;
    object-fit: cover;
    position: relative;
    width: 10px;
  }
  
  .FD-Header .frame-202 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    justify-content: center;
    padding: 0px 0px 0px 1.5px;
    position: relative;
  }
  
  .FD-Header .address {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .FD-Header .frame-268 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 15px;
    height: 12.95px;
    position: relative;
  }
  
  .FD-Header .frame-243-1 {
    align-items: center;
    align-self: stretch;
    border-color: var(--white);
    border-right-style: solid;
    border-right-width: 1px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 0px 15px 0px 0px;
    position: relative;
  }
  
  .FD-Header .partially-refundable {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.03px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  
  .FD-Header .frame-3 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    position: relative;
  }
  
  .FD-Header .flexi-economy {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.03px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .FD-Header .check {
    align-items: center;
    border: 1.19px solid;
    border-color: var(--white);
    border-radius: 2.25px;
    display: flex;
    flex-direction: column;
    gap: 5.18px;
    height: 14.14px;
    margin-bottom: -0.60px;
    margin-right: -0.60px;
    margin-top: -0.60px;
    padding: 3.19px 3.98px;
    position: relative;
    width: 14.14px;
  }
  
  .FD-Header .frame-242 {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 1.5px;
    left: 5px;
    position: relative;
    top: 2px;
  }
  
  .FD-Header .frame-242-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
  }
  
  .FD-Header .text-2 {
    height: 2.2px;
    margin-top: -1.00px;
    position: relative;
    width: 2px;
  }
  
  .FD-Header .text-2-1 {
    height: 4px;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: 2px;
  }
  
  .FD-Header .valign-text-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .FD-Header .frame-24 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .FD-Header .frame-250 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }
  
  .FD-Header .frame-243 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 15px;
    padding: 0px 0px 0px 10px;
    position: relative;
  }
  
  .FD-Header .address-1 {
    text-align: center;
  }
  
  .FD-Header .address-18 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .FD-Header .frame-268 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 15px;
    height: 12.95px;
    position: relative;
  }
  .FD-Header .frame-243-1 {
    align-items: center;
    align-self: stretch;
    border-color: var(--white);
    border-right-style: solid;
    border-right-width: 1px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 0px 15px 0px 0px;
    position: relative;
  }
  
  .FD-Header .non-refundable {
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.03px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .FD-Header .frame-3 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    position: relative;
  }
  
  .FD-Header .flexi-economy-3 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.03px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .FD-Header .check {
    align-items: center;
    border: 1.19px solid;
    border-color: var(--white);
    border-radius: 2.25px;
    display: flex;
    flex-direction: column;
    gap: 5.18px;
    height: 14.14px;
    margin-bottom: -0.60px;
    margin-right: -0.60px;
    margin-top: -0.60px;
    padding: 3.19px 3.98px;
    position: relative;
    width: 14.14px;
  }
  
  .FD-Header .frame-242 {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 1.5px;
    left: 5px;
    position: relative;
    top: 2px;
  }
  
  .FD-Header .frame-242-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
  }
  
  .FD-Header .text-2 {
    height: 2.2px;
    margin-top: -1.00px;
    position: relative;
    width: 2px;
  }
  
  .FD-Header .text-2-1 {
    height: 4px;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: 2px;
  }
  
  .FD-Header .valign-text-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  