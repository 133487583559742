.bottombar {
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 12px 20px;
  position: relative;
  width: 100%;
  height: 10%;
}
.bottombar.proxima {
  box-shadow: 0px 0px 5px 1px #0057b733;
}
.bottombar.averta {
  box-shadow: 0px 0px 5px 1px var(--gold-shadow);
}

.bottombar .frame-242-17 {
  align-items: flex-start;
  /* display: inline-flex; */
  flex: 0 0 auto;
  gap: 7px;
  position: relative;
}

.bottombar .frame-242 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  position: relative;
}

.bottombar .frame-173-7 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  position: relative;
}

.bottombar .frame-241 {
  align-items: flex-end;
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
}

.bottombar .frame-169-9 {
  flex: 0 0 auto;
  position: relative;
}

.bottombar .text-348 {
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.bottombar .valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bottombar .travellers-4 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
.bottombar .info-icon {
  cursor: pointer;
}

.bottombar .frame-242-18 {
  align-items: flex-end;
  margin-left: 7px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  position: relative;
}

.bottombar .frame-242-19 {
    cursor: pointer;
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  /* padding: 0px 0px 2px; */
  position: relative;
}

.bottombar .fare {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.bottombar .fare-summary-tooltip {
    position: absolute;
    /* top: 100%;
    left: -175%; */
    bottom: 25px;
    left: -75px;
    width: 295px;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s, transform 0.3s;
  }
  
.bottombar .fare-summary-tooltip.proxima::after {
    content: '';
    position: absolute;
    bottom: -7px; /* Adjust this value to position the arrow correctly */
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--rhino);
  }
.bottombar .fare-summary-tooltip.averta::after {
    content: '';
    position: absolute;
    bottom: -7px; /* Adjust this value to position the arrow correctly */
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--dark-galaxy);
  }
  
  .bottombar .info-icon-hov:hover .fare-summary-tooltip {
    visibility: visible;
    z-index: 100;
    opacity: 1;
    transform: translateY(0);
  }




.bottombar .frame-242-22 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  /* gap: 15px; */
  justify-content: flex-end;
  position: relative;
  cursor: pointer;
  border: 1px solid;
  font-style: normal;
  font-weight: 400;
}

.bottombar .frame-242-22.proxima {
  background-color: var(--white);
  color: var(--endeavour);
  border-color: var(--endeavour);
  font-family: var(--font-family-proxima_nova-semibold);
  font-size: var(--font-size-14);
}
.bottombar .frame-242-22.averta {
  background-color: var(--white);
  color: var(--burning-gold);
  border-color: var(--burning-gold);
  font-family: var(--font-family-averta-semibold);
  font-size: var(--font-size-14);
}


.bottombar .frame-242-22.proxima:hover {
  color: var(--white);
  background-color: var(--endeavour);
}
.bottombar .frame-242-22.averta:hover {
  color: var(--white);
  background-color: var(--light-mud);
  border-color: var(--light-mud);
}

.bottombar .frame-242-22.proxima .loader:before , .bottombar .frame-242-22.proxima .loader:after , .bottombar .frame-242-22.proxima .loader{

  background: radial-gradient(closest-side at 50% 12.5%,
  var(--endeavour) 96%,#0000) 50% 0/20% 80% repeat-y,
 radial-gradient(closest-side at 12.5% 50%,
  var(--endeavour) 96%,#0000) 0 50%/80% 20% repeat-x;

}
.bottombar .frame-242-22.averta .loader:after , .bottombar .frame-242-22.averta .loader:before , .bottombar .frame-242-22.averta .loader{

  background: radial-gradient(closest-side at 50% 12.5%,
  var(--burning-gold) 96%,#0000) 50% 0/20% 80% repeat-y,
 radial-gradient(closest-side at 12.5% 50%,
  var(--burning-gold) 96%,#0000) 0 50%/80% 20% repeat-x;

}

.bottombar .frame-242-22.proxima:hover .loader:before , .bottombar .frame-242-22.proxima:hover .loader:after , .bottombar .frame-242-22.proxima:hover .loader{

  background: radial-gradient(closest-side at 50% 12.5%,
  var(--white) 96%,#0000) 50% 0/20% 80% repeat-y,
 radial-gradient(closest-side at 12.5% 50%,
  var(--white) 96%,#0000) 0 50%/80% 20% repeat-x;

}
.bottombar .frame-242-22.averta:hover .loader:after , .bottombar .frame-242-22.averta:hover .loader:before , .bottombar .frame-242-22.averta:hover .loader{

  background: radial-gradient(closest-side at 50% 12.5%,
  var(--white) 96%,#0000) 50% 0/20% 80% repeat-y,
 radial-gradient(closest-side at 12.5% 50%,
  var(--white) 96%,#0000) 0 50%/80% 20% repeat-x;

}

/* HTML: <div class="loader"></div> */
.loader {
  width: 20px;
  margin-right: 15px;
  aspect-ratio: 1;
  display:grid;
  -webkit-mask: conic-gradient(from 15deg,#0000,#000);
  animation: l26 1s infinite steps(12);
}
/* .loader,
.loader:before,
.loader:after{
  background:
    radial-gradient(closest-side at 50% 12.5%,
     #f03355 96%,#0000) 50% 0/20% 80% repeat-y,
    radial-gradient(closest-side at 12.5% 50%,
     #f03355 96%,#0000) 0 50%/80% 20% repeat-x;
} */
.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
}
.loader:after {
  transform: rotate(60deg);
}

@keyframes l26 {
  100% {transform:rotate(1turn)}
}

.bottombar .frame-103-1 {
  align-items: center;
  display: flex;
  gap: 4px;
  height: 34px;
  justify-content: center;
  padding: 10.5px 33px;
  position: relative;
  width: 108px;
}

.bottombar .book {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
