.fare-rules-bar {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0px;
  position: relative;
  width: 100%;
}

.fare-rules-bar .frame-245-3.proxima {
  box-shadow: 0px 1px 4px 1px #0057b74c;
}
.fare-rules-bar .frame-245-3.averta {
  box-shadow: 0px 1px 4px 1px var(--light-yellow);
}

.fare-rules-bar .frame-245-4 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.fare-rules-bar .frame-243-11 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 15px 20px;
  position: relative;
  width: 100%;
}

.fare-rules-bar .frame-243-11.proxima {
  background-color: var(--crystal-water);
}
.fare-rules-bar .frame-243-11.averta {
  background-color: var(--ocean);
}

.fare-rules-bar .frame-243-12 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 5px;
  position: relative;
  width: 100%;
}

.fare-rules-bar .frame {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.fare-rules-bar .frame-24-2-2 {
  align-items: center;
  display: flex;
  gap: 8px;
  align-items: baseline;
}
.fare-rules-bar .frame-24-8 {
  flex: 0 0 auto;
  position: relative;
  display: flex;
  align-items: normal;
}
.fare-rules-bar .place {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -3px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.fare-rules-bar .frame-243-13 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.fare-rules-bar .frame-243-14 {
  align-items: normal;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 8px 20px;
  position: relative;
  width: 100%;
}

.fare-rules-bar .frame-24-2 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
}

.fare-rules-bar .ticket-cancellation {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.fare-rules-bar .frame-250-3 {
  cursor: pointer;
  padding: 9px 0px;
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}
.fare-rules-bar .text-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -3px;
  position: relative;
}

.fare-rules-bar .view-fare-rules {
  /* -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent; */
  background: linear-gradient(
    180deg,
    rgb(0, 87, 183) 0%,
    rgb(41, 58, 100) 100%
  );
  background-clip: text;
  color: transparent;
  /* font-family: var(--font-family-proxima_nova-semibold);
    font-size: var(--font-size-xl); */
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  /* margin-top: -1.00px; */
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
