.seat-row-no {
    cursor: pointer;
    height: 24px;
    width: 24px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .main-seat {
    cursor: pointer;
    height: 24px;
    width: 24px;
    /* display: flex;
    justify-content: center; */
    border: 2px solid var(--gray-4);
    border-radius: 4px;
  }
  .main-seat.isActiveSeat {
    height: 28px;
    width: 28px;
    border: 3px solid skyblue;
    border-radius: 4px;
/* https://smoothshadows.com/#djEsMSw1LDAuMDgsMjQsMzIsMCwjMDMwNzEyLCNmM2Y0ZjYsI2ZmZmZmZiwy */
box-shadow: 0px 1px 1px rgba(3, 7, 18, 0.02),
  0px 5px 4px rgba(3, 7, 18, 0.03),
  0px 12px 9px rgba(3, 7, 18, 0.05),
  0px 20px 15px rgba(3, 7, 18, 0.06),
  0px 32px 24px rgba(3, 7, 18, 0.08);


 ;
  }
  .main-seat .free{
    width: 100%;
    height: 20%;
    background: #808080;
  }
  .main-seat .paid{
    width: 100%;
    height: 20%;
    background: #41A318;
  }
  .main-seat .extraLegRoom{
    width: 100%;
    height: 20%;
    background: #43A9C7;
  }
  .main-seat .exitrow{
    width: 100%;
    height: 20%;
    background: #D2042D;
  }
  .main-seat .nonrecline{
    width: 100%;
    height: 20%;
    background: pink;
  }
  .main-seat .recline{
    width: 100%;
    height: 20%;
    background: orange;
  }
  .main-seat .selected{
    width: 100%;
    border-radius: 4px;
    height: 100%;
    background: #293A64;
    color: white;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .seat-wrapper {
    position: relative;
    display: inline-block;
  }
  .seat-wrapper.Occupied {
  display: flex;
  align-items: center;
  justify-content: center;
  }
  
  .seat-tooltip {
    visibility: hidden;
    background-color: #1F2E53;
    color: #fff;
    text-align: center;
    position: absolute;
    bottom: 130%; /* Position above the seat */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    white-space: nowrap;
    line-height: 1.4;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  
  .seat-tooltip .seat-tooltip-wrapp{
    display: flex;
    flex-direction: column;
    padding: 10px 16px;
  }
  
  .seat-tooltip::after {
    content: "";
    position: absolute;
    top: 100%; /* Position the arrow below the tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent; /* Arrow color */
  }
  
  .seat-wrapper:hover .seat-tooltip {
    visibility: visible;
    opacity: 1;
  }
  