.calendar-Bar {
  align-items: flex-end;
  align-self: stretch;
  background-color: var(--mystic);
  box-shadow: 0px 3px 4px #00000033;
  display: flex;
  gap: 2px;
  justify-content: center;
  padding: 0px 34px;
  width: 100%;
}

.calendar-Bar.frame-169-20 {
  flex: 0 0 auto;
  position: relative;
}

.calendar-Bar .frame-110 {
  cursor: pointer;
  height: 54px;
  right: 0px;
  position: absolute;
  top: 0px;
  width: 32px;
}

.calendar-Bar .frame-111 {
  cursor: pointer;
  height: 54px;
  left: 0;
  position: absolute;
  top: 0px;
  width: 33px;
}

.calendar-Bar .frame-103-4{
  display: flex;
  height: 53px;
}
.calendar-Bar .frame-103-4::-webkit-scrollbar {

  display: none;
  width: 0;
}

.calendar-Bar .frame-1-4 {
  cursor: pointer;
  width: 115px;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  padding: 10px 18px;
  position: relative;
  height: 54px;
}

.calendar-Bar .tue-30-jan {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.calendar-Bar .frame-4 {
  align-items: unset;
  justify-content: center;
  display: flex;
  flex: 0 0 auto;
  gap: 2px;
  position: relative;
}
.calendar-Bar .vector-2 {
  height: 9.72px;
  position: relative;
  width: 7.03px;
}

.calendar-Bar .number-20 {
  margin-top: -1px;
}

.calendar-Bar .number-29 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  max-width: 85px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calendar-Bar .bg-blue-grow {
  align-items: center;
  z-index: 1;
  background-color: var(--white);
  box-shadow: 0px 0px 10px 5px #0057b733;
  /* box-shadow: -1px 1.5px 20px 5.5px rgba(0, 87, 183, 0.2); */
  flex-direction: column;
  gap: 4.4px;
  position: relative;
  top: -7px;
  left: 0px;
  height: 62.78px;
  padding: 10px 18px;
  width: 115px;
  /* width: 130.17px; */
  border-bottom: 3px solid var(--endeavour);
}

.calendar-Bar .tue-30-jan-1 {
  margin-top: -1px;
}

.calendar-Bar .tue-30-jan-9 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}



.calendar-Bar .vector-2 {
  height: 9.72px;
  position: relative;
  width: 7.03px;
}

.calendar-Bar .number-21 {
  margin-top: -1px;
}

.calendar-Bar .number-29 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
