/* screen - fare-summary-popup-3 */

.fare-summary-popup {
    align-items: flex-start;
    background-color: var(--rhino);
    display: flex;
    flex-direction: column;
    padding: 0px 0px 12px;
    /* position: relative; */
  }
  
  .fare-summary-popup .rectangle-73 {
    height: 169px;
    left: 0;
    position: absolute;
    top: -7px;
    width: 293px;
  }
  
  .fare-summary-popup .frame-242 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--rhino);
    border-bottom-style: solid;
    border-bottom-width: 0.5px;
    border-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: space-around;
    padding: 12px 15px;
    position: relative;
    width: 100%;
  }
  
  .fare-summary-popup .fare-summary {
    color: var(--white);
    flex: 1;
    font-family: var(--font-family-proxima_nova-semibold);
    font-size: var(--font-size-14_8);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -0.50px;
    position: relative;
  }
  
  .fare-summary-popup .frame-244 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--rhino);
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 15px 15px 10px;
    position: relative;
    width: 100%;
  }
  
  .fare-summary-popup .base-fare {
    color: var(--white);
    flex: 1;
    font-family: var(--font-family-proxima_nova-regular);
    font-size: var(--font-size-16);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1.00px;
    position: relative;
  }
  
  .fare-summary-popup .frame-173 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 4px;
    justify-content: flex-end;
    position: relative;
  }
  
  .fare-summary-popup .frame-169 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .fare-summary-popup .text {
    color: var(--white);
    font-family: var(--font-family-proxima_nova-regular);
    font-size: var(--font-size-16);
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -2.74px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .fare-summary-popup .frame-243 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--rhino);
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 10px 15px 15px;
    position: relative;
    width: 100%;
  }
  
  .fare-summary-popup .taxes {
    color: var(--white);
    flex: 1;
    font-family: var(--font-family-proxima_nova-regular);
    font-size: var(--font-size-16);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1.00px;
    position: relative;
  }
  
  .fare-summary-popup .frame-241 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--rhino);
    border-color: var(--white);
    border-top-style: solid;
    border-top-width: 0.2px;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 12px 15px 0px;
    position: relative;
    width: 100%;
  }
  
  .fare-summary-popup .total {
    color: var(--white);
    flex: 1;
    font-family: var(--font-family-proxima_nova-semibold);
    font-size: var(--font-size-16);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -0.20px;
    position: relative;
  }
  
  .fare-summary-popup .text-3 {
    color: var(--white);
    font-family: var(--font-family-proxima_nova-semibold);
    font-size: var(--font-size-18);
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -2.74px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .stops-summary-popup{
    background-color: black;
    color: white;
    width: 400px;
    height: 330px;
    z-index: 10000;
    position: absolute;
    top: 100%;
    left: -50%;
  }

