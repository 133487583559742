.main-content-popup-review {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    gap: 15px;
    width: 100%;
    position: relative;
    height: 76%;
    /* margin-bottom: 20px; */
  }

  .main-content-popup-review .frame-243-3 {
    background-color: var(--white);
    display: inline-flex;
    flex-direction: column;
    width: 68.7%;
    height: 100%;
    padding-bottom: 20px;
    overflow: auto;
  }
  .main-content-popup-review .frame-243-3.proxima {
    box-shadow: 0px 2px 5px 1px #0057b733;
  }
  .main-content-popup-review .frame-243-3.averta {
    box-shadow: 0px 0px 5px 1px var(--gold-shadow);
  }
  
  .main-content-popup-review .frame-243-6 {
    align-items: flex-start;
    flex: 0 0 auto;
    position: relative;
  }

  .main-content-popup-review .results {
    align-items: flex-start;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    gap: 5px;
    padding: 20px;
    position: relative;
    width: 98.8%;
    overflow-y: auto;
  }


  
/* Custom scrollbar styles for WebKit-based browsers */
.main-content-popup-review .results::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
  cursor: grab;
  height: 100px !important;
}

.main-content-popup-review .results.proxima::-webkit-scrollbar-track {
  height: 100px;
  background: var(--sky-light); /* Background of the scrollbar track */
  margin-top: 16px; /* Adjust to match button height */
  margin-bottom: 16px; /* Adjust to match button height */
}

.main-content-popup-review .results.averta::-webkit-scrollbar-track {
  height: 100px;
  background: var(--light-pale-bg); /* Background of the scrollbar track */
  margin-top: 16px; /* Adjust to match button height */
  margin-bottom: 16px; /* Adjust to match button height */
}

.main-content-popup-review .results.proxima::-webkit-scrollbar-thumb {
  background: var(--deep-sapphire); /* Background of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}
.main-content-popup-review .results.averta::-webkit-scrollbar-thumb {
  background: var(--magic-potion); /* Background of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

.main-content-popup-review .results::-webkit-scrollbar-button {
  display: none;
}


.main-content-popup-review .up-button-2-2 {
  top: 45px;
  right: -1px;
  align-self: end;
  position: absolute;
  z-index: 10;
  background: none;
  border: none;
}

.main-content-popup-review .down-button-2-2 {
  right: -1px;
  bottom: -8px;
  align-self: end;
  position: absolute;

  z-index: 10;
  background: none;
  border: none;
  transform: rotate(180deg)
}

  
.main-content-popup-review .frame-221 {
    align-items: flex-start;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    /* gap: 20px; */
    width: 100%;
    height: 100%;
    padding: 0px 0px 3px;
    position: relative;
  }

  .main-content-popup-review .frame-221.proxima {
    box-shadow: 0px 2px 5px 1px #0057b733;
  }
  .main-content-popup-review .frame-221.averta {
    box-shadow: 0px 0px 5px 1px var(--gold-shadow);
  }
  


  .main-content-popup-review .frame-241-3 {
    align-items: flex-start;
    background-color: var(--white);
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding-top: 12px;
    position: relative;
  }

  
.main-content-popup-review .frame-2-5 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .main-content-popup-review .frame-2-10 {
    flex: 0 0 auto;
    position: relative;
  }


  
.main-content-popup-review .frame-241-4 {
    align-items: center;
    display: inline-flex;
    justify-content: space-between;
    flex: 0 0 auto;
    gap: 8px;
    padding: 0px 20px 0px 15px;
    width: 100%;
    position: relative;
  }
  
  
  .main-content-popup-review .ff-btn-wrapper{
  display: flex;
  gap: 12px;
    }
  .main-content-popup-review .ff-btn-wrapper img{
 cursor: pointer;
    }

  .main-content-popup-review .frame-242-11 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
  }

  .main-content-popup-review .more-fare-options {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .main-content-popup-review .available-for-your-trip {
    letter-spacing: 0;
    line-height: normal;
    /* margin-top: -1.00px; */
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .main-content-popup-review .ff-container{
    width: 98%;
    margin: auto;
  }
  .main-content-popup-review .frame-242-12{
    scroll-behavior: smooth;
    overflow-y: auto;
    width: 100%;
    /* margin: auto; */
  }

  .main-content-popup-review .custom-scrollbar {
    position: absolute;
    height: 410px;
    right: 8px;
    top: 70px;
    bottom: 0;
    width: 5px;
    background-color: #e0e0e0;
    border-radius: 4px;
  }
  .main-content-popup-review .custom-scrollbar.proxima {
    background: var(--sky-light); 
  }
  .main-content-popup-review .custom-scrollbar.averta {
    background: var(--light-pale-bg); 
  }
  
  .main-content-popup-review .scroll-thumb {
    position: absolute;
    cursor: grab;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
  }
  .main-content-popup-review .scroll-thumb.proxima {
    background: var(--deep-sapphire);
  }
  .main-content-popup-review .scroll-thumb.averta {
    background: var(--magic-potion); 
  }

  
  .main-content-popup-review .frame-242-12::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  
  .main-content-popup-review .frame-241-5 {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: center;
    flex: 0 0 auto;
    /* overflow: hidden; */
    flex-direction: column;
    gap: 20px;
    /* overflow-y: auto;
    overflow-x: hidden; */
    position: relative;
  }

  .main-content-popup-review .frame-241-6 {
    /* width: 97%; */
    align-items: baseline;
    display: flex;
    /* overflow: hidden; */
    flex: 1;
    flex-direction: row;
    flex-grow: 1;
    gap: 20px;
    padding: 1px 2px;

    position: relative;
    max-height: 440px;
  }

   
/* Custom scrollbar styles for WebKit-based browsers */
/* .main-content-popup-review .frame-242-12::-webkit-scrollbar {
  width: 5px; 
  padding-left: 10px;
  cursor: grab;
  height: 100px !important;
}

.main-content-popup-review .frame-242-12.proxima::-webkit-scrollbar-track {
  height: 100px;
  background: var(--sky-light); 
  margin-top: 16px; 
  margin-bottom: 16px; 
}
.main-content-popup-review .frame-242-12.averta::-webkit-scrollbar-track {
  height: 100px;
  background: var(--light-pale-bg); 
  margin-top: 16px; 
  margin-bottom: 16px; 
}

.main-content-popup-review .frame-242-12.proxima::-webkit-scrollbar-thumb {
  background: var(--deep-sapphire); 
  border-radius: 10px; 
}
.main-content-popup-review .frame-242-12.averta::-webkit-scrollbar-thumb {
  background: var(--magic-potion); 
  border-radius: 10px; 
}

.main-content-popup-review .frame-242-12::-webkit-scrollbar-button {
  display: none;
}
.main-content-popup-review .frame-242-12::-webkit-scrollbar {
  width: 5px; 
  padding-left: 10px;
  cursor: grab;
  height: 100px !important;
} */

