.flight-error-message-2 {
    width: auto;
    z-index: 1;
    background-color: #FFE0DA;
    position: absolute;
    left: 5%;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: end;
  
  }
  