.title-tooltip {
    padding: 10px;
    width: max-content; /* Ensures the tooltip container adapts to its content */
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align content to the left */
  }
  
  .title-tooltip img {
    max-width: 100%; /* Restrict image width to prevent overflow */
    height: auto; /* Maintain aspect ratio */
  }
  
  .title-tooltip div {
    margin-bottom: 6px;
  }
  