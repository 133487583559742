.result-bar-section {
  align-items: flex-start;
  max-height: 85px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  /* overflow: hidden; */
  position: relative;
  width: 100%;
}

.result-bar-section .frame-20-3 {
  align-items: center;
  align-self: stretch;
  box-shadow: 0px 0px 11px 1px #0057b70d;
  display: flex;
  flex: 1;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.result-bar-section .fare-summary-tooltip {
  position: absolute;
  top: 138%;
  left: -175%;
  width: 293px;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s, transform 0.3s;
}

.result-bar-section .fare-summary-tooltip::after {
  content: "";
  position: absolute;
  top: -7%;
  left: 69%;
  margin-left: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent var(--rhino) transparent;

}

.result-bar-section .fare-summary:hover .fare-summary-tooltip {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.result-bar-section .frame-237 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
height: 86px;
  position: relative;
}
.result-bar-section .frame-237.proxima {
  border-right: 4px solid var(--mystic);
}
.result-bar-section .frame-237.averta {
  border-right: 4px solid var(--light-pale-bg);
}

.result-bar-section .png-transparent-luft-6 {
  object-fit: cover;
  position: relative;
}

.result-bar-section .png-transparent-luft-5 {
  height: 30px;
  width: 30px;
}

.result-bar-section .frame-175-4 {
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 5px;
  justify-content: center;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  height: 86px;
  padding-bottom: 8px;
}
.result-bar-section .frame-175-4-4 {
  height: 86px;
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 5px;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 8px;
  position: relative;
}
.result-bar-section .frame-175-4-4.proxima {
  background-color: var(--rhino);
}
.result-bar-section .frame-175-4-4.averta {
  background-color: var(--dark-galaxy);
}

.result-bar-section .frame-232 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  position: relative;
}

.result-bar-section .frame-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.result-bar-section .frame-232-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.result-bar-section .frame-232-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
  width: 100%;
}

.result-bar-section .frame-232-3 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  position: relative;
}

.result-bar-section .frame-233-4 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 30px;
  position: relative;
  width: 30px;
}

.result-bar-section .frame-233-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  position: relative;
  width: 100%;
}

.result-bar-section .turkish-airlines-symbol-1-1 {
  height: 14.12px;
  position: relative;
  width: 15px;
}

.result-bar-section .frame-234-1 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.result-bar-section .png-transparent-luft-4 {
  height: 15px;
  width: 15px;
}


.result-bar-section .frame-203-3 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 2px;
  justify-content: center;
  position: relative;
}

.result-bar-section .new-delhi-bangalore {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -3px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.result-bar-section .frame-5 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.result-bar-section .tue-30-jan-24 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.result-bar-section .frame-242 {
  cursor: pointer;
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: flex-end;
  position: relative;
}

.result-bar-section .flight-details-19 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.result-bar-section .frame-18 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.result-bar-section .frame-2-3 {
  display: inline-flex;
  gap: 4px;
}

.result-bar-section .frame-2-4 {
  align-items: center;
  flex: 0 0 auto;
  position: relative;
}

.result-bar-section .x0500-am-1200-pm {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.07px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.result-bar-section .frame-15 {
  align-items: flex-end;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.result-bar-section .frame-169-23 {
  flex: 0 0 auto;
  position: relative;
}

.result-bar-section .text-214 {
  margin-top: -1.07px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.result-bar-section .text-6 {
  letter-spacing: 0;
  line-height: normal;
}

.result-bar-section .frame {
  align-items: center;
  display: inline-flex;
  align-self: flex-end;
  flex: 0 0 auto;
  gap: 5%;
  position: relative;
}

.result-bar-section .frame-20-4 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--white);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  height: 86px;
  justify-content: center;
  padding: 0px 20px;
  position: relative;
}

.result-bar-section .frame-233-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 6px 0px 0px;
  position: relative;
  width: 100%;
}

.result-bar-section .frame-175-7 {
  align-items: center;
  background-color: var(--rhino);
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: var(--mystic);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 5px;
  justify-content: center;
  padding: 15px 25px;
  position: relative;
}

.result-bar-section .total-cost {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.result-bar-section .fare-summary {
  cursor: pointer;
  line-height: normal;
  margin-top: -1px;
}

.result-bar-section .continue {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.result-bar-section .fare {
  letter-spacing: 0;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.result-bar-section .frame-232-8 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  /* gap: 5px; */
  position: relative;
}

.result-bar-section .frame-2-2 {
  display: inline-flex;
  gap: 6px;
}

.result-bar-section .frame-2-4 {
  align-items: center;
  flex: 0 0 auto;
  position: relative;
}

.result-bar-section .frame-169-23 {
  flex: 0 0 auto;
  position: relative;
}

.result-bar-section .text-218 {
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.result-bar-section .text-6 {
  letter-spacing: 0;
  line-height: normal;
}

.result-bar-section .per-adult-15 {
  align-self: stretch;
  text-align: right;
}

.result-bar-section .per-adult-16 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.result-bar-section .frame-103-77 {
  align-items: center;
  cursor: pointer;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--endeavour);
  display: inline-flex;
  gap: 4px;
  justify-content: center;
  padding: 14.5px 23px;
}
.result-bar-section .frame-103-7 {
  height: 38px !important;
  align-items: center;
  cursor: pointer;
  border: 1px solid;
  display: inline-flex;
  gap: 4px;
  justify-content: center;
  padding: 14.5px 23px;
}
.result-bar-section .frame-103-7.proxima {
  color: var(--endeavour);
  background-color: var(--white);
  border-color: var(--endeavour);
}
.result-bar-section .frame-103-7.averta {
  color: var(--burning-gold);
  background-color: var(--white);
  border-color: var(--burning-gold);
}

.result-bar-section .frame-103-7.proxima:hover {
  color: var(--white);
  background-color: var(--endeavour);
  color: var(--white) !important;
}
.result-bar-section .frame-103-7.averta:hover {
  color: var(--white);
  background-color: var(--dark-gold);
  color: var(--white) !important;
}

.result-bar-section .frame-103-8 {
  flex: 0 0 auto;
  position: relative;
}
.result-bar-section .disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}


.result-bar-section .four-img-container {
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
}

.result-bar-section .four-img-container.frame-174-7 {
  align-items: center;
  /* align-self: stretch; */
  display: flex;
  position: relative;
}

.result-bar-section .four-img-container .frame-223-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 1px;
  position: relative;
  width: 100%;
}

.result-bar-section .four-img-container .vistara_-logo-1-1 {
  height: 12px;
  margin-bottom: -1px;
  position: relative;
  width: 12px;
}

.result-bar-section .four-img-container .frame-224-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 1px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.result-bar-section .four-img-container .frame-173-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2.69px;
  height: 10.5px;
  position: relative;
}

.result-bar-section .four-img-container .unnamed {
  height: 10.5px;
  object-fit: cover;
  position: relative;
  width: 10.5px;
}

.result-bar-section .four-img-container .frame-224 {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.13px;
  justify-content: flex-end;
  position: relative;
}

.result-bar-section .four-img-container .png-transparent-luft-5 {
  height: 12px;
  margin-top: -1px;
  width: 12px;
}

.result-bar-section .four-img-container .png-transparent-luft-6 {
  object-fit: cover;
  position: relative;
}

.result-bar-section .two-img-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 30px;
  position: relative;
  width: 30px;
}
.result-bar-section .two-img-container .frame-233-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  position: relative;
  width: 100%;
}
.result-bar-section .two-img-container .turkish-airlines-symbol-1-1 {
  height: 14.12px;
  position: relative;
  width: 15px;
}

.result-bar-section .two-img-container .frame-234-1 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.result-bar-section .two-img-container .png-transparent-luft-4 {
  height: 15px;
  width: 15px;
}

.result-bar-section .two-img-container .png-transparent-luft-6 {
  object-fit: cover;
  position: relative;
}


.result-bar-section .single-img-container.png-transparent-luft-5 {
  height: 24px;
  margin-top: -1px;
  width: 24px;
}

.result-bar-section .single-img-container.png-transparent-luft-6 {
  object-fit: cover;
  position: relative;
}


.result-bar-section .three-img-container {
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
}

.result-bar-section .three-img-container.frame-174-7 {
  align-items: center;
  align-self: stretch;
  min-width: 20px;
  display: flex;
  position: relative;
}

.result-bar-section .three-img-container .frame-223-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 1px;
  position: relative;
  width: 100%;
}

.result-bar-section .three-img-container .vistara_-logo-1-1 {
  height: 12px;
  margin-bottom: -1px;
  position: relative;
  width: 12px;
}

.result-bar-section .three-img-container .frame-224-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 1px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.result-bar-section .three-img-container .frame-173-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2.69px;
  height: 10.5px;
  position: relative;
}

.result-bar-section .three-img-container .unnamed {
  height: 10.5px;
  object-fit: cover;
  position: relative;
  width: 10.5px;
}

.result-bar-section .three-img-container .frame-224 {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.13px;
  justify-content: flex-end;
  position: relative;
}

.result-bar-section .three-img-container .png-transparent-luft-5 {
  height: 12px;
  margin-top: -1px;
  width: 12px;
}

.result-bar-section .three-img-container .png-transparent-luft-6 {
  object-fit: cover;
  position: relative;
}