  .result-data-section .frame-257-1 {
    align-items: flex-start;
    align-self: stretch;
    /* background-color: var(--white); */
    display: flex;
    flex: 0 0 auto;
    min-height: 57.77px;
    flex-direction: column;
    gap: 7px;
    position: relative;
    width: 100%;
  }

   .result-data-section .frame-168-4::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  
   .result-data-section .frame-168-4 {
    align-items: flex-start;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 15px;
    height: 1253.73px;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    /* padding: 92px 0px 0px; */
  }
  
   .result-data-section .frame-168-6 {
    display: flex;
    position: relative;
  }

   .result-data-section .frame-232-12 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 7px;
    position: relative;
    width: 100%;
  }

  .result-data-section .frame-232-13 {
    /* background-color: var(--white); */
    align-items: flex-start;
    display: flex;
    /* width: 100px; */
    /* max-width: 500px; */
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    position: relative;
  }
