.tripSliderTwo {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px 0px 15px;
}

.tripSliderTwo.proxima {
  width: 89%;
  margin-left: 20px;
  background-color: var(--dark-purple);
  }
.tripSliderTwo.averta {
  width: 90%;
  margin-left: 17px;
  background-color: var(--dark-galaxy);
  }

.tripSliderTwo-1 {
  display: flex;
  width: 92%;
  margin: auto;
}
.tripSliderTwo-1 .sliderBar{
  min-width: 183px;
}
.tripSliderTwo-1 .down-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333; /* Change the color as needed */
  margin: 0 auto;
}

.tripSliderTwo-1 .frame-237-33 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.tripSliderTwo .frame-179 {
  cursor: pointer;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 4px 0px;
  position: relative;
  max-width: 350px;
  min-width: 180px;
  overflow: hidden;
  /* width: 417px; */
}

.tripSliderTwo .bg-dark-purple.proxima {
  background-color: var(--dark-purple);
}
.tripSliderTwo .bg-dark-purple.averta {
  background-color: var(--optera-galaxy);
}
.tripSliderTwo .bg-white {
  background-color: var(--light-sky);
}

.tripSliderTwo .frame-238-5 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 1px 15px;
  position: relative;
  width: 100%;
}

.tripSliderTwo .frame {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2px;
  position: relative;
}

.tripSliderTwo .frame-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.tripSliderTwo .flight-details {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.tripSliderTwo .frame-239 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 15px;
  padding: 5px 14px;
  position: relative;
  width: 100%;
}

.tripSliderTwo .frame-238-6 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 2px;
  position: relative;
}

.tripSliderTwo .frame-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.tripSliderTwo .place {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
.tripSliderTwo .frame-170-1 {
  align-items: center;
  gap: 2px;
}

.tripSliderTwo .frame-170-4 {
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.tripSliderTwo .place-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
