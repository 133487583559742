/* Tooltip.css */
.Seattooltip {
    position: absolute;
    background: #333;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1000;
  }
  
  .Seattooltip.visible {
    opacity: 1;
  }
  
  .Seattooltip-content {
    padding: 8px;
  }
  
  .Seattooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  