.frame-slider {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    overflow: hidden;
    /* padding: 10px 0px; */
    position: relative;
    width: 100%;
  }

.frame-slider.proxima {
    background-color: var(--mystic);
  }
.frame-slider.averta {
    background-color: var(--light-pale-bg);
  }
  
  .frame-slider .frame-233-5 {
    align-items: center;
    display: flex;
    transition: transform 0.5s ease-in-out;
    /* width: 400% */
  }
  
  .frame-slider .frame-17 {
    cursor: pointer;
    margin-right: 10px;
    width: 305px;
    align-items: flex-start;
    background-color: var(--white);
    border: 1.5px solid;
    border-color: var(--magic-potion-2);
    flex: 0 0 33.33%; /* Show partial of the next slide */
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    padding: 10px 20px;
    position: relative;
    opacity: 0.5; /* Partially visible slides */
    transition: opacity 0.5s ease-in-out;
  }
  
  .frame-slider .frame-17.active {
    opacity: 1; /* Fully visible slide */
  }
  
  .frame-slider .frame-233 {
    align-items: center;
    display: inline-flex;
    gap: 12px;
    justify-content: center;
    position: relative;
  }
  
  .frame-slider .frame-234 {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 10px;
    position: relative;
    width: 100%;
  }
  
  .frame-slider .frame-233-6,
  .frame-slider .frame-234-4 {
    cursor: pointer;
    height: 22px;
    position: absolute;
    top: 15px;
    width: 22px;
  }
  
  .frame-slider .frame-233-6 {
    left: 0px;
  }
  
  .frame-slider .frame-234-4 {
    right: 0px;
  }
  
  .frame-slider .trip-1 {
    line-height: normal;
    margin-top: -3.00px;
    white-space: nowrap;
    width: fit-content;
    font-size: small;
  }
  