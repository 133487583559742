.bag-section {
    padding: 20px 15px;
    border: 0.8px solid var(--spindle-2);
    border-top: none;
    display: flex;
    width: 100%;
  }
  .bag-section .data-section {
    width: 30%;
    display: flex;
    flex-direction: column;
  }
  .bag-section .data-section .travellers-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .bag-section .data-section .travellers-content .traveller-row-data {
    display: flex;
  }
  
  .bag-section .data-section .plane-content {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    gap: 20px;
    padding-left: 12px;
    width: 70%;
    flex-direction: column;
  }
  
  .bag-section .bags-section {
    width: 70%;
    flex-direction: column;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .bag-section .bags-section .bags-wrapper {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
  .bag-section .bags-section .bag-item{
  display: flex;
    padding: 20px 10px;
    width: 70%;
      justify-content: space-between;
  }
  .bag-section .bags-section .bag-item.isActiveBag{
    background: #f6f6f6;
  }
  .bag-section .bags-section .bag-item .bag-info-container{
  display: flex;
   gap: 15px;
   align-items: center;
  }
  .bag-section .bags-section .bag-item .bag-info-container .bag-info{
  display: flex;
  flex-direction: column;
  gap: 3px;
  }
  
  .bag-section .bags-section .bag-item .bag-controllers-container{
  display: flex;
   align-items: center;
   justify-content: center;
   gap: 15px;
  }
  .bag-section .bags-section .bag-item .bag-controllers-container img{
    cursor: pointer;
  }
  
  .bag-section .bags-section::-webkit-scrollbar {
    width: 7px; /* Width of the scrollbar */
  }
  
  .bag-section .bags-section::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  
  .bag-section .bags-section.proxima::-webkit-scrollbar-track {
    background: var(--mystic); /* Color of the scrollbar track */
  }
  .bag-section .bags-section.averta::-webkit-scrollbar-track {
    background: var(--light-pale-bg); /* Color of the scrollbar track */
  }
  
  .bag-section .bags-section::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
  
  .bag-section .bags-section.proxima::-webkit-scrollbar-thumb {
    background-color: var(--rhino); /* Color of the scrollbar thumb */
    border: 2px solid var(--mystic); /* Adds space around the thumb */
  }
  
  .bag-section .bags-section.averta::-webkit-scrollbar-thumb {
    background-color: var(--blue-zodiac); /* Color of the scrollbar thumb */
    border: 2px solid var(--light-pale-bg); /* Adds space around the thumb */
  }
  
  .bag-section .bags-section.proxima::-webkit-scrollbar-thumb:hover {
    background-color: var(
      --rhino
    ); /* Color when hovering over the scrollbar thumb */
  }
  
  .bag-section .bags-section.averta::-webkit-scrollbar-thumb:hover {
    background-color: var(
      --blue-zodiac
    ); /* Color when hovering over the scrollbar thumb */
  }
  
  /* Scrollbar Buttons (Up Arrow) */
  .bag-section .bags-section::-webkit-scrollbar-button:vertical:decrement {
    background: url("../../../../../Assets/Images/scroll-arrow-up-df.svg")
      no-repeat center center;
    /* background-size: 100% 100%; */
    transform: rotate(90deg);
    height: 16px;
    width: 10px;
    cursor: pointer;
  }
  
  /* Scrollbar Buttons (Down Arrow) */
  .bag-section .bags-section::-webkit-scrollbar-button:vertical:increment {
    background: url("../../../../../Assets/Images/scroll-arrow-down-df.svg")
      no-repeat center center;
    background-size: 100% 100%;
    height: 16px;
    width: 10px;
    cursor: pointer;
  }
  