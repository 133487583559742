.addons-section-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 75%;
  padding-bottom: 20px;
}

.addons-section-wrapper .addons-main {
  background-color: var(--white);
}
.addons-section-wrapper .ancellary-loading {
  background-color: var(--white);
 display: flex;
align-items: center;
justify-content: center;
height: 70vh;
gap: 20px;
width: 100%;

}

.addons-section-wrapper .ancellary-loading .loader-big:before , .addons-section-wrapper .ancellary-loading .loader-big:after , .addons-section-wrapper .ancellary-loading .loader-big{

  background: radial-gradient(closest-side at 50% 12.5%,
  var(--black) 96%,#0000) 50% 0/20% 80% repeat-y,
 radial-gradient(closest-side at 12.5% 50%,
  var(--black) 96%,#0000) 0 50%/80% 20% repeat-x;

}
.addons-section-wrapper .ancellary-loading .loader-big:after , .addons-section-wrapper .ancellary-loading .loader-big:before , .addons-section-wrapper .ancellary-loading .loader-big{

  background: radial-gradient(closest-side at 50% 12.5%,
  var(--black) 96%,#0000) 50% 0/20% 80% repeat-y,
 radial-gradient(closest-side at 12.5% 50%,
  var(--black) 96%,#0000) 0 50%/80% 20% repeat-x;

}

/* HTML: <div class="loader"></div> */
.addons-section-wrapper .ancellary-loading .loader-big {
  width: 20px;
  margin-right: 15px;
  aspect-ratio: 1;
  display:grid;
  -webkit-mask: conic-gradient(from 15deg,#0000,#000);
  animation: l26 1s infinite steps(12);
}

.addons-section-wrapper .ancellary-loading .loader-big:before,
.addons-section-wrapper .ancellary-loading .loader-big:after {
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
}
.addons-section-wrapper .ancellary-loading .loader-big:after {
  transform: rotate(60deg);
}



.addons-section-wrapper .addons-main .main-addon-content {
  padding: 10px 15px;
}


/* // from here  */

.addons-section-wrapper .continue-section {
  height: 45px;
  align-items: center;
  display: flex;
  gap: 10px;
  padding: 0px 2px;
  justify-content: space-between;
  flex: 0 0 auto;
  position: relative;
}

.addons-section-wrapper .continue-section .info-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.addons-section-wrapper .continue-section .frame-259 {
  align-items: center;
  /* border: 1px solid; */
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  justify-content: center;
  padding: 17px 34px;
  position: relative;
  cursor: pointer;
}


.addons-section-wrapper .continue-section .content-header .info-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.addons-section-wrapper .continue-section .frame-259.proxima {
  background-color: var(--endeavour);
}
.addons-section-wrapper .continue-section .frame-259.proxima.shake {
  animation: shake 0.5s ease-in-out infinite;
}
.addons-section-wrapper .continue-section .frame-259.averta {
  background-color: var(--text-pale-bg);
}
.addons-section-wrapper .continue-section .frame-259.averta.shake {
  animation: shake 0.5s ease-in-out infinite;
}
.addons-section-wrapper .continue-section .continue {
  display: flex;
  flex-direction: row;
  gap: 20px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
