/* screen - trip-type-proxima-2 */

.Flights-Options-Popup-trip-type-proxima-2 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.Flights-Options-Popup-frame-41 {
  background-color: #ffffff;
  flex: 0 0 auto;
  width: 175px;
}

.Flights-Options-Popup-frame-10 {
  align-self: stretch;
  flex: 0 0 auto;
  gap: 8px;
  padding: 6px 15px;
  width: 100%;
}

.Flights-Options-Popup-frame-10.proxima:hover {
  background-color: var(--light-sky-bg-hov);
  transform: scale(1.001);
  transform-origin: left top; /* Adjust the origin point if necessary */
  transition: transform 0.2s; /* Smooth transition effect */
}

.Flights-Options-Popup-frame-10.averta:hover {
  background-color: var(--light-pale-bg-hov);
  transform: scale(1.001);
  transform-origin: left top; /* Adjust the origin point if necessary */
  transition: transform 0.2s; /* Smooth transition effect */
}


.Flights-Options-Popup-frame-57 {
  align-self: stretch;
  height: 24px;
  justify-content: center;
  width: 100%;
}

.Flights-Options-Popup-frame {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}
