
.trip-chooser {
  background-color: var(--white);
  display: flex;
  align-items: center;
  gap: 5px;
  padding-left: 20px;
  padding-right: 35px;
  padding-top: 10px;
  padding-bottom: 10px;

  position: relative;
  width: 100%;
}
.trip-chooser .frame-2-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  justify-content: flex-end;
  position: relative;
}

.trip-chooser .frame-242-1 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.trip-chooser .group-261 {
  display: flex;
  align-items: center;
 gap: 10px;
  width: 140px;
}

.trip-chooser .line-straight {

  width: 0.5px;
  background-color: var(--pumice);
  height: 12px;
}
.trip-chooser .line-through {

  height: 1.5px;
  background-color: var(--pumice);
  width: 12px;
}

.trip-chooser .frame-202-1 {
  height: 10.05px;
  left: 63px;
  padding: 0px 0px 0px 10px;
  position: absolute;
  top: 0;
}

.trip-chooser .frame-202-4 {
  align-items: center;
  display: inline-flex;
  gap: 5px;
}

.trip-chooser .address {
  color: var(--gravel);
  font-family: var(--font-family-proxima_nova-regular);
  font-size: var(--font-size-12);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}
.trip-chooser .address-5 {
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.trip-chooser .frame-242-12 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.trip-chooser .frame-202-2 {
  flex: 0 0 auto;
  justify-content: flex-end;
  padding: 0px 0px 0px 1.5px;
  position: relative;
}



.trip-chooser .group-206-2 {
  height: 10.05px;
  position: relative;
  width: 10.05px;
}

.trip-chooser .overlap-group-3 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--gravel);
  border-radius: 6.03px;
  display: flex;
  height: 12px;
  left: -1px;
  min-width: 12px;
  padding: 1.8px 4.2px;
  position: relative;
  top: -1px;
}


.trip-chooser .vector-50-2 {
  height: 5px;
  width: 3px;
}

.trip-chooser .x2h-40m-1 {
  margin-top: -0.47px;
}


.trip-chooser .x2h-40m-3 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}



.trip-chooser .frame-236 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 0px 0px 0px 6px;
  position: relative;
}


.trip-chooser .place-7 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
.trip-chooser .frame-170 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2px;
  justify-content: center;
  position: relative;
}

.trip-chooser .line-5 {
  height: 1px;
  margin-left: -0.50px;
  object-fit: cover;
  position: relative;
  width: 6px;
}



.frame-202 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  justify-content: center;
  padding: 0px 0px 0px 1.5px;
  position: relative;
}

.trip-chooser .line-8 {
  height: 1px;
  margin-right: -0.50px;
  object-fit: cover;
  position: relative;
  width: 6px;
}

.trip-chooser-slider {
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: 20px;
  padding-right: 35px;
  /* max-width: 880px; */
}
.trip-chooser-slider .back-arrow {
  position: absolute;
  z-index: 1;
  left: 10px;
  cursor: pointer;
}

.trip-chooser-slider .forward-arrow {
  position: absolute;
  z-index: 1;
  right: 25px;
  cursor: pointer;
}


.trip-chooser .place-7 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.trip-chooser .frame-244 {
  width: 50%;
  cursor: pointer;
  align-items: flex-end;
  min-height: 52px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  padding: 10px 15px;
  position: relative;
}
.trip-chooser .frame-244.selected.proxima {
  background-color: var(--light-water);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--endeavour);

}
.trip-chooser .frame-244.non-selected.proxima {
  background-color: var(--dim-sunlight);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--pumice);
}
.trip-chooser .frame-244.selected.averta {
  background-color: var(--light-pale-bg);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--light-pale-border);

}
.trip-chooser .frame-244.non-selected.averta {
  background-color: var(--dim-sunlight);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--pumice);
}

.trip-chooser .frame-2-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  justify-content: flex-end;
  position: relative;
}
.trip-chooser .frame-242-1 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.trip-chooser .group-265 {
  height: 11.05px;
  position: relative;
  width: 136px;
}


.trip-chooser .non-selected {
  color: var(--stack);
  font-family: var(--font-family-proxima_nova-semibold);
  font-size:  var(--font-size-12);
  font-weight: 400;
}

.trip-chooser .frame-202-3 {
  border-color: var(--stack);
  border-left-style: solid;
  border-left-width: 1px;
  height: 11.05px;
  left: 57px;
  padding: 0px 0px 0px 10px;
  position: absolute;
  top: 0;
}

.trip-chooser .address-2 {
  color: var(--stack);
  font-family: var(--font-family-proxima_nova-regular);
  font-size: var(--font-size-12);
  font-weight: 400;
  text-align: right;
}


.trip-chooser .address-10 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.trip-chooser .frame-242-13 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9px;
  position: relative;
}

.frame-202 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  justify-content: center;
  padding: 0px 0px 0px 1.5px;
  position: relative;
}

.trip-chooser .group-206-3 {
  height: 11.05px;
  position: relative;
  width: 11.05px;
}

.trip-chooser .overlap-group-4 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--stack);
  border-radius: 6.53px;
  display: flex;
  height: 13px;
  left: -1px;
  min-width: 13px;
  padding: 2.1px 4.7px;
  position: relative;
  top: -1px;
}  

.trip-chooser .vector-50-3 {
  height: 6px;
  width: 3px;
}



.trip-chooser .x2h-40m-3 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.trip-chooser .frame-243-4 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  padding: 0px 6px;
}


.trip-chooser .frame-243-6 {
  flex: 0 0 auto;
  position: relative;
}


.trip-chooser .place {

  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.trip-chooser .frame-170 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2px;
  justify-content: center;
  position: relative;
}
