
.status-counter {
  width: 1342px;
  display: flex;
  align-items: center;
  }

  .status-container-wrapper{
    height: 72px;
  }

  .status-counter .frame-244-7 {
    align-items: center;
    background-color: var(--pigeon-post);
    display: flex;
    gap: 260px;
    left: 0;
    padding: 12px 76px 14px;
    position: absolute;
    top: 0;
    width: 1512px;
  }

  .status-counter .frame-293-4 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    min-width: 990px;
    padding-top: 12px;
    padding-bottom: 14px;
  }

  .status-counter .progress-bar-1 {
    background-color: var(--dark-rain);
    height: 0.8px;
    width: 383px;
    position: absolute;
    top: 29px;
  }
  .status-counter .progress-bar-1.proxima {
    background-color: var(--dark-rain);
    left: 7%;
  }
  .status-counter .progress-bar-1.averta {
    background-color: var(--light-pale-border);
    left: 7.3%;
  }
  .status-counter .progress-bar-1.proxima.selected {
    background-color: var(--magic-potion);
  }
  .status-counter .progress-bar-1.averta.selected {
    background-color: var(--choclate);
  }

  .status-counter .progress-bar-2 {
    height: 0.8px;
    width: 410px;
    position: absolute;
    left: 49%;
    top: 29px;
  }
  .status-counter .progress-bar-2.proxima {
    background-color: var(--dark-rain);
  }
  .status-counter .progress-bar-2.averta {
    background-color: var(--light-pale-border);
  }
  .status-counter .progress-bar-2.proxima.selected {
    background-color: var(--magic-potion);
  }
  .status-counter .progress-bar-2.averta.selected {
    background-color: var(--choclate);
  }

  .status-counter .frame-293-5 {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    position: relative;
  }
  
  .status-counter .frame-293-6 {
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    display: flex;
    width: 30px;
    height: 30px;
    flex-direction: column;
  }

  .status-counter .frame-293-6.proxima {
    background-color: var(--dark-purple);
  }
  .status-counter .frame-293-6.averta {
    background-color: var(--choclate);
  }

  .status-counter .frame-293-7 {
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    display: flex;
    width: 30px;
    height: 30px;
    flex-direction: column;
  }
  .status-counter .frame-293-7.proxima {
    border: 2px solid var(--dark-purple);
    background-color: var(--spindle-3);
  }
  .status-counter .frame-293-7.averta {
    border: 2px solid var(--choclate);
    background-color: var(--light-yellow);
  }


  .status-counter .number-18 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }


  .status-counter .review-itinerary {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .status-counter .frame-294 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    padding: 0px 20px;
    position: relative;
  }

  
.status-counter .number {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    width: 8px;
  }


  
.status-counter .add-traveller-details-1 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }


  
 .status-counter .frame-295 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    padding: 0px 2px 0px 0px;
    position: relative;
  }

  .status-counter .frame-294-1 {
    align-items: center;
    background-color: var(--spindle-3);
    border: 1px solid;
    border-color: var(--cloud-burst);
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 30px;
    padding: 9.5px 11px;
    position: relative;
    width: 30px;
  }

  .status-counter .seats-meals-baggage-1 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }


