.container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .slider {
    position: relative;
    width: 270px;
  }
  
  .slider__track,
  .slider__range,
  .slider__left-value,
  .slider__right-value {
    position: absolute;
  }
  
  .slider__track,
  .slider__range {
    border-radius: 3px;
    height: 3px;
  }
  
  .slider__track {
    background-color: var(--pumice);
    width: 100%;
    z-index: 1;
  }
  
  .slider__range.proxima {
    background-color: var(--endeavour);
    z-index: 2;
  }
  .slider__range.averta {
    background-color: var(--dark-gold);
    z-index: 2;
  }
  
  
  .slider__left-value,
  .slider__right-value {
    color: #dee2e6;
    font-size: 12px;
    margin-top: 20px;
  }
  
  .slider__left-value {
    left: 6px;
  }
  
  .slider__right-value {
    right: -4px;
  }
  
  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
  }
  
  .thumb--zindex-3 {
    z-index: 3;
  }
  
  .thumb--zindex-4 {
    z-index: 4;
  }
  
  .thumb--zindex-5 {
    z-index: 5;
  }
  

  .thumb::-webkit-slider-thumb {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 0.5px 19px -7px #001446;
    pointer-events: all;
    position: relative;
    cursor:pointer;
  }
  
  .thumb::-moz-range-thumb {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 0.5px 19px -7px #001446;
    pointer-events: all;
    position: relative;
    cursor:pointer;
  }
  
  .thumb::-ms-thumb {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 0.5px 19px -7px #001446;
    pointer-events: all;
    position: relative;
    cursor:pointer;
  } 


/* 
  .thumb::-webkit-slider-thumb {
    background-color: #ffffff;
    border: none;
    border-radius: 50%;

    -webkit-box-shadow:0 0 2.93px 2.2x #001446;
    -moz-box-shadow:0 0 2.93px 2.2x #001446;
    box-shadow: 0 0 2.93px 2.2x #001446;
    cursor: pointer;
    height: 23.57px;
padding: 7.33px 7.33px 7.33px 8.8px;
    width: 22px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  

  .thumb::-moz-range-thumb {
    background-color: #FFFFFF;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 5px var(--pumice);
    cursor: pointer;
    height: 23.57px;
padding: 7.33px 7.33px 7.33px 8.8px;
    width: 22px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  
  
   */