.fare-details-sidebar {
    align-self: stretch;
    flex: 0 0 auto;
    width: 100%;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .fare-details-sidebar .frame-241-1 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--white);
    border-bottom-style: solid;
    border-bottom-width: 1.5px;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 15px 20px;
    position: relative;
    width: 100%;
  }
  .fare-details-sidebar .frame-241-1.proxima {
    border-color: var(--mystic);
  }
  .fare-details-sidebar .frame-241-1.averta {
    border-color: var(--text-pale-bg);
  }

  .fare-details-sidebar .frame-242-6 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 8px;
    position: relative;
  }

  .fare-details-sidebar .price-identifier-Increased {
  padding: 10px 20px;
  background-color: lightcoral;
  color: white;
  width: 100%;
  }
  .fare-details-sidebar .price-identifier-Decreased {
  padding: 10px 20px;
  background-color: lightgreen;
  /* color: white; */
  width: 100%;
  }
  .fare-details-sidebar .fare-summary {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .fare-details-sidebar .frame-244 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--white);
    border-bottom-style: solid;
    border-bottom-width: 1.5px;
    border-color: var(--magic-potion);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    padding: 0px 20px;
    position: relative;
    width: 100%;
  }

  .fare-details-sidebar .frame-244.proxima {
    border-color: var(--magic-potion);
  }
  .fare-details-sidebar .frame-244.averta {
    border-color: var(--text-pale-bg);
  }

  .fare-details-sidebar .component {
    align-items: flex-start;
    align-self: stretch;
    border-bottom-style: solid;
    border-bottom-width: 0.4px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0px;
    position: relative;
    width: 100%;
  }
  .fare-details-sidebar .component.proxima {
    border-color: var(--mystic);
  }
  .fare-details-sidebar .component.averta {
    border-color: var(--text-pale-bg);
  }

  .fare-details-sidebar .frame-24 {
    cursor: pointer;
    align-items: baseline;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
    width: 100%;
  }

  .fare-details-sidebar .frame-293-2 {
    align-items: center;
    flex: 1;
    flex-grow: 1;
    gap: 7px;
  }
  
  .fare-details-sidebar .base-fare {
    letter-spacing: 0;
    line-height: 16px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .fare-details-sidebar .frame-293-10 {
    display: flex;
align-items: baseline;
  }

  .fare-details-sidebar .frame-3 {
    flex: 0 0 auto;
    position: relative;
  }
  
.fare-details-sidebar .frame-173-1 {
    display: flex;
    gap: 4px;
  }

  .fare-details-sidebar .text-65 {
    margin-top: -2.74px;
  }

  
.fare-details-sidebar .valign-text-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  
.fare-details-sidebar .text-1 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .fare-details-sidebar .frame-5 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 100%;
  }

  .fare-details-sidebar .frame-24-1 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px 0px 0px 12px;
    width: 100%;
  }

  .fare-details-sidebar .frame-24-3 {
 display: flex;
 align-items: baseline;
 gap: 4px;
  }

  .fare-details-sidebar .frame-243-5 {
    align-items: baseline;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 4px;
    position: relative;
  }

  
.fare-details-sidebar .frame-173-2 {
    display: flex;
   align-items: baseline;
    gap: 3px;
  }


  .fare-details-sidebar .text-67 {
    margin-top: -2.74px;
  }  

  .fare-details-sidebar .frame-245-2 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 25px 20px;
    width: 100%;
  }

  .fare-details-sidebar .frame-245-3 {
    flex: 0 0 auto;
    position: relative;
  }
  .fare-details-sidebar .frame-2-1 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  .fare-details-sidebar .total-amount {
    flex: 1;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1.00px;
    position: relative;
  }
  
  
.fare-details-sidebar .frame-173-3 {
    display: flex;
    align-items: baseline;
    /* flex: 1; */
    /* flex-grow: 1; */
    gap: 4px;
  }
  


  .fare-details-sidebar .text-86 {
    margin-top: -2.74px;
  }
