.filter-bar {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 20px;
    justify-content: flex-end;
    padding: 10px;
    position: relative;
    width: 100%;
  }

.filter-bar.proxima {
    background-color: var(--white);
  }

.filter-bar.averta {
    background-color: var(--light-pale-bg);
  }

  
  .filter-bar .frame-5 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    justify-content: space-between;
    position: relative;
  }

  
  .filter-bar .search-bar-hover {
    align-items: center;
    align-self: stretch;
    border-bottom-style: solid;
    border-bottom-width: 2.50px;
    display: flex;
    gap: 7px;
    padding: 8px 10px 8px 11px;
    position: relative;
    width: 245px;
  }
  
  .filter-bar .search-bar-hover.proxima {
    background-color: var(--white);
    border-color: var(--pumice);
  }
  .filter-bar .search-bar-hover.averta {
    background-color: var(--light-pale-bg);
    border-color: var(--burning-gold);
  }

  .filter-bar .search {
    height: 18.58px;
    position: relative;
    width: 18.58px;
  }

  .filter-bar .search-input:focus {
    border: none !important;
    box-shadow: none;
    outline: none !important;
  }

  .filter-bar .search-input {
    width: 115%;
    border: none;
  }
  .filter-bar .search-input.proxima {
   background-color: var(--white);
  }
  .filter-bar .search-input.averta {
    background-color: var(--light-pale-bg);
  }
  .filter-bar .search-input::placeholder {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }


  .filter-bar .frame-259 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}


.filter-bar .frame-8 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 7px;
    justify-content: center;
    position: relative;
  }
  
  .filter-bar .frame-257-1 {
    gap: 6px;
  }
  
  .filter-bar .frame-257-4 {
    display: contents !important;
  }
  .filter-bar .frame-257-4-4 {
    display: flex !important;
  }
  .filter-bar .frame-257-4-4-4 {
    display: flex !important;
    /* border-bottom-style: solid;
    border-bottom-width: 0.2px;
    border-bottom-color: var(--pumice); */
  }

  .filter-bar .frame-262 {
    cursor: pointer;
    align-items: center;
    background-color: var(--concrete);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 12px;
    padding: 8px 18px;
    position: relative;
  }

  .filter-bar .frame {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }

  
  .filter-bar .frame-259-1 {
    background-color: var(--pumice);
    gap: 8.81px;
    padding: 4px 6px;
  }
  
  .filter-bar .overlap-group-4 {
    height: 14px;
    position: relative;
    width: 17px;
  }

  .filter-bar .frame-259-3 {
    align-items: center;
    border-radius: 1px;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }
  .filter-bar .group-259 {
    height: 14.43px;
    position: relative;
    width: 16.95px;
  }
  
  .filter-bar .vector-116 {
    height: 13px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 15px;
  }
  
  .filter-bar .ellipse-42 {
    height: 1px;
    left: 4px;
    position: absolute;
    top: 6px;
    width: 1px;
  }
  
  .filter-bar .ellipse-43 {
    border: 0.57px solid;
    border-color: var(--white);
    border-radius: 4.08px;
    height: 8px;
    left: 9px;
    position: absolute;
    top: 0;
    width: 8px;
  }

  .filter-bar .vector-6 {
    height: 4px;
    left: 12px;
    position: absolute;
    top: 2px;
    width: 3px;
  }

  .filter-bar .cheapest {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  
  .filter-bar .frame-261 {
    cursor: pointer;
    align-items: center;
    background-color: var(--light-envedor);
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-color: var(--endeavour);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 12px;
    padding: 8px 18px;
    position: relative;
  }

  
.filter-bar .frame-259-2 {
    background-color: var(--endeavour);
    flex-direction: column;
    gap: 10px;
    padding: 6px 3px;
  }

  .filter-bar .frame-259-3 {
    align-items: center;
    border-radius: 1px;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }

  .filter-bar .group-206-2 {
    height: 10.77px;
    position: relative;
    width: 19.74px;
  }

  .filter-bar .overlap-group-5 {
    align-items: flex-start;
    border: 0.81px solid;
    border-color: var(--white);
    border-radius: 6.19px;
    display: flex;
    height: 12px;
    justify-content: flex-end;
    left: 8px;
    min-width: 12px;
    padding: 2.5px 5.0px;
    position: absolute;
    top: -1px;
  }

  .filter-bar .vector-50 {
    height: 5px;
    width: 2px;
  }
  
  .filter-bar .group-258 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 3px;
    left: 0;
    min-height: 7px;
    position: absolute;
    top: 3px;
    width: 6px;
  }
  
  .filter-bar .line-33 {
    height: 1px;
    margin-left: 2.14px;
    width: 4px;
  }
  
  .filter-bar .line-32 {
    height: 1px;
    width: 6px;
  }
  
  .filter-bar .line-34 {
    height: 1px;
    margin-left: 2.36px;
    width: 4px;
  }
  
  .filter-bar .fastest {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  
  .filter-bar .frame-258 {
    width: 303px;
    height: 34px;
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 20px;
    justify-content: flex-end;
    position: relative;
  }
  
  .filter-bar .frame-257-2 {
    gap: 20px;
  }



  
.filter-bar .frame-103-5 {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 12px;
    position: relative;
  }

  .filter-bar .frame-233-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 12px;
    position: relative;
  }

  .filter-bar .sort-price-by {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .filter-bar .frame-258-1 {
    cursor: pointer;
    align-items: center;
    border-bottom-style: solid;
    border-bottom-width: 3px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 12px;
    padding: 8px 9px;
    position: relative;
  }
  .filter-bar .frame-258-1.proxima {
    border-color: var(--magic-potion);
  }
  .filter-bar .frame-258-1.averta {
    border-color: var(--burning-gold);
  }
  
  .filter-bar .per-adult {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .filter-bar .frame-262-1 {
    cursor: pointer;
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 12px;
    padding: 8px 10px;
    position: relative;
  }
  .filter-bar .frame-262-1.proxima {
    border-bottom-style: solid;
    border-bottom-width: 0.8px;
    background-color: var(--white);
    border-color: var(--pumice);
  }
  .filter-bar .frame-262-1.averta {
    background-color: var(--light-pale-bg);
    border-bottom-style: solid;
    border-bottom-width: 0.2px;
    border-bottom-color: var(--pumice);
    /* border-color: none; */
  }

  
.filter-bar .group-233 {
    height: 18px;
    margin-right: -2.00px;
    position: relative;
    width: 88px;
  }
  
.filter-bar .all-travellers {
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
