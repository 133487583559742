.coupon-section {
    align-items: flex-start;
    align-self: stretch;
    /* background-color: var(--spindle);
    box-shadow: 0px 2px 5px 1px #0057b733; */
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 1px;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .coupon-section .frame-241-3 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 15px 20px;
    position: relative;
    width: 100%;
  }

  .coupon-section .frame-242-6 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 8px;
    position: relative;
  }


  .coupon-section.have-a-coupon-code {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .coupon-section .frame-244-8 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--white);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--mystic);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    padding: 15px 20px 20px;
    position: relative;
    width: 100%;
  }
  
  .coupon-section .frame-2-15 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 100%;
  }


  .coupon-section .frame-2-11 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
    width: 100%;
  }

  .coupon-section .frame-129 {
    align-items: center;
    border: 1px solid;
    border-color: var(--stack);
    border-radius: 3px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: center;
    overflow: hidden;
    padding: 14.5px 15px;
    position: relative;
  }

  .coupon-section .enter-coupon-code {
    flex: 1;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
  }

  .coupon-section .apply {
    cursor: pointer;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .coupon-section .valign-text-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }


  .coupon-section .frame-244-9 {
    align-items: center;
    align-self: stretch;
    border: 1px solid;
    border-color: var(--spindle);
    border-radius: 3px;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 20px 15px;
    position: relative;
    width: 100%;
    height: 112px;
  }

  .coupon-section .frame-173-2 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    position: relative;
  }
  
  .coupon-section .frame-173-3 {
    align-items: center;
    display: flex;
    gap: 7.24px;
    height: 19px;
    position: relative;
    width: 19px;
  }

.coupon-section .cross-img {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
}
.coupon-section .coupon-selector {
   cursor: pointer;
}

  
.coupon-section .frame-2-14 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 6px;
    position: relative;
  }

  
.coupon-section .frame-2-9 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    position: relative;
    width: 100%;
  }

  
.coupon-section .frame-2-11 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
    width: 100%;
  }

  .coupon-section .stintl {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .coupon-section .valign-text-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .coupon-section .frame-289 {
    align-items: end;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    gap: 9px 5px;
    position: relative;
    width: 100%;
  }
  
  .coupon-section .use-this-code-to-get {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .coupon-section .instant-discount {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .coupon-section .upto-11 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .coupon-section .frame-173-4 {
    align-items: baseline;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    justify-content: flex-end;
    position: relative;
  }
  .coupon-section .frame-4 {
    flex: 0 0 auto;
    position: relative;
  }

  .coupon-section .number-13 {
    margin-top: -2.74px;
  }

  .coupon-section .number-18 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .coupon-section .terms-conditions {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
