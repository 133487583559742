/* screen - traveller-class-overlay-proxima2 */

.traveller-class-overlay-proxima2 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-99 {
  align-items: flex-start;
  display: inline-flex;
  gap: 63px;
  left: 353px;
  position: absolute;
  top: 0;
}


.traveller-class-overlay-proxima2 .travellersPopup-address {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 16px;
  position: relative;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-100 {
  align-items: flex-start;
  background-color: var(--white);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding: 15px 0px;
  position: relative;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-105 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  padding: 0px 15px;
  position: relative;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-102 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 20px 20px;
  overflow: hidden;
  position: relative;
  width: 576px;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-58-1 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  padding: 0px 0px 3px 6px;
  position: relative;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-57 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-109 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  justify-content: center;
  position: relative;
}

.traveller-class-overlay-proxima2 .travellersPopup-adults {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.traveller-class-overlay-proxima2 .travellersPopup-above-12-yrs {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-48 {
  align-items: flex-start;
  background-color: #f6f7f9;
  box-shadow: 0px 1px 5px #0014461a;
  display: flex;
  height: 33.29px;
  overflow: hidden;
  position: relative;
  width: 339px;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-10 {
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 4.38px 8.76px;
  position: relative;
}
.traveller-class-overlay-proxima2 .travellersPopup-frame-10.proxima:hover{
    background-color: var(--light-sky-bg-hov) !important;
    font-weight: bold !important;
}
.traveller-class-overlay-proxima2 .travellersPopup-frame-5.proxima:hover {
  background-color: var(--light-sky-bg-hov) !important;
  /* font-weight: bold !important; */
}
.traveller-class-overlay-proxima2 .travellersPopup-frame-10.averta:hover{
    background-color: var(--light-pale-bg-hov) !important;
    font-weight: bold !important;
}
.traveller-class-overlay-proxima2 .travellersPopup-frame-5.averta:hover {
  background-color: var(--light-pale-bg-hov) !important;
  /* font-weight: bold !important; */
}

.traveller-class-overlay-proxima2 .travellersPopup-number {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-103.proxima {
  background-color: var(--endeavour);
}
.traveller-class-overlay-proxima2 .travellersPopup-frame-103.averta {
  background-color: var(--optera-galaxy);
}


.traveller-class-overlay-proxima2 .travellersPopup-frame-103 {
  align-items: center;
  align-self: stretch;
  background-color: var(--endeavour);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 4.38px 8.76px;
  position: relative;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-110 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  padding: 0px 6px 3px;
  position: relative;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-58 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-109-1 {
  align-items: flex-end;
  display: inline-flex;
  gap: 5px;
  justify-content: center;
}

.traveller-class-overlay-proxima2 .travellersPopup-children {
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.traveller-class-overlay-proxima2 .travellersPopup-x2-12-yrs {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-4 {
  align-items: flex-start;
  background-color: #f6f7f9d9;
  box-shadow: 0px 1px 5px #0014461a;
  display: flex;
  height: 33.29px;
  position: relative;
  width: 263.67px;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-10-1 {
  padding: 4.35px 8.7px;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-103-1 {
  background-color: var(--endeavour);
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 4.35px 8.7px;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-109-2 {
  align-items: flex-end;
  display: inline-flex;
  gap: 6px;
  justify-content: center;
}

.traveller-class-overlay-proxima2 .travellersPopup-infants {
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.traveller-class-overlay-proxima2 .travellersPopup-below-2-yrs {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-10-2 {
  padding: 5px 10px;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-40 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-104 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  padding: 0px 6px 5px;
  position: relative;
  width: 100%;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-109-3 {
  align-items: center;
  display: inline-flex;
  gap: 25px;
  padding: 0px 15px;
}

.traveller-class-overlay-proxima2 .travellersPopup-choose-travel-class {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 564px;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-109-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 15px;
  width: 100%;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-103-2 {
  background-color: var(--white);
  box-shadow: 0px 5px 4px -3px #1f1f1f47;
  flex: 0 0 auto;
  width: 100%;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-102-1 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  position: relative;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-51.proxima {
  background-color: var(--endeavour);
}
.traveller-class-overlay-proxima2 .travellersPopup-frame-51.averta {
  background-color: var(--optera-galaxy);
}
.traveller-class-overlay-proxima2 .travellersPopup-frame-51 {
  align-items: center;
  background-color: var(--endeavour);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 10px 34px;
  position: relative;
}

.traveller-class-overlay-proxima2 .travellersPopup-place-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-5 {
  align-items: center;
  background-color: var(--white);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 10px 34px;
  position: relative;
}

.traveller-class-overlay-proxima2 .travellersPopup-premium-economy {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.traveller-class-overlay-proxima2 .travellersPopup-business {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.traveller-class-overlay-proxima2 .travellersPopup-first {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-109-5 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 0px 21px;
  width: 100%;
}


.traveller-class-overlay-proxima2 .travellersPopup-frame-20 {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 9px 26px 7px;
  position: relative;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-20.proxima {
  background-color: var(--endeavour);
}
.traveller-class-overlay-proxima2 .travellersPopup-frame-20.averta {
  background-color: var(--optera-galaxy);
}

.traveller-class-overlay-proxima2 .travellersPopup-apply {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-10-3 {
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  position: relative;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-103-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  position: relative;
}

.traveller-class-overlay-proxima2 .travellersPopup-frame-109-6 {
  flex: 0 0 auto;
  position: relative;
}

.traveller-class-overlay-proxima2 .travellersPopup-number-22 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
