.container-const {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: white;
    width: 50%;
    border-radius: 20px;
    margin-left: 23%;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
    color: white;
    text-align: center;
  }
  
  .construction-sign {
    position: relative;
  }
  
  .crane {
    width: 300px;
    height: 100px;
    background: url('../../../Assets/Images/under-const.png') no-repeat center center;
    background-size: contain;
  }