/* screen - fare-summary-popup-detailed-3 */

.fare-summary-popup-detailed {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 12px;
    /* position: relative; */
  }

.fare-summary-popup-detailed.proxima {
    background-color: var(--rhino);
  }
.fare-summary-popup-detailed.averta {
    background-color: var(--dark-galaxy);
    border-color: var(--gold-shadow);
    box-shadow: 0px 1px 5px var(--gold-shadow);
  }
/*  */
  
  .fare-summary-popup-detailed .rectangle-73 {
    height: 169px;
    left: 0;
    position: absolute;
    top: -7px;
    width: 293px;
  }
  
  .fare-summary-popup-detailed .frame-242 {
    align-items: flex-start;
    align-self: stretch;
    border-bottom-style: solid;
    border-bottom-width: 0.5px;
    border-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: space-around;
    padding: 12px 15px;
    position: relative;
    width: 100%;
  }
  
  .fare-summary-popup-detailed .frame-242.proxima {
    background-color: var(--rhino);
  }
  .fare-summary-popup-detailed .frame-242.averta {
    background-color: var(--dark-galaxy);
  }
  
  .fare-summary-popup-detailed .fare-summary {
    flex: 1;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 16px;
    position: relative;
  }
  
  .fare-summary-popup-detailed .frame-244 {
    align-items: baseline;
    /* align-self: stretch; */
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 15px 15px 10px;
    position: relative;
    width: 100%;
  }
  .fare-summary-popup-detailed .frame-244.proxima {
    background-color: var(--rhino);
  }
  .fare-summary-popup-detailed .frame-244.averta {
    background-color: var(--dark-galaxy);
  }
  
  .fare-summary-popup-detailed .base-fare {
    flex: 1;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1.00px;
    position: relative;
  }
  
  .fare-summary-popup-detailed .frame-173 {
    /* align-items: self-start; */
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 4px;
    justify-content: flex-end;
    position: relative;
  }
  
  .fare-summary-popup-detailed .frame-169 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .fare-summary-popup-detailed .text {

    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    /* margin-top: -2.74px; */
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .fare-summary-popup-detailed .frame-243 {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 10px 15px 15px;
    position: relative;
    width: 100%;
  }
  .fare-summary-popup-detailed .frame-243.proxima {
    background-color: var(--rhino);
  }
  .fare-summary-popup-detailed .frame-243.averta {
    background-color: var(--dark-galaxy);
  }
  
  .fare-summary-popup-detailed .taxes {
    color: var(--white);
    flex: 1;
    font-family: var(--font-family-proxima_nova-regular);
    font-size: var(--font-size-16);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1.00px;
    position: relative;
  }
  
  .fare-summary-popup-detailed .frame-241 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--rhino);
    border-color: var(--white);
    border-top-style: solid;
    border-top-width: 0.2px;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 12px 15px 0px;
    position: relative;
    width: 100%;
  }
  .fare-summary-popup-detailed .frame-241.proxima {
    background-color: var(--rhino);
  }
  .fare-summary-popup-detailed .frame-241.averta {
    background-color: var(--dark-galaxy);
  }
  
  .fare-summary-popup-detailed .total {
    flex: 1;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -0.20px;
    position: relative;
  }
  
  .fare-summary-popup-detailed .text-3 {
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -2.74px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .stops-summary-popup{
    background-color: black;
    color: white;
    width: 400px;
    height: 330px;
    z-index: 10000;
    position: absolute;
    top: 100%;
    left: -50%;
  }

