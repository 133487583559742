
  .result-data-section .frame-257 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    min-height: 57.77px;
    gap: 7px;
    position: relative;
    width: 100%;
  }