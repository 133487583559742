
.error-page-flights-section {
    align-items: flex-start;
    align-self: stretch;
    background-color: #e4edf6;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    padding: 20px 0px;
    position: relative;
    width: 100%;
  }
  
  .error-page-frame-99-7 {
    background: linear-gradient(180deg, rgb(170.54, 194.71, 218.88) 3.08%, rgb(170.54, 194.71, 218.88) 11%, rgb(228, 237, 246) 69%, rgb(228, 237, 246) 100%);
    height: 889px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  
  .error-page-frame-98-1 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    padding: 0px 76px;
    position: relative;
    width: 100%;
  }
  
  .error-page-frame-242 {
    align-items: center;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 510px;
    justify-content: center;
    position: relative;
    width: 1260px;
  }
  
  .error-page-frame {
    height: 116px;
    position: relative;
    width: 116px;
  }
  
  .error-page-frame-302 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    position: relative;
  }
  
  
  .error-page-we-are-currently-exp {
    letter-spacing: 0;
    line-height: 20px;
    position: relative;
    text-align: center;
    width: fit-content;
  }
  
  .error-page-frame-302-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    position: relative;
  }
  
  .error-page-frame-259 {
    cursor: pointer;
    align-items: center;
    background-color: var(--white);
    border: 1px solid;
    border-color: var(--endeavour);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: center;
    margin-bottom: -1.00px;
    margin-left: -1.00px;
    margin-top: -1.00px;
    padding: 14px 28px;
    position: relative;
  }
  
  
  .error-page-frame-259-1 {
    cursor: pointer;
    align-items: center;
    background-color: var(--endeavour);
    border: 1px solid;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: center;
    margin-bottom: -2.00px;
    margin-right: -2.00px;
    margin-top: -2.00px;
    padding: 14px 28px;
    position: relative;
  }
  
  


  /* // geenrated   */
  


  .error-page-section {
    align-items: flex-start;
    align-self: stretch;
    background-color: #e4edf6;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    padding: 20px 0px;
    position: relative;
    width: 100%;
}

.error-page-background {
    background: linear-gradient(180deg, rgb(170.54, 194.71, 218.88) 3.08%, rgb(170.54, 194.71, 218.88) 11%, rgb(228, 237, 246) 69%, rgb(228, 237, 246) 100%);
    height: 889px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.error-page-content {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    padding: 0px 76px;
    position: relative;
    width: 100%;
}

.error-page-icon-container {
    align-items: center;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 510px;
    justify-content: center;
    position: relative;
    width: 1260px;
}

.error-page-icon {
    height: 116px;
    position: relative;
    width: 116px;
}

.error-page-message-container {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    position: relative;
}

.error-page-main-message {
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.error-page-description {
    letter-spacing: 0;
    line-height: 20px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.error-page-actions {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    position: relative;
}

.error-page-home-button {
    cursor: pointer;
    align-items: center;
    background-color: var(--white);
    border: 1px solid;
    border-color: var(--endeavour);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: center;
    margin-bottom: -1.00px;
    margin-left: -1.00px;
    margin-top: -1.00px;
    padding: 14px 28px;
    position: relative;
}

.error-page-home-button.proxima {
    background-color: var(--white);
    border-color: var(--endeavour);
}
.error-page-home-button.averta {
    background-color: var(--light-pale-skin-bg);
    border-color: var(--medium-pale-border);
}

.error-page-home-text {
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.error-page-refresh-button {
    cursor: pointer;
    align-items: center;
    border: 1px solid;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: center;
    margin-bottom: -2.00px;
    margin-right: -2.00px;
    margin-top: -2.00px;
    padding: 14px 28px;
    position: relative;
}
.error-page-refresh-button.proxima {
    background-color: var(--endeavour);
}
.error-page-refresh-button.averta {
    background-color: var(--optera-galaxy);
}

.error-page-refresh-text {
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-left: -1.00px;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

