.travllerAccordian-wrapper {
  border-bottom: none;
}
.travllerAccordian-wrapper.proxima {
  border: 1px solid var(--spindle);
  border-bottom: none;
}
.travllerAccordian-wrapper.averta {
  border: 1px solid var(--light-pale-border);
  border-bottom: none;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

.travllerAccordian-wrapper.shake {
  animation: shake 0.5s ease-in-out infinite;
}


.travllerAccordian-bar {
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 15px;
}

.travllerAccordian-bar.bn.proxima {
  border-bottom: 1px solid var(--spindle);
}
.travllerAccordian-bar.bn.averta {
  border-bottom: 1px solid var(--light-pale-border);
}

.travllerAccordian-bar .error-container {
  display: flex;
  gap: 20px;
  align-items: center;
}
.travllerAccordian-bar .info-container {
  display: flex;
  gap: 10px;
}

.travllerAccordian-bar .traveller-info {
  display: flex;
  align-items: center;
  gap: 3px;
}

.travllerAccordian-bar .traveller-info .light-text {
  padding-top: 2px;
}

.travllerAccordian-bar .expand-icon {
  cursor: pointer;
}

.expand-icon-img {
  transition: transform 0.3s ease;
}

.expand-icon-img.open {
  transform: rotate(180deg);
}

.expandable-content {
  display: flex;
  gap: 25px;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  opacity: 0;
}

.expandable-content.open {
  padding: 25px 15px;
  max-height: max-content;
  opacity: 1;
}
.expandable-content .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
}

.expandable-content .nameSection,
.expandable-content .name-dataSection,
.expandable-content .passport-dataSection {
  display: flex;
  gap: 15px;
}

.rotate-icon {
  transition: transform 0.3s ease-in-out;
}

.rotate-icon.open {
  transform: rotate(180deg);
}

.wheelchair-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}
.wheelchair-check {
  cursor: pointer;
}

.frequent-flyer-wrapper{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.addmoretraveller{
  cursor: pointer;
  display: flex;
  justify-content: end;
}