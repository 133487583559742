.main-content-popup {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    gap: 15px;
    width: 100%;
    position: relative;
    height: 76%;
    /* margin-bottom: 20px; */
  }

  .main-content-popup .frame-243-3 {
    background-color: var(--white);
    display: inline-flex;
    flex-direction: column;
    width: 68.7%;
    height: 100%;
    padding-bottom: 20px;
    overflow: auto;
  }
  .main-content-popup .frame-243-3.proxima {
    box-shadow: 0px 2px 5px 1px #0057b733;
  }
  .main-content-popup .frame-243-3.averta {
    box-shadow: 0px 0px 5px 1px var(--gold-shadow);
  }
  
  .main-content-popup .frame-243-6 {
    align-items: flex-start;
    flex: 0 0 auto;
    position: relative;
  }

  .main-content-popup .results {
    align-items: flex-start;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    gap: 5px;
    padding: 20px;
    position: relative;
    width: 98.8%;
    overflow-y: auto;
  }


  
/* Custom scrollbar styles for WebKit-based browsers */
.main-content-popup .results::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
  cursor: grab;
  height: 100px !important;
}

.main-content-popup .results.proxima::-webkit-scrollbar-track {
  height: 100px;
  background: var(--sky-light); /* Background of the scrollbar track */
  margin-top: 16px; /* Adjust to match button height */
  margin-bottom: 16px; /* Adjust to match button height */
}

.main-content-popup .results.averta::-webkit-scrollbar-track {
  height: 100px;
  background: var(--light-pale-bg); /* Background of the scrollbar track */
  margin-top: 16px; /* Adjust to match button height */
  margin-bottom: 16px; /* Adjust to match button height */
}

.main-content-popup .results.proxima::-webkit-scrollbar-thumb {
  background: var(--deep-sapphire); /* Background of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}
.main-content-popup .results.averta::-webkit-scrollbar-thumb {
  background: var(--magic-potion); /* Background of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

.main-content-popup .results::-webkit-scrollbar-button {
  display: none;
}

.up-button {
  top: 10px;
  align-self: end;
  margin-right: 2px;
  position: absolute;
  z-index: 10;
  border: none;
  background: white;
  height: 25px;
}

.down-button {
  bottom: 10px;
  align-self: end;
  position: absolute;
  margin-right: 2px;
  z-index: 10;
  border: none;
  background: white;
  height: 25px;
  transform: rotate(180deg);
}
.up-button-2 {
  top: -10px;
  align-self: end;
  margin-right: -1px;
  position: absolute;
  z-index: 10;
  background: none;
  border: none;
}

.down-button-2 {
  bottom: -10px;
  align-self: end;
  position: absolute;
  margin-right: -1px;
  z-index: 10;
  background: none;
  border: none;
  transform: rotate(180deg)
}

  
.main-content-popup .frame-221 {
    align-items: flex-start;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    /* gap: 20px; */
    width: 30%;
    height: 100%;
    padding: 0px 0px 3px;
    position: relative;
  }

  .main-content-popup .frame-221.proxima {
    box-shadow: 0px 2px 5px 1px #0057b733;
  }
  .main-content-popup .frame-221.averta {
    box-shadow: 0px 0px 5px 1px var(--gold-shadow);
  }
  


  .main-content-popup .frame-241-3 {
    align-items: flex-start;
    background-color: var(--white);
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding-top: 12px;
    position: relative;
  }

  
.main-content-popup .frame-2-5 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .main-content-popup .frame-2-10 {
    flex: 0 0 auto;
    position: relative;
  }


  
.main-content-popup .frame-241-4 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 0px 20px 0px 15px;
    position: relative;
  }
  

  .main-content-popup .frame-242-11 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
  }

  .main-content-popup .more-fare-options {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .main-content-popup .available-for-your-trip {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .main-content-popup .frame-242-12{
   width: 100%;
  }
  .main-content-popup .frame-242-12::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  
  .main-content-popup .frame-241-5 {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: center;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }

  .main-content-popup .frame-241-6 {
    width: 96%;
    align-items: center;
    display: inline-flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 20px;
    overflow-y: auto;
    padding: 3px 0px;
    position: relative;
    max-height: 440px;
  }

   
/* Custom scrollbar styles for WebKit-based browsers */
.main-content-popup .frame-241-6::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
  cursor: grab;
  height: 100px !important;
}

.main-content-popup .frame-241-6.proxima::-webkit-scrollbar-track {
  height: 100px;
  background: var(--sky-light); /* Background of the scrollbar track */
  margin-top: 16px; /* Adjust to match button height */
  margin-bottom: 16px; /* Adjust to match button height */
}
.main-content-popup .frame-241-6.averta::-webkit-scrollbar-track {
  height: 100px;
  background: var(--light-pale-bg); /* Background of the scrollbar track */
  margin-top: 16px; /* Adjust to match button height */
  margin-bottom: 16px; /* Adjust to match button height */
}

.main-content-popup .frame-241-6.proxima::-webkit-scrollbar-thumb {
  background: var(--deep-sapphire); /* Background of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}
.main-content-popup .frame-241-6.averta::-webkit-scrollbar-thumb {
  background: var(--magic-potion); /* Background of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

.main-content-popup .frame-241-6::-webkit-scrollbar-button {
  display: none;
}
