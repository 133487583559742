.main-footer {
    align-items: center;
    align-self: stretch;
    background-color: var(--mercury);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    padding: 50px 85px 20px;
    position: relative;
    width: 100%;
  }

  
.main-footer .footer-columns {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 40px;
    justify-content: center;
    position: relative;
  }

  .main-footer .frame-66 {
    height: 261px;
    position: relative;
    width: 312px;
  }
  
  
.main-footer .frame-65 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }

  .main-footer .get-latest-updates-offers {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    width: 215px;
  }

  .main-footer .group-70 {
    height: 28px;
    margin-right: -2.00px;
    position: relative;
    width: 217px;
  }

  .main-footer .overlap-group-2 {
    align-items: flex-start;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--eerie-black);
    display: flex;
    height: 28px;
    min-width: 215px;
    padding: 2px 0;
  }

  .main-footer .enter-email-address {
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin-left: -1px;
    white-space: nowrap;
  }

  .main-footer .frame-71 {
    align-items: flex-start;
    background-color: var(--deep-sapphire);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 5px 16px 7px;
    position: relative;
  }

  .main-footer .subscribe {
    letter-spacing: 0;
    line-height: 18px;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .main-footer .group-79 {
    height: 36px;
    position: relative;
    width: 174px;
  }

  
.main-footer .group-container {
    align-items: flex-start;
    display: flex;
    gap: 10px;
    min-width: 174px;
  }
  
  .main-footer .group-76 {
    align-items: flex-start;
    background-color: var(--white);
    border-radius: 18px;
    display: flex;
    height: 36px;
    min-width: 36px;
    padding: 9px 13px;
  }
  
  .main-footer .group-74 {
    align-items: flex-end;
    background-color: var(--white);
    border-radius: 18px;
    display: flex;
    height: 36px;
    min-width: 36px;
    padding: 8.0px 8px;
  }
  
  .main-footer .group-77 {
    align-items: flex-end;
    background-color: var(--white);
    border-radius: 18px;
    display: flex;
    height: 36px;
    justify-content: flex-end;
    min-width: 36px;
    padding: 9.7px 7.9px;
  }
  
  .main-footer .group-78 {
    align-items: center;
    background-color: var(--white);
    border-radius: 18px;
    display: flex;
    height: 36px;
    justify-content: flex-end;
    min-width: 36px;
    padding: 0 6px;
  }


  .main-footer .group-72 {
    height: 31.52px;
    position: relative;
    width: 210px;
  }
  
  .main-footer .img2 {
    height: 32px;
    left: 110px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100px;
  }
  
  .main-footer .img1 {
    height: 32px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100px;
  }

  .main-footer .gle {
    height: 22px;
    width: 22px;
  }

  
.main-footer .frame-67 {
    align-self: stretch;
    position: relative;
    width: 282px;
  }
  
  .main-footer .group-65 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 225px;
    width: 170px;
  }

  .main-footer .our-services {
    letter-spacing: 0;
    line-height: normal;
    margin-left: 5px;
    min-height: 27px;
  }

  .main-footer .group-64 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 182px;
    width: 168px;
  }
  
  .main-footer .group-61 {
    align-items: flex-start;
    display: flex;
    gap: 5px;
    min-width: 170px;
  }
  
  .main-footer .group-62 {
    align-items: flex-start;
    display: flex;
    gap: 5px;
    min-width: 146px;
  }

  .main-footer .international {
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 1px;
    white-space: nowrap;
  }

  
.main-footer .domestic-flights {
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 1px;
    white-space: nowrap;
  }

  .main-footer .group-63 {
    align-items: flex-start;
    display: flex;
    gap: 5px;
    min-width: 131px;
  }

  .main-footer .hotels-in-india {
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 1px;
    white-space: nowrap;
  }

  .main-footer .group-64-1 {
    align-items: flex-start;
    display: flex;
    gap: 5px;
    min-width: 169px;
  }
  
  .main-footer .group-65-1 {
    align-items: flex-start;
    display: flex;
    gap: 5px;
    min-width: 129px;
  }

  
.main-footer .tour-packages {
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 1px;
    white-space: nowrap;
  }

  
.main-footer .group-66 {
    align-items: flex-start;
    display: flex;
    gap: 5px;
    min-width: 126px;
  }
  
  
.main-footer .special-offers {
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 1px;
    white-space: nowrap;
  }


  .main-footer .frame-68 {
    align-self: stretch;
    position: relative;
    width: 265px;
  }
  
  .main-footer .group-65-2 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 161px;
    width: 123px;
  }
  
  .main-footer .quick-links {
    letter-spacing: 0;
    line-height: normal;
    margin-right: 9.0px;
    min-height: 27px;
    min-width: 104px;
  }
  
  .main-footer .group-64-2 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-right: 2.0px;
    min-height: 118px;
    width: 121px;
  }
  
  .main-footer .group-61-1 {
    align-items: flex-start;
    min-width: 72px;
  }

  .main-footer .group-61-3 {
    display: flex;
    gap: 5px;
  }
  
  .main-footer .group-62-3 {
    display: flex;
    gap: 5px;
  }
  
  .main-footer .group-63-3 {
    display: flex;
    gap: 5px;
  }

  .main-footer .group-62-1 {
    align-items: flex-start;
    min-width: 73px;
  }
  
  .main-footer .about {
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 1px;
    white-space: nowrap;
  }
  
  .main-footer .group-63-1 {
    align-items: flex-start;
    min-width: 123px;
  }
  
  .main-footer .x247-support {
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 1px;
    white-space: nowrap;
  }

  .main-footer .group-64-3 {
    align-items: flex-start;
    display: flex;
    gap: 5px;
    min-width: 84px;
  }
  
  .main-footer .place-8 {
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 1px;
    white-space: nowrap;
  }
  
  .main-footer .frame-69 {
    height: 263px;
    position: relative;
    width: 286px;
  }
  
  .main-footer .group-65-3 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 24px;
    left: 4px;
    min-height: 83px;
    position: absolute;
    top: 175px;
    width: 239px;
  }
  
  .main-footer .we-accept {
    letter-spacing: 0;
    line-height: normal;
    min-height: 27px;
  }
  
  .main-footer .group-80 {
    align-items: flex-start;
    display: flex;
    gap: 10px;
    margin-left: 3px;
    min-width: 234px;
  }

  .main-footer .card {
    height: 32px;
    object-fit: cover;
    width: 51px;
  }
  
  .main-footer .group-66-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 11px;
    left: 0;
    min-height: 140px;
    position: absolute;
    top: 0;
    width: 176px;
  }
  
  .main-footer .get-to-know-us {
    letter-spacing: 0;
    line-height: normal;
    margin-left: 5.08px;
    min-height: 27px;
  }
  
  .main-footer .group-64-4 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 102px;
    width: 174px;
  }

  

.main-footer .group-61-2 {
    align-items: center;
    min-width: 176px;
  }
  
  .main-footer .login {
    height: 15px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 1.63px;
    white-space: nowrap;
    width: 146px;
  }
  
  .main-footer .group-62-2 {
    align-items: center;
    min-width: 154px;
  }
  
  .main-footer .enquiry {
    height: 15px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 1.63px;
    white-space: nowrap;
    width: 124px;
  }
  
  .main-footer .group-63-2 {
    align-items: center;
    min-width: 135px;
  }
  
  .main-footer .group-64-5 {
    align-items: center;
    display: flex;
    gap: 5px;
    min-width: 175px;
  }
  
  .main-footer .blog {
    height: 15px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 1.63px;
    white-space: nowrap;
    width: 145px;
  }

  
.main-footer .frame-74 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--mercury);
    border-color: var(--deep-sapphire);
    border-top-style: solid;
    border-top-width: 1px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0px 2px;
    position: relative;
    width: 100%;
  }
  
  .main-footer .frame-73 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .main-footer .copyright-2020-2024 {
    letter-spacing: 0;
    line-height: 18px;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .main-footer .designed-by-nspl {
    letter-spacing: 0;
    line-height: 18px;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }