.topbar-navbar-navigation {

  align-items: flex-start;
  align-self: stretch;
  box-shadow: 0px 4px 4px #0067b81f;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.topbar-navbar-navigation .frame-98 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 7px 85px;
  position: relative;
  width: 100%;
}


.topbar-navbar-navigation .frame-99 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.topbar-navbar-navigation .frame-99-1 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 54px;
  position: relative;
}

.topbar-navbar-navigation .frame-100 {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.topbar-navbar-navigation .group-168 {
  height: 33.9px;
  position: relative;
  width: 38px;
}

.topbar-navbar-navigation .overlap-group {
  height: 33px;
  position: relative;
}

.topbar-navbar-navigation .group-149 {
  background-image: url(../../../Assets/Images/exclude-3.svg);
  background-size: 100% 100%;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 38px;
}

.topbar-navbar-navigation .stfares {
  font-family: var(--font-family-proxima_nova-regular) !important;
  font-size: 22px;
  font-weight: 600;
  line-height: 26.8px;
  letter-spacing: 0.02em;
  text-align: center;


  color: var(--magic-potion);
  /* font-family: var(--font-family-proxima_nova-semibold) !important;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.22px; */
  /* line-height: normal;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content; */
}

.topbar-navbar-navigation .frame-9 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 25px;
  position: relative;
}

.topbar-navbar-navigation .frame-19 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  padding: 0px 4px;
  position: relative;
}

.topbar-navbar-navigation .frame-99-4 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  justify-content: center;
  position: relative;
}


.topbar-navbar-navigation .group-108 {
  /* box-shadow: -3.26px 5.43px 2.17px #00000040; */
  height: 22.08px;
  position: relative;
  width: 32.31px;
}

.topbar-navbar-navigation .overlap-group4 {
  height: 22px;
  position: relative;
  width: 32px;
}

.topbar-navbar-navigation .group-107 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1px;
  left: 0;
  min-height: 20px;
  padding: 0.1px 0;
  position: absolute;
  top: 0;
  width: 32px;
}

.topbar-navbar-navigation .overlap-group3 {
  height: 14px;
  margin-right: 0.27px;
  position: relative;
  width: 32px;
}

.topbar-navbar-navigation .group-105 {
  align-items: flex-start;
  display: flex;
  height: 4px;
  left: 7px;
  min-width: 4px;
  position: absolute;
  top: 3px;
  transform: rotate(5.49deg);
}

.topbar-navbar-navigation .overlap-group2 {
  height: 5px;
  margin-top: -0.5px;
  position: relative;
  width: 5px;
}

.topbar-navbar-navigation .line-container {
  height: 3px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 5px;
}

.topbar-navbar-navigation .group-104 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-right: 2.19px;
  min-height: 4px;
  transform: rotate(21.59deg);
  width: 4px;
}

.topbar-navbar-navigation .line-6-1 {
  height: 1px;
  margin-left: 0.04px;
  transform: rotate(-21.59deg);
  width: 2px;
}

.topbar-navbar-navigation .line-container-1 {
  height: 3px;
  margin-left: -0.57px;
  position: relative;
  width: 5px;
}

.topbar-navbar-navigation .flights {
  letter-spacing: 0.16px;
  line-height: 21px;
  position: relative;
  width: 65px;
}

.topbar-navbar-navigation .frame-27 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  position: relative;
}

.topbar-navbar-navigation .hotels {
  letter-spacing: 0.16px;
  line-height: 22px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.topbar-navbar-navigation .frame-23 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  justify-content: flex-end;
  position: relative;
}

.topbar-navbar-navigation .packages {
  letter-spacing: 0;
  line-height: 26px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.topbar-navbar-navigation .frame-100-1 {
  align-items: center;
  display: inline-flex;
  gap: 20px;
  justify-content: center;
}

.topbar-navbar-navigation .frame-100-4 {
  flex: 0 0 auto;
  position: relative;
}

.topbar-navbar-navigation .frame-9-2 {
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--endeavour);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7px;
  padding: 10px 20px;
  position: relative;
}

.topbar-navbar-navigation .login-signup {
  color: var(--endeavour);
  font-family: var(--font-family-proxima_nova-regular);
  font-size: var(--font-size-16);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.topbar-navbar-navigation .frame-8-1 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7px;
  position: relative;
}

.topbar-navbar-navigation .frame-266 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}


.topbar-navbar-navigation .en-inr {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}


.vector-2 {
  height: 12px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 32px;
}

.vector-3 {
  height: 7px;
  left: 8px;
  position: absolute;
  top: 0;
  width: 10px;
}


.line-6 {
  height: 1px;
  left: 1px;
  position: absolute;
  top: 0;
  transform: rotate(-5.49deg);
  width: 2px;
}


.line-7 {
  height: 1px;
  left: 3px;
  position: absolute;
  top: 2px;
  transform: rotate(-5.49deg);
  width: 2px;
}

.line-8 {
  height: 2px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-5.49deg);
  width: 4px;
}

.vector-4 {
  height: 3px;
  left: 21px;
  position: absolute;
  top: 2px;
  width: 8px;
}


.line-7-1 {
  height: 1px;
  left: 3px;
  position: absolute;
  top: 2px;
  transform: rotate(-21.59deg);
  width: 2px;
}

.line-8-1 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 1px;
  transform: rotate(-21.59deg);
  width: 4px;
}

.vector-8 {
  height: 12px;
  left: 16px;
  position: absolute;
  top: 10px;
  width: 6px;
}

.frame-128 {
  flex: 0 0 auto;
  margin-bottom: -1.27px;
  margin-top: -0.44px;
  position: relative;
}