.result-bar-1 {
    align-items: center;
    align-self: stretch;
    background-color: var(--white);
    border: 1px solid;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 1px;
    position: relative;
    min-height: 125.5px;
    width: 100%;
  }
.result-bar-1.proxima {
    border-color: var(--alto);
  }
.result-bar-1.averta {
    border-color: var(--light-pale-border );
  }

  .result-bar-1 .frame-201-3 {
    align-items: flex-end;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
  }
  
  .result-bar-1 .frame-17-2 {
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    padding: 32px 20px 22.5px;
    width: 100%;
  }
  
  .result-bar-1 .frame-104 {
    align-items: flex-start;
    /* display: flex; */
    flex-direction: column;
    gap: 7px;
    justify-content: center;
    position: relative;
    width: 121px;
  }

  .result-bar-1 .frame-17-3 {
    /* align-items: center; */
    flex: 0 0 auto;
    position: relative;
  }

  .result-bar-1 .frame-104-5 {
    flex-direction: column;
    position: relative;
  }

  
  .result-bar-1 .frame-104-1 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 7px;
    justify-content: center;
    width: 100%;
  }
  
  .result-bar-1 .frame-103 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
    width: 100%;
  }

  .result-bar-1 .frame-173 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    position: relative;
    width: 25px;
  }
  
  .result-bar-1 .frame-223 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 1px;
    position: relative;
    width: 100%;
  }

  .result-bar-1 .frame-172 {
    align-items: flex-start;
    align-self: stretch;
    background-position: 50% 50%;
    background-size: cover;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 3.31px;
    position: relative;
  }
  
  .result-bar-1 .frame-173-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 3.07px;
    height: 12px;
    position: relative;
  }

  .result-bar-1 .unnamed-2 {
    height: 12px;
    width: 12px;
  }

  
.result-bar-1 .unnamed-5 {
    object-fit: cover;
    position: relative;
  }

  .result-bar-1 .frame-174 {
    align-items: flex-start;
    align-self: stretch;
    background-position: 50% 50%;
    background-size: cover;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 3.13px;
    justify-content: flex-end;
    position: relative;
  }
  
.result-bar-1 .frame-224 {
    align-items: flex-start;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 3.13px;
    justify-content: flex-end;
    position: relative;
  }

  .result-bar-1 .png-transparent-luft-4 {
    height: 12px;
    object-fit: cover;
    position: relative;
    width: 12px;
  }

  .result-bar-1 .frame-168-2 {
    align-items: flex-start;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 3px;
    justify-content: flex-end;
  }
  
  .result-bar-1 .frame-173-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 2px;
    position: relative;
    width: 100%;
  }

  .result-bar-1 .frame-168-5 {
    display: flex;
    position: relative;
  }
  
  
.result-bar-1 .frame-173-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 2px;
    position: relative;
    width: 100%;
  }

  
  .result-bar-1 .indigo-spi {
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .result-bar-1 .in-123-sj-123 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    max-width: 100px;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }

  .result-bar-1 .frame-214 {
    display: inline-flex;
    flex: 0 0 auto;
    gap: 7px;
    height: 32px;
    justify-content: center;
    position: relative;
  }

  .result-bar-1 .frame-214 .proxima{
    align-items: flex-start;
  }
  
  .result-bar-1 .frame-214 .averta{

  }
  
  .result-bar-1 .frame-202 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: -1.00px;
    position: relative;
    width: 474px;
  }

  .result-bar-1 .frame-170-1 {
    /* gap: 9px; */
    justify-content: center;
    width: 106px;
    /* gap: 3px; */
  }

  .result-bar-1 .frame-170-4 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .result-bar-1 .text-561 {
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  
.result-bar-1 .text-4 {
    letter-spacing: 0;
    line-height: normal;
  }

  .result-bar-1 .frame-9 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    position: relative;
  }
  
  .result-bar-1 .del {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .result-bar-1 .place {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .result-bar-1 .frame-1-5 {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    position: relative;
  }
  
  .result-bar-1 .frame-10 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
  }

  .result-bar-1 .frame-8 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 7px;
    justify-content: center;
    position: relative;
  }

  .result-bar-1 .line {
    height: 1px;
    object-fit: cover;
    position: relative;
    width: 19px;
  }

  .result-bar-1 .line.proxima {
    background-color: var(--pumice);
  }
  .result-bar-1 .line.averta {
    background-color: var(--dark-gold);
  }

  .result-bar-1 .frame-202-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    justify-content: center;
    padding: 0px 0px 0px 1.5px;
    position: relative;
  }

  .result-bar-1 .group-206 {
    margin-top: -7px;
    height: 10.6px;
    position: relative;
    width: 10.6px;
  }
  
  .result-bar-1 .overlap-group {
    align-items: flex-start;
    border: 0.8px solid;
    border-color: var(--eerie-black);
    border-radius: 6.1px;
    display: flex;
    height: 12px;
    justify-content: flex-end;
    left: -1px;
    min-width: 12px;
    padding: 2.5px 4.9px;
    position: relative;
    top: -1px;
  }

  
  .result-bar-1 .vector-50 {
    height: 5px;
    width: 2px;
  }
  .result-bar-1 .x28h-40m {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.20px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }  

  .result-bar-1 .frame-11 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: center;
    position: relative;
  }

  .result-bar-1 .frame-175-2 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    position: relative;
  }

  .result-bar-1 .address-2 {
    margin-top: -1.00px;
    text-align: center;
  }

  .result-bar-1 .address-17 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .result-bar-1 .frame-214-1 {
    align-items: center;
    align-self: stretch;
    gap: 10px;
    justify-content: center;
    margin-right: -0.20px;
    padding: 1px 0px 0px;
  }
  
  .result-bar-1 .frame-203 {
    border-color: var(--eerie-black);
    border-left-style: solid;
    border-left-width: 0.8px;
    height: 10px;
    position: relative;
    width: 1px;
  }

  .result-bar-1 .frame-202-2 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 1px;
    position: relative;
  }

  .result-bar-1 .frame-9 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    position: relative;
  }

  .result-bar-1 .tashkent-2 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    white-space: pre;
    overflow: hidden;
    max-width: 80px;
    text-overflow: ellipsis;
    cursor: pointer;
  }


  /* index.css */


  /* .result-bar-1 .tooltip-container {
  position: relative;
  display: inline-block;
} */

.result-bar-1  .tooltip-content {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 0%;
  margin-left: -100px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.result-bar-1 .tooltip-container:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}




  .result-bar-1 .frame-177 {
    flex: 0 0 auto;
    margin-right: -1.00px;
    position: relative;
  }

  
.result-bar-1 .frame-169 {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    /* gap: 3px; */
    justify-content: center;
    position: relative;
    width: 106px;
  }
  
  .result-bar-1 .frame-12 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    justify-content: flex-end;
    position: relative;
  }
  
  .result-bar-1 .text-562 {
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }

  
  .result-bar-1 .atq {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .result-bar-1 .place-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
  }


  .result-bar-1 .frame-202-3 {
    align-items: flex-start;
    border-radius: 14px;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    position: relative;
  }
  
  .result-bar-1 .x1-day {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }

  
.result-bar-1 .frame-104-2 {
    align-items: flex-end;
    margin-top: -4px;
    display: flex;
    gap: 5px;
    width: 218px;
  }
  
  .result-bar-1 .frame-104-5 {
    flex-direction: column;
    position: relative;
  }

  
  .result-bar-1 .frame-202-4 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-end;
    margin-left: -1.80px;
    position: relative;
  }
  
  .result-bar-1 .frame-103-1 {
    align-items: center;
    background-color: var(--white);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 15px;
    justify-content: center;
    position: relative;
  }
  
  .result-bar-1 .frame-172-1 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 9px;
    justify-content: flex-end;
    position: relative;
  }
  
  .result-bar-1 .frame-169-1 {
    align-items: flex-end;
    display: inline-flex;
    flex-direction: column;
    /* gap: 8px; */
    justify-content: flex-end;
  }
  
  .result-bar-1 .frame-1-6 {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
  }

  .result-bar-1 .frame-169-20 {
    flex: 0 0 auto;
    position: relative;
    /* gap: 3px; */
  }

  .result-bar-1 .text {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -2.74px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .result-bar-1 .per-adult-16 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .result-bar-1 .frame-103-2 {
    cursor: pointer;
    align-items: center;
    align-self: stretch;
    border: 1px solid;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: center;
height: 32px;
    padding: 10.5px 33px;
    position: relative;
  }
  .result-bar-1 .frame-103-2.proxima {
    background-color: var(--white);
    color: var(--endeavour);
    border-color: var(--endeavour);
  }
  .result-bar-1 .frame-103-2.averta {
    background-color: var(--white);
    color: var(--burning-gold);
    border-color: var(--burning-gold);
  }

  .result-bar-1 .frame-103-2:hover {
    cursor: pointer;
    align-items: center;
    align-self: stretch;
    border: 1px solid;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: center;
    padding: 10.5px 33px;
    position: relative;
  }
  .result-bar-1 .frame-103-2.proxima:hover {
    background-color: var(--endeavour) !important;
    color: var(--white) !important;
    border-color: var(--endeavour);
  }
  .result-bar-1 .frame-103-2.averta:hover {
    background-color: var(--burning-gold) !important;
    color: var(--white) !important;
    border-color: var(--burning-gold);
  }

  
  .result-bar-1 .book {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  
.result-bar-1 .frame-171 {
    align-items: center;
    min-height: 36px;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 20px;
    justify-content: center;
    padding: 10px 20px 15px;
    position: relative;
    width: 100%;
  }
.result-bar-1 .frame-171-5 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 20px;
    justify-content: space-between;
    padding: 10px 20px 15px;
    position: relative;
    width: 100%;
  }

  
  .result-bar-1 .partially-refundable {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

.result-bar-1 .frame-203-1 {
    cursor: pointer;
    align-items: flex-end;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: flex-end;
    padding: 0px 0px 2px;
    position: relative;
  }

  .result-bar-1 .flight-details {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }

  .result-bar-1 .four-img-container {
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
  }
  
  .result-bar-1 .four-img-container.frame-174-7 {
    align-items: center;
    /* align-self: stretch; */
    display: flex;
    position: relative;
  }
  
  .result-bar-1 .four-img-container .frame-223-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 1px;
    position: relative;
    width: 100%;
  }
  
  .result-bar-1 .four-img-container .vistara_-logo-1-1 {
    height: 12px;
    margin-bottom: -1px;
    position: relative;
    width: 12px;
  }
  
  .result-bar-1 .four-img-container .frame-224-4 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 1px;
    justify-content: flex-end;
    position: relative;
    width: 100%;
  }
  
  .result-bar-1 .four-img-container .frame-173-4 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 2.69px;
    height: 10.5px;
    position: relative;
  }
  
  .result-bar-1 .four-img-container .unnamed {
    height: 10.5px;
    object-fit: cover;
    position: relative;
    width: 10.5px;
  }
  
  .result-bar-1 .four-img-container .frame-224 {
    align-items: flex-start;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 3.13px;
    justify-content: flex-end;
    position: relative;
  }
  
  .result-bar-1 .four-img-container .png-transparent-luft-5 {
    height: 12px;
    margin-top: -1px;
    width: 12px;
  }
  
  .result-bar-1 .four-img-container .png-transparent-luft-6 {
    object-fit: cover;
    position: relative;
  }

  .result-bar-1 .two-img-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 30px;
    position: relative;
    width: 30px;
  }
  .result-bar-1 .two-img-container .frame-233-5 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    position: relative;
    width: 100%;
  }
  .result-bar-1 .two-img-container .turkish-airlines-symbol-1-1 {
    height: 14.12px;
    position: relative;
    width: 15px;
  }
  
  .result-bar-1 .two-img-container .frame-234-1 {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    width: 100%;
  }

  .result-bar-1 .two-img-container .png-transparent-luft-4 {
    height: 15px;
    width: 15px;
  }

  .result-bar-1 .two-img-container .png-transparent-luft-6 {
    object-fit: cover;
    position: relative;
  }
  
  
.result-bar-1 .single-img-container.png-transparent-luft-5 {
    height: 24px;
    margin-top: -1px;
    width: 24px;
  }
  
  .result-bar-1 .single-img-container.png-transparent-luft-6 {
    object-fit: cover;
    position: relative;
  }

  
.result-bar-1 .three-img-container {
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
  }
  
  .result-bar-1 .three-img-container.frame-174-7 {
    align-items: center;
    align-self: stretch;
    min-width: 20px;
    display: flex;
    position: relative;
  }
  
  .result-bar-1 .three-img-container .frame-223-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 1px;
    position: relative;
    width: 100%;
  }
  
  .result-bar-1 .three-img-container .vistara_-logo-1-1 {
    height: 12px;
    margin-bottom: -1px;
    position: relative;
    width: 12px;
  }
  
  .result-bar-1 .three-img-container .frame-224-4 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 1px;
    justify-content: flex-end;
    position: relative;
    width: 100%;
  }
  
  .result-bar-1 .three-img-container .frame-173-4 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 2.69px;
    height: 10.5px;
    position: relative;
  }
  
  .result-bar-1 .three-img-container .unnamed {
    height: 10.5px;
    object-fit: cover;
    position: relative;
    width: 10.5px;
  }
  
  .result-bar-1 .three-img-container .frame-224 {
    align-items: flex-start;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 3.13px;
    justify-content: flex-end;
    position: relative;
  }
  
  .result-bar-1 .three-img-container .png-transparent-luft-5 {
    height: 12px;
    margin-top: -1px;
    width: 12px;
  }
  
  .result-bar-1 .three-img-container .png-transparent-luft-6 {
    object-fit: cover;
    position: relative;
  }

  .coupon-container {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: flex-end;
    padding: 2.75px 20px;
    position: relative;
  }

  .frame-171-5 .coupon-container {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: flex-end;
    padding-top: 2.75px;
    padding-bottom: 2.75px;
    padding-left: 10px;
    padding-right: 0px;
    position: relative;
  }

  .coupon-container .frame-230 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: flex-end;
    padding: 0px 11px;
    position: relative;
  }
  .coupon-container .frame-230.proxima {
    background-color: var(--mystic);
  }
  .coupon-container .frame-230.averta {
    background-color: var(--light-pale-bg);
  }
  
  .coupon-container .frame-203-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    /* padding: 4px 0px 7px; */
    position: relative;
  }  

  .coupon-container .frame-211 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    position: relative;
  }


  .coupon-container .frame-9 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    position: relative;
  }

  
.coupon-container .text-3 {
    -webkit-text-stroke: 1px var(--black);
    margin-left: -1.00px;
    margin-top: -2.00px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .coupon-container .frame-13 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    position: relative;
  }
  
  .coupon-container .frame {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }
  
  
  .coupon-container .deal-text {
margin-top: 2px;
margin-bottom: 2px;
  }
  

  .result-bar-1 .tags-container {
    min-height: 33px !important;
    background-color: var(--gallery);
    flex: 0 0 auto;
    display: flex;
    gap: 15px;
    padding-left: 20px;
    width: 100%;
    justify-content: space-between;
  }

  .result-bar-1 .tags-container.proxima {

    border-color: var(--pumice);
    border-top-style: solid;
    border-top-width: 0.5px;
  }

  .result-bar-1 .tags-container.averta {

    border-top: none;
  }

  
  .result-bar-1 .tags-container .frame-174-7 {
    align-items: center;
    align-self: stretch;
    display: flex;
    position: relative;
  }

  .result-bar-1 .tags-container .frame-223-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    padding: 6px 0px 6px 0px;
    position: relative;
  }

  .result-bar-1 .tags-container .frame-228 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }
 .result-bar-1  .tags-container .st-4 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    width: fit-content;
  }

  .result-bar-1 .tags-container .frame-228-1 {
    /* align-self: stretch; */
    flex: 0 0 auto;
    position: relative;
  }

  
  .result-bar-1 .tags-container .frame-207-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }


  .result-bar-1 .tags-container .frame-12 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    justify-content: flex-end;
    padding: 6px 0px 6px 0px;
    position: relative;
  }

  .result-bar-1 .tags-container .frame-208 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    padding: 0px 0px 1px;
    position: relative;
  }

  .result-bar-1 .tags-container .line-straight {
    height: 15px;
    width: 3.5px;
    color: var(--pumice);
  }

  .result-bar-1 .tags-container .frame-203-5 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
  }
