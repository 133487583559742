.review-page-main-section {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
}
.review-page-main-section.proxima {
  background-image: linear-gradient(to bottom, #aac2da, #b8cde1, #c7d7e8, #d5e2ef, #e4edf6 200px);
}
.review-page-main-section.averta {
    background-color: var(--light-pale-bg);
}

.top-bar-section {
    width: 100%;
    display: flex;
    justify-content: center;
    background: transparent;
}

.main-bar-section {
    width: 100%;
    display: flex;
    justify-content: center;
}

.main-bar-section .detail-section {
  width: 1342px;
  display: flex;
  gap: 20px;
  
}

.main-bar-section .main-data-section {
  background-color: var(--white);
  /* width: 74%; */
  padding: 15px 15px;
  gap: 20px;
}
.main-bar-section .fare-summary-section {
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 25%;
}

.main-bar-section .flight-details-1{
  padding-bottom: 15px;
}
.main-bar-section .flight-details-1-1{
  margin-top: 20px;
}


.mini-popup-overlay-Review {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.mini-popup-overlay-Review .popup-content {
  width: 600px;
  height: 300px;
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.mini-popup-overlay-Review .popup-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.mini-popup-overlay-Review .popup-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.mini-popup-overlay-Review .continue-button {
  background-color: #4caf50;
  color: white;
}

.mini-popup-overlay-Review .go-back-button {
  background-color: #f44336;
  color: white;
}


.continue-section .frame-259.proxima .loader:before , .continue-section .frame-259.proxima .loader:after , .continue-section .frame-259.proxima .loader{

  background: radial-gradient(closest-side at 50% 12.5%,
  var(--white) 96%,#0000) 50% 0/20% 80% repeat-y,
 radial-gradient(closest-side at 12.5% 50%,
  var(--white) 96%,#0000) 0 50%/80% 20% repeat-x;

}
.continue-section .frame-259.averta .loader:after , .continue-section .frame-259.averta .loader:before , .continue-section .frame-259.averta .loader{

  background: radial-gradient(closest-side at 50% 12.5%,
  var(--white) 96%,#0000) 50% 0/20% 80% repeat-y,
 radial-gradient(closest-side at 12.5% 50%,
  var(--white) 96%,#0000) 0 50%/80% 20% repeat-x;

}

/* HTML: <div class="loader"></div> */
.loader {
  width: 20px;
  margin-right: 15px;
  aspect-ratio: 1;
  display:grid;
  -webkit-mask: conic-gradient(from 15deg,#0000,#000);
  animation: l26 1s infinite steps(12);
}

.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
}
.loader:after {
  transform: rotate(60deg);
}