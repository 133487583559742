.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: var(--cloud-burst);
    padding: 10px;
    width: 1320px;
    height: 680.05px;
    position: relative;
  }
  .popup-content.proxima {
    background: var(--cloud-burst);
  }
  .popup-content.averta {
    background: var(--light-pale-bg);
  }

  .popup-content-layer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  