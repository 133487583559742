.plane-info-row{
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
}
.plane-info-row .identifier-box{
 width: 20px;
 height: 20px;
 border: 2px solid #808080;
 border-radius: 3px;
}
.plane-info-row .info-container{
display: flex;
flex-direction: column;
}
.plane-info-row .info-container .price{
display: flex;
gap: 5px;
}