.meal-section {
  padding: 20px 15px;
  border: 0.8px solid var(--spindle-2);
  border-top: none;
  display: flex;
  width: 100%;
}
.meal-section .data-section {
  width: 30%;
  display: flex;
  flex-direction: column;
}
.meal-section .data-section .travellers-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.meal-section .data-section .travellers-content .traveller-row-data {
  display: flex;
}

.meal-section .data-section .plane-content {
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  gap: 20px;
  padding-left: 12px;
  width: 70%;
  flex-direction: column;
}

.meal-section .meals-section {
  width: 70%;
  flex-direction: column;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
.meal-section .meals-section .meals-wrapper {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.meal-section .meals-section .meal-item{
display: flex;
  padding: 20px 10px;
  width: 70%;
    justify-content: space-between;
}
.meal-section .meals-section .meal-item.isActiveMeal{
background: #f6f6f6;
}
.meal-section .meals-section .meal-item .meal-info-container{
display: flex;
 gap: 15px;
 align-items: center;
}
.meal-section .meals-section .meal-item .meal-info-container .meal-info{
display: flex;
flex-direction: column;
gap: 3px;
}

.meal-section .meals-section .meal-item .meal-controllers-container{
display: flex;
 align-items: center;
 justify-content: center;
 gap: 15px;
}
.meal-section .meals-section .meal-item .meal-controllers-container .meal-control{
cursor: pointer;
border-radius: 50%;
align-items: center;
justify-content: center;
display: flex;
width: 25px;
height: 25px;
border: 2px solid var(--endeavour);
}
.meal-section .meals-section .meal-item .meal-controllers-container img{
  cursor: pointer;
}

.meal-section .meals-section::-webkit-scrollbar {
  width: 7px; /* Width of the scrollbar */
}


.meal-section .meals-section::-webkit-scrollbar-track {
  border-radius: 10px;
}

.meal-section .meals-section.proxima::-webkit-scrollbar-track {
  background: var(--mystic); /* Color of the scrollbar track */
}
.meal-section .meals-section.averta::-webkit-scrollbar-track {
  background: var(--light-pale-bg); /* Color of the scrollbar track */
}

.meal-section .meals-section::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.meal-section .meals-section.proxima::-webkit-scrollbar-thumb {
  background-color: var(--rhino); /* Color of the scrollbar thumb */
  border: 2px solid var(--mystic); /* Adds space around the thumb */
}

.meal-section .meals-section.averta::-webkit-scrollbar-thumb {
  background-color: var(--blue-zodiac); /* Color of the scrollbar thumb */
  border: 2px solid var(--light-pale-bg); /* Adds space around the thumb */
}



/* Scrollbar Buttons (Up Arrow) */
.meal-section .meals-section::-webkit-scrollbar-button:vertical:decrement {
  background: url("../../../../../Assets/Images/scroll-arrow-up-df.svg")
    no-repeat center center;
  /* background-size: 100% 100%; */
  transform: rotate(90deg);
  height: 16px;
  width: 10px;
  cursor: pointer;
}

/* Scrollbar Buttons (Down Arrow) */
.meal-section .meals-section::-webkit-scrollbar-button:vertical:increment {
  background: url("../../../../../Assets/Images/scroll-arrow-down-df.svg")
    no-repeat center center;
  background-size: 100% 100%;
  height: 16px;
  width: 10px;
  cursor: pointer;
}
