
.plane-layout {
    align-items: flex-end;
    background-color: var(--white);
    border-bottom-style: solid;
    border-bottom-width: 11px;
    border-color: var(--white);
    border-top-style: solid;
    border-top-width: 7px;
    display: inline-flex;
    gap: 10px;
    /* min-height: 1896.56px; */
    /* min-width: 414px; */
    /* overflow-x: hidden; */
    /* padding: 269px 4px 311.56px; */
    position: relative;
    width: 100%;
    justify-content: center;
    padding-right: 35px;
  }
  
  .plane-layout .frame-268 {
    align-items: flex-start;
    background-color: var(--white);
    box-shadow: 0px 6px 6px #00000066;
    display: inline-flex;
    flex: 0 0 auto;
    padding: 30px 0px 0px;
    position: relative;
  }
  .plane-layout .frame-268-5 {
    left: 0;
    position: absolute;
    bottom: 100%;
    width: 100%;
  }

  .plane-layout .frame-269 {
    top: calc(100% - 6px);
    left: -2px;
    position: absolute;
    width: calc(100% + 3px);
  }

  
  .plane-layout .frame-268-1 {
    align-items: center;
    background-color: var(--white);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 18px;
    justify-content: center;
    padding: 3px 22px;
    position: relative;
  }
  
  .plane-layout .frame-268-2 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
  }
  
  .plane-layout .frame-268-3 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 18px;
    padding: 5px 0px;
    position: relative;
  }
  
  
  .plane-layout .exit-row-default{
      display: flex;
      width: 100%;
      justify-content: space-between;

  }
  
  
  