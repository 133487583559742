:root {
  /*  Colors  */

  --alto: #dbdbdb;
  --black: #000000;
  --concrete: #f2f2f2;
  --congress-blue: #093c83;
  --curious-blue: #2583e7;
  --danube: #60a2d2;
  --deep-sapphire: #071e69;
  --carmine: #ad0519;
  --eerie-black: #1f1f1f;
  --endeavour: #0057b7;
  --magic-potion: #001446;
  --cultured-pearl: #f5f5f5;
  --magic-potion-2: #00144666;
  --black-haze: #f6f6f6;
  --light-water: #e7f1fb;
  --dark-rain: #7d8e9e;
  --crystal-water: #dfefff;
  --pigeon-post: #aac2da;
  --parrot: #157272;
  --pine-green: #138783;
  --pine-green-2: #128682;
  --mercury: #e3e3e3;
  --cherry: #f48282;
  --error-red: #d32f2f;
  --lightlava: #f56b1b;
  --shady-lady: #aaaaaa;
  --oslo-gray: #838f96;
  --sky-light: #e4edf6;
  --pumice: #c6c7c5;
  --gray-nurse: #eaeaea;
  --gray-black:#333333;
  --rhino: #293a64;
  --stack: #8d8d8d;
  --crystal: #fbfbfb;
  --sand-storm: #d8e7d6;
  --storm-dust: #616161;
  --swans-down: #dbf0ea;
  --white: #ffffff;
  --gravel: #494949;
  --wild-blue-yonder: #97abd3;
  --blue-zodiac: #162751;
  --light-grayish-water: #606060;
  --robins-egg-blue: #00c6c6;
  --optera-galaxy: #001446;
  --dim-sunlight: #fafafa;
  --geyser: #d2dee9;
  --light-sky: #f6f8fb;
  --mystic: #e4edf6;
  --gallery: #f0f0f0;
  --dark-galaxy: #162751;
  --crimson: #d30e2a;
  --quick-silver: #a7a7a7;
  --light-envedor: #e8f3ff;
  --cloud-burst: #e4edf6;
  --tropical-blue-2: #cfdbf6;
  --tropical-blue-3: #c1d9f5;
  --winter-sunlight : #EDEDEC;
  --tropical-blue-4: #c3ddff;
  --gray-4: #808080;
  --dark-purple: #1f2e53;
  --black-2: #1f1f1f;
  --graish-white: #9d9d9d;
  --eastern-blue: #249995;
  --multani-mitti: #e7e0cf;
  --smalt: #00259a;
  --dark-salt: "#A8A8A8";
  --dust: #616161;
  --deep-ocean: #202020;
  --ocean: #32477b;
  /* --ocean : #32477B; */
  --graylight: #c6c7c5;
  --light-cement:rgba(0, 0, 0, 0.6);
  --slimy-green: #41a318;
  --spindle: #b2cae1;
  --spindle-2: #b8cee4;
  --spindle-3: #bcd2e5;
  --spindle-4: #acc9ea;
  --light-sky-bg-hov: #f3f3f3;
  --parrot-color:rgba(191,243,55,255);
  --choclate: #976c41;
  --light-yellow: #f1ead9;
  --light-pale-bg-hov: #faf5eb;
  --gold-shadow: #d2b99b;
  --text-pale-bg: #c2a07c;
  --light-pale-skin-bg: #faf5eb;
  --light-gray-black: #626262;
  --light-pale-border: #dccc95;
  --light-pale-bg: #f0ede5;
  --medium-pale-border: #e0be85;
  --dark-gold: #c2a07c;
  --burning-gold: #926537;
  --light-mud: #c2a07c;
  --dim-sunlight : #FFE9C9;

  /* font sizes  */

  --font-size-7_6: 7.6px;
  --font-size-9_6: 9.6px;
  --font-size-10: 10px;
  --font-size-11: 11px;
  --font-size-11_1: 11.12px;
  --font-size-12: 12px;
  --font-size-13: 13px;
  --font-size-14: 14px;
  --font-size-14_8: 14.8px;
  --font-size-15: 15px;
  --font-size-16: 16px;
  --font-size-16_2: 16.2px;
  --font-size-16_7: 16.7px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-21: 21px;
  --font-size-22: 22px;
  --font-size-23: 23px;
  --font-size-24: 24px;
  --font-size-25: 25px;
  --font-size-26: 26px;
  --font-size-50: 50px;

  /* font Families  */

  /* // Proxima  */
  --font-family-proxima_nova-bold: "Proxima Nova-Bold", Helvetica;
  --font-family-proxima_nova-regular: "Proxima Nova-Regular", Helvetica;
  --font-family-proxima_nova-semibold: "Proxima Nova-Semibold", Helvetica;
  --font-family-proxima_nova-medium: "Proxima Nova-Medium", Helvetica;
  --font-family-proxima_nova-light: "Proxima Nova-Light", Helvetica;

  /* Averta  */
  --font-family-averta-regular: "Averta Regular", Helvetica;
  --font-family-averta-bold: "Averta Bold", Helvetica;
  --font-family-averta-light: "Averta Light", Helvetica;
  --font-family-averta-semibold: "Averta SemiBold", Helvetica;

  /* segoe-ui  */
  --font-family-segoe_ui-regular: "Segoe UI-Regular", Helvetica;
  --font-family-segoe_ui-bold: "Segoe UI-Bold", Helvetica;
  --font-family-segoe_ui-semibold: "Segoe UI-Semibold", Helvetica;

  /* For Future  Breakpoints for responsive UI*/
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}

/* FONT CLASSES */
.font7_6 {
  font-size: var(--font-size-7_6);
}

.font9_6 {
  font-size: var(--font-size-9_6);
}
.font10 {
  font-size: var(--font-size-10);
}

.font11 {
  font-size: var(--font-size-11);
}

.font11_1 {
  font-size: var(--font-size-11_1);
}

.font12 {
  font-size: var(--font-size-12);
}

.font13 {
  font-size: var(--font-size-13);
}

.font14 {
  font-size: var(--font-size-14);
}

.font14_8 {
  font-size: var(--font-size-14_8);
}

.font15 {
  font-size: var(--font-size-15);
}

.font16 {
  font-size: var(--font-size-16);
}
.font16_2 {
  font-size: var(--font-size-16_2);
}
.font16_7 {
  font-size: var(--font-size-16_7);
}

.font18 {
  font-size: var(--font-size-18);
}

.font20 {
  font-size: var(--font-size-20);
}

.font21 {
  font-size: var(--font-size-21);
}

.font22 {
  font-size: var(--font-size-22);
}

.font23 {
  font-size: var(--font-size-23);
}

.font24 {
  font-size: var(--font-size-24);
}
.font25 {
  font-size: var(--font-size-25);
}

.font26 {
  font-size: var(--font-size-26);
}

.font50 {
  font-size: var(--font-size-50);
  font-weight: 700;
}

/* COLOR CLASSES */
.color_alto {
  color: var(--alto);
}

.color_black {
  color: var(--black);
}

.color_concrete {
  color: var(--concrete);
}

.color_congress-blue {
  color: var(--congress-blue);
}

.color_curious-blue {
  color: var(--curious-blue);
}
.color_crystal {
  color: var(--crystal);
}

.color_danube {
  color: var(--danube);
}
.color_light-grayish-water {
  color: var(--light-grayish-water);
}
.color_dim-sunlight {
  color: var(--dim-sunlight);
}

.color_light-pale-skin-bg {
  color: var(--light-pale-skin-bg);
}
.color_deep-sapphire {
  color: var(--deep-sapphire);
}

.color_eerie-black {
  color: var(--eerie-black);
}
.color_graish-white {
  color: var(--graish-white);
}
.color_light-water {
  color: var(--light-water);
}

.color_endeavour {
  color: var(--endeavour);
}
.color_deep-ocean {
  color: var(--deep-ocean);
}
.color_gray-black {
  color: var(--gray-black);
}
.color_choclate {
  color: var(--choclate);
}
.color_light-pale-bg {
  color: var(--light-pale-bg);
}
.color_geyser {
  color: var(--geyser);
}
.color_cherry {
  color: var(--cherry);
}
.color_multani-mitti {
  color: var(--multani-mitti);
}
.color_light-gray-black {
  color: var(--light-gray-black);
}
.color_dark-salt {
  color: var(--dark-salt);
}

.color_magic-potion {
  color: var(--magic-potion);
}
.color_light-cement {
  color: var(--light-cement);
}
.color_light-yellow {
  color: var(--light-yellow);
}
.color_parrot-color {
  color: var(--parrot-color);
}
.color_medium-pale-border {
  color: var(--medium-pale-border);
}
.color_blue-zodiac {
  color: var(--blue-zodiac);
}
.color_sand-storm {
  color: var(--sand-storm);
}
.color_gray-4 {
  color: var(--gray-4);
}
.color_winter-sunlight {
  color: var(--winter-sunlight);
}

.color_cultured-pearl {
  color: var(--cultured-pearl);
}
.color_slimy-green {
  color: var(--slimy-green);
}
.color_dark-rain {
  color: var(--dark-rain);
}
.color_spindle {
  color: var(--spindle);
}
.color_crystal-water {
  color: var(--crystal-water);
}
.color_spindle-2 {
  color: var(--spindle-2);
}
.color_spindle-3 {
  color: var(--spindle-3);
}
.color_spindle-4 {
  color: var(--spindle-4);
}
.color_carmine {
  color: var(--carmine);
}

.color_burning-gold {
  color: var(--burning-gold);
}
.color_pigeon-post {
  color: var(--pigeon-post);
}
.color_dim-sunlight {
  color: var(--dim-sunlight);
}
.color_error-red {
  color: var(--error-red);
}

.color_text-pale-bg {
  color: var(--text-pale-bg);
}
.color_ocean {
  color: var(--ocean);
}

.color_magic-potion-2 {
  color: var(--magic-potion-2);
}

.color_black-haze {
  color: var(--black-haze);
}

.color_pine-green {
  color: var(--pine-green);
}

.color_pine-green-2 {
  color: var(--pine-green-2);
}

.color_mercury {
  color: var(--mercury);
}

.color_lightlava {
  color: var(--lightlava);
}

.color_shady-lady {
  color: var(--shady-lady);
}

.color_oslo-gray {
  color: var(--oslo-gray);
}

.color_sky-light {
  color: var(--sky-light);
}
.color_parrot {
  color: var(--parrot);
}

.color_pumice {
  color: var(--pumice);
}

.color_gray-nurse {
  color: var(--gray-nurse);
}

.color_rhino {
  color: var(--rhino);
}

.color_stack {
  color: var(--stack);
}

.color_storm-dust {
  color: var(--storm-dust);
}

.color_swans-down {
  color: var(--swans-down);
}

.color_white {
  color: var(--white);
}

.color_gravel {
  color: var(--gravel);
}

.color_wild-blue-yonder {
  color: var(--wild-blue-yonder);
}

.color_robins-egg-blue {
  color: var(--robins-egg-blue);
}

.color_light-sky {
  color: var(--light-sky);
}

.color_mystic {
  color: var(--mystic);
}

.color_gallery {
  color: var(--gallery);
}

.color_crimson {
  color: var(--crimson);
}
.color_optera-galaxy {
  color: var(--optera-galaxy);
}

.color_quick-silver {
  color: var(--quick-silver);
}

.color_light-envedor {
  color: var(--light-envedor);
}

.color_cloud-burst {
  color: var(--cloud-burst);
}

.color_tropical-blue-2 {
  color: var(--tropical-blue-2);
}

.color_tropical-blue-3 {
  color: var(--tropical-blue-3);
}

.color_tropical-blue-4 {
  color: var(--tropical-blue-4);
}

.color_dark-purple {
  color: var(--dark-purple);
}

.color_black-2 {
  color: var(--black-2);
}

.color_eastern-blue {
  color: var(--eastern-blue);
}

.color_smalt {
  color: var(--smalt);
}

.color_dust {
  color: var(--dust);
}

.color_graylight {
  color: var(--graylight);
}

/* // Font Family Classes */

/* // Proxima Start  */

.proxima_bold {
  font-family: var(--font-family-proxima_nova-bold);
  font-style: normal;
  font-weight: 400;
}
.proxima_regular {
  font-family: var(--font-family-proxima_nova-regular);
  font-style: normal;
  font-weight: 400;
}
.proxima_semibold {
  font-family: var(--font-family-proxima_nova-semibold);
  font-style: normal;
  font-weight: 400;
}
.proxima_medium {
  font-family: var(--font-family-proxima_nova-medium);
  font-style: normal;
  font-weight: 400;
}
.proxima_light {
  font-family: var(--font-family-proxima_nova-light);
  font-style: normal;
  font-weight: 400;
}

/* // Proxima End  */

/* // Averta Start  */

.averta_regular {
  font-family: var(--font-family-averta-regular);
  font-style: normal;
  font-weight: 400;
}
.averta_bold {
  font-family: var(--font-family-averta-bold);
  font-style: normal;
  font-weight: 400;
}
.averta_light {
  font-family: var(--font-family-averta-light);
  font-style: normal;
  font-weight: 400;
}
.averta_semibold {
  font-family: var(--font-family-averta-semibold);
  font-style: normal;
  font-weight: 400;
}
.averta_medium {
  font-family: var(--font-family-averta-semibold);
  font-style: normal;
  font-weight: 400;
}

/* // Averta End */

/* // Segoe-Ui Start  */

.segoe_ui_regular {
  font-family: var(--font-family-segoe_ui-regular);
  font-style: normal;
  font-weight: 400;
}
.segoe_ui_bold {
  font-family: var(--font-family-segoe_ui-bold);
  font-style: normal;
  font-weight: 400;
}
.segoe_ui_semibold {
  font-family: var(--font-family-segoe_ui-semibold);
  font-style: normal;
  font-weight: 400;
}

/* --font-size-xxs */

/* // Segoe-Ui End */
