.content-header {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.content-header.proxima {
  border: 0.8px solid var(--spindle-2);
}
.content-header.averta {
  border: 0.8px solid var(--light-pale-border);
}
.content-header .header-content {
  display: flex;
  gap: 10px;
}
.content-header .info {
    display: flex;
    gap: 10px;
    padding-right: 25px;
}
.content-header .trip {
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding-left: 25px;
    border-left: 0.8px solid var(--gray-4);
}
.content-header .trip .coordinates{
   padding-left: 6px;
}
.content-header .info .img-container {
  display: flex;
  align-items: center;
}
.content-header img {
  width: 28px;
  height: 28px;
}
.content-header .info-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.content-header .info .info-codes {
  display: flex;
  gap: 5px;
  align-items: baseline;
}
.content-header .info-btn {
    cursor: pointer;
  display: flex;
  align-items: center;
}
