.topbar-wrapper{
  height: 9%;
}


.topBar1 {
    align-self: stretch;
    display: flex;
    width: 100%;
  }
.topbar-wrapper.proxima {
    box-shadow: 0px 0px 5px 1px #00000026;
  }
.topbar-wrapper.averta {
    box-shadow: 0px 0px 5px 1px var(--gold-shadow);
  }

  .topBar1.frame-243-6 {
    align-items: flex-start;
    flex: 0 0 auto;
    position: relative;
    height: 100%;
  }

  
.topBar1-1 .frame-237-33 {
    display: flex;
    justify-content: space-between;
  }
.topBar1 .frame-237-3 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    position: relative;
    justify-content: space-between;
  }
  
  .topBar1-1 .frame-237-33 > div {
    flex: 1;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-top: -3px;
  }
  .topBar1 .frame-237-3 > div {
    flex: 1;
  }

  .topBar1 .bg-dark-purple.proxima {
      background-color: var(--dark-purple);
  }
  .topBar1 .bg-dark-purple.averta {
      background-color: var(--magic-potion);
  }
  .topBar1 .bg-white {
      background-color: var(--white);
  }
  .topBar1 .line-between-eerie-black  {
      height: 1px;
      background-color: var(--eerie-black);
      width: 15px;
  }
  .topBar1 .line-between-white  {
      height: 1px;
      background-color: var(--white);
      width: 15px;
  }


  .down-arrow {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #333; /* Change the color as needed */
    margin: 0 auto;
  }
  

  .topBar1 .frame-179 {
    cursor: pointer;
    align-items: flex-start;
    border-color: var(--mystic);
    border-right-style: solid;
    border-right-width: 1px;
    display: flex;
    flex-direction: column;
    padding: 2px 0px;
    position: relative;
    width: 417px;
    height: 100%;
  }


  .topBar1 .rectangle-72 {
    height: 64px;
    left: 0;
    position: absolute;
    top: 0;
    width: 417px;
  }
  
.topBar1 .frame-238-5 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 5px 15px;
    position: relative;
    width: 100%;
  }

  
  .topBar1 .frame {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 2px;
    position: relative;
  }

  .topBar1 .frame-2 {
    align-items: self-end;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }
  
  .topBar1 .frame-202 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    justify-content: center;
    padding: 0px 0px 0px 1.5px;
    position: relative;
  }

  .topBar1 .flight-details {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: 241px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .topBar1 .group-206-2 {
    height: 11.05px;
    position: relative;
    width: 11.05px;
  }

  .topBar1 .overlap-group-3 {
    align-items: flex-start;
    border: 1px solid;
    border-color: var(--white);
    border-radius: 6.53px;
    display: flex;
    height: 13px;
    left: -1px;
    min-width: 13px;
    padding: 2.1px 4.7px;
    position: relative;
    top: -1px;
  }

  .topBar1 .vector-50-2 {
    height: 6px;
    width: 3px;
  }


  .topBar1 .x2h-40m-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.47px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }

  
.topBar1 .frame-239 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 15px;
    padding: 5px 22px;
    position: relative;
    width: 100%;
  }
.topBar1 .frame-239.averta {
padding-top: 1px;
  }
  
  .topBar1 .frame-238-6 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 2px;
    position: relative;
  }

  .topBar1 .place {
    letter-spacing: 0;
    line-height: normal;
    /* margin-top: -1.00px; */
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  .topBar1 .frame-170-1 {
    align-items: center;
    gap: 2px;
  }

  .topBar1 .frame-170-4 {
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    gap: 5px;
    position: relative;
  }

  .topBar1 .line-5 {
    height: 1px;
    margin-left: -0.50px;
    object-fit: cover;
    position: relative;
    width: 11px;
  }

  
  .topBar1 .frame-202 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    justify-content: center;
    padding: 0px 0px 0px 1.5px;
    position: relative;
  }

  
  .topBar1 .address {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  
.topBar1 .line-8 {
    height: 1px;
    margin-right: -0.50px;
    object-fit: cover;
    position: relative;
    width: 11px;
  }


  .topBar1 .place-1 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .topBar1 .frame-176-1 {
    cursor: pointer;
    align-items: center;
    align-self: stretch;
    border-color: var(--mystic);
    border-right-style: solid;
    border-right-width: 1px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: center;
    padding: 6px 0px;
    position: relative;
  }

  .topBar1 .frame-176-1::after {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #333; /* Change the color as needed */
    margin: 0 auto;
  }

  .topBar1 .frame-170-2 {
    align-items: flex-start;
    gap: 10px;
    padding: 0px 15px;
  }

  .topBar1 .frame-170-4 {
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    position: relative;
  }

  
  .topBar1 .view {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -3.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .topBar1 .frame-177 {
    cursor: pointer;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: center;
    padding: 6px 0px;
    position: relative;
  }
