.addons-head {
    display: flex;
    background-color: var(--white);
    padding: 13px 20px;
  }
.addons-head .item{
    padding: 8px 15px;
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
  }
.addons-head .item.selected{
    background-color: var(--dark-purple);
  }