.flight-error-message-2 {
    width: auto;
    z-index: 1;
    background-color: #FFE0DA;
    position: absolute;
    left: 5%;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: end;
  
  }
  
  .flight-error-message-3 {
    width: max-content;
    z-index: 1;
    background-color: #FFE0DA;
    position: absolute;
    top: 108%;
    left: -30%;
    padding: 4px 8px;
  
  }
  
  .flight-error-message-4 {
    width: max-content;
    z-index: 1;
    background-color: #FFE0DA;
    position: absolute;
    top: 108%;
    left: 1%;
    padding: 4px 8px;
  
  }
  
  .flight-error-message::after {
    bottom: 100%;
    left: 40%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #ffd3d4;
    border-width: 8px;
    margin-left: -24px;
  
  }
  
  .flight-error-message::after {
    bottom: 100%;
    left: 40%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #ffd3d4;
    border-width: 8px;
    margin-left: -24px;
  
  }
.flight-error-message-100::after {
  top: 100%; /* Position the arrow at the bottom of the element */
  left: 68%; /* Adjust the position horizontally as needed */
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #ffd3d4; /* Change border-bottom-color to border-top-color */
  border-width: 8px;
  margin-left: -8px; /* Adjust margin to center the arrow horizontally */
}

  
  .flight-error-message-alert::after {
    bottom: 100%;
    left: 40%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #f27258;
    border-width: 8px;
    margin-left: -24px;
  
  }
  
  .flight-error-message-alert-special::after {
    bottom: 100%;
    right: 20%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #f27258;
    border-width: 8px;
    /* margin-left: -24px; */
  
  }
  
  .flight-error-message-alert {
    background-color: #f27258 !important;
    color: white !important;
  }
  
  
  .flight-error-message {
    width: auto;
    z-index: 1;
    background-color: #FFE0DA;
    position: absolute;
    top: 120%;
    left: 300px;
    padding: 4px 8px;
  
  }
  .flight-error-message-special {
    width: auto;
    z-index: 1;
    background-color: #FFE0DA;
    position: absolute;
    top: 120%;
    left: 300px;
    padding: 4px 8px;
  
  }

  .flight-error-message-special::after {
    bottom: 100%;
    right: 20%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #FFE0DA;
    border-width: 8px;
    margin-left: -24px;
  
  }
  


  .alert-error-msg {
    margin-right: 10px;
    margin-right: 10px;
  }
  
  .makeFlex {
    display: flex;
  }