.result-page-flights-search-bar {
  align-items: center;
  background-color: var(--magic-potion);
  display: flex;
  z-index: 500;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  cursor: pointer;
}

.result-page-flights-search-bar.sticky {
  position: fixed;
  top: 0;
  z-index: 500;
}
.result-page-flights-return-cross {
  position: absolute;
  left: 65px;
}

.result-page-flights-frame-97 {
  width: 1342px;
  align-items: center;
  /* align-self: stretch; */
  background-color: var(--magic-potion);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  /* gap: 1px; */
  justify-content: center;
  padding-top: 14px;
  padding-bottom: 14px;
  position: relative;
  /* width: 100%; */
}
.result-page-flights-frame-97.proxima {
  min-height: 123px !important;
}
.result-page-flights-frame-97.averta {
}

.result-page-flights-frame-27 {
  min-height: 54px !important;
  align-items: center;
  align-self: stretch;
  border-radius: 5px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.result-page-flights-frame-33 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 1px 0px;
  position: relative;
  width: 100%;
}

.result-page-flights-frame-35 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.result-page-flights-frame-101 {
  min-height: 52px;
  align-items: flex-start;
  background-color: var(--rhino);
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 12px;
  padding-right: 19px;
  position: relative;
  min-width: 10%;
}
.result-page-flights-frame-101-1 {
  align-items: flex-start;
  /* background-color: var(--white-2); */
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-top: 9px;
  position: relative;
  min-width: 10%;
  padding-bottom: 9px;
  padding-left: 4%;
}

.result-page-flights-frame-101-1-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-right: 19px;
  position: relative;
  min-width: 138px;
  margin-right: 4px;
  padding-left: 12px;
}

.result-page-flights-trip-type {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  width: max-content;
}

.result-page-flights-frame-168-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 4px;
  justify-content: space-around;
  position: relative;
  width: 100%;
}

.result-page-flights-one-way {
  flex: 1;
  letter-spacing: 0;
  line-height: normal;
 text-wrap: nowrap;
  position: relative;
  width: max-content;
}

.result-page-flights-arrow {
  position: relative;
  left: 14px;
}

.result-page-flights-frame-99-7 {
  align-items: center;
  align-self: stretch;
  min-height: 52px;
  width: 35%;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2px;
  justify-content: center;
  position: relative;
}
.result-page-flights-frame-99-7-7 {
  align-items: center;
  align-self: stretch;
  min-height: 52px;
  margin-left: 10px;
  width: 670px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2px;
  justify-content: center;
  position: relative;
}

.result-page-flights-frame-28 {
  min-height: 52px;
  width: 50%;
  align-items: flex-start;
  background-color: var(--rhino);
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 12px;
  padding-right: 19px;
  position: relative;
}
.result-page-flights-frame-28-1 {
  align-items: flex-start;
  background-color: var(--rhino);
  display: flex;
  flex-direction: column;
  gap: 2px;

  padding: 9px 12px;
  position: relative;
  /* width: 255px; */
  min-height: 52px;
}

.result-page-flights-frame-99-8 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 63px;
  padding: 0px 11px 0px 0px;
  position: relative;
  width: 186px;
}

.result-page-flights-place-32 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 78.24px;
}

.result-page-flights-del-delhi-india {
  align-self: stretch;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.result-page-flights-frame-29 {
  min-height: 52px;
  width: 50%;
  align-items: flex-start;
  background-color: var(--rhino);
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 12px;
  padding-right: 19px;
  position: relative;
}

.result-page-flights-frame-29-1 {
  align-items: flex-start;
  background-color: var(--rhino);
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 9px 12px;
  position: relative;
  /* width: 254px; */
  min-height: 52px;
}

.result-page-flights-frame-99-9 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.result-page-flights-to {
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.result-page-flights-trv-thiruvananthapuram-india {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.result-page-flights-exchange-alt {
  height: 11px;
  position: relative;
  top: 24px;
}

.result-page-flights-innextText {
  max-width: 185px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}
.result-page-flights-innextText-2 {
  max-width: 201px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}
.result-page-flights-frame-100-1 {
  align-items: center;
  width: 25%;
  /* margin-left: 1px; */
  background-color: var(--rhino);
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}
.result-page-flights-frame-100-10 {
  align-items: center;
  min-width: 10%;
  margin-left: 1px;
  background-color: var(--rhino);
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.result-page-flights-frame-30 {
  background-color: var(--rhino);;
  min-height: 52px !important;
  align-items: flex-start;
  /* border-radius: 5px; */
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  overflow: hidden;
  padding: 9px 12px;
  position: relative;
  /* width: 155px; */
}

.result-page-flights-frame-103-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
  width: 100%;
}

.result-page-flights-departure {
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.result-page-flights-tue-30-jan-9 {
  /* margin-left: -25px; */
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.result-page-flights-frame-31 {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
  padding: 9px 12px;
  position: relative;
  width: 50%;
}
.result-page-flights-frame-31-1 {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
  padding: 9px 12px;
  position: relative;
}

.result-page-flights-return {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
}

.result-page-flights-add-return {
  align-self: stretch;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.result-page-flights-frame-32 {
  min-height: 52px;
  align-items: flex-start;
  background-color: var(--rhino);
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 9px 12px;
  position: relative;
  /* width: 235px; */
}

.result-page-flights-frame-1-9 {
  width: max-content;
}

.result-page-flights-travellers-class {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  padding-bottom: 1px;
  position: relative;
}

.result-page-flights-address-18 {
  margin-top: -1px;
}

.result-page-flights-place-33 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 73px;
}

.result-page-flights-frame-20-2 {

  align-items: center;
  min-height: 52px;
  width: 152px;
  background-color: var(--white);
  gap: 10px;
  justify-content: center;

}
.result-page-flights-frame-20-2.proxima {
  background-color: var(--white);
}

.result-page-flights-frame-20-2.averta {
  background-color: var(--light-pale-skin-bg);
}

.result-page-flights-frame-20-2.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.result-page-flights-search-1 {
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin: 0 auto;
  position: relative;
  width: fit-content;
}

.result-page-flights-frame-44 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  justify-content: center;
  position: relative;
}

.result-page-flights-frame-444 {
  align-items: center;
  min-height: 31px;
  margin-top: 9px;
  /* min-width: 1342px; */
  max-height: 31px;
  display: flex;
  flex: 0 0 auto;
  gap: 15px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.result-page-flights-fare-type {
  letter-spacing: 0;
  line-height: 16px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.result-page-flights-frame-205 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  justify-content: center;
  position: relative;
}

.result-page-flights-frame-47 {
  align-items: center;
  background-color: var(--rhino);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 21px;
  position: relative;
}

.result-page-flights-frame-205-1 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1px;
  justify-content: center;
  padding: 1px;
  position: relative;
}

.result-page-flights-frame-42 {
  align-items: flex-start;
  background-color: var(--rhino);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.result-page-flights-frame-103-7 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  padding: 7.5px 30px;
  position: relative;
}

.result-page-flights-regular {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.result-page-flights-check-2 {
  height: 10px;
  left: 85px;
  position: absolute;
  top: 8px;
  width: 10px;
}

.result-page-flights-frame-4-1 {
  align-items: center;
  background-color: var(--magic-potion);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.result-page-flights-frame-103-8 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  justify-content: center;
  padding: 7.5px 30px;
  position: relative;
}

.result-page-flights-armed-forces {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.result-page-flights-frame-103-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 7.5px 30px;
  position: relative;
}

.result-page-flights-student {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.result-page-flights-senior-citizen {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.result-page-flights-frame-2-1 {
  display: inline-flex;
}

.result-page-flights-frame-2-3 {
  align-items: center;
  height: 34px;
  align-self: stretch;
  flex: 0 0 auto;
  position: relative;
}

.result-page-flights-frame-234 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 0px 25px;
  position: relative;
}

.result-page-flights-frame-234.proxima {
  background-color: var(--endeavour);
}

.result-page-flights-frame-234.averta {
  background-color: var(--light-mud);
}

.result-page-flights-frame-233-1 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-left-style: solid;
  border-left-width: 2px;
  border-top-style: solid;
  border-top-width: 2px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 0px 25px;
  position: relative;
}

.result-page-flights-frame-233-1.proxima {
  background-color: var(--white);
  border-color: var(--endeavour);
}
.result-page-flights-frame-233-1.averta {
  background-color: var(--light-pale-skin-bg);
  border-color: var(--dark-gold);
}

.result-page-flights-flight {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.result-page-flights-frame-232-3 {
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.result-page-flights-frame-99-77 {
  align-items: center;
  align-self: stretch;
  background-color: var(--rhino);
  display: flex;
  gap: 2px;
  justify-content: center;
  position: relative;
  min-width: 60.2%;
}

.result-page-flights-frame-288 {
  align-items: flex-start;
  background-color: var(--white-2);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 2px;
  padding: 6.5px 12px;
  position: relative;
}

.result-page-flights-trip {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
}
.result-page-flights-trip-5 {
  text-align: right;
width: 104px;
}



.result-page-flights-frame-232-2 {
  align-items: center;
  gap: 12px;
}

.result-page-flights-via-sydney-toronto-brampton {
  color: var(--graylight);
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: normal;
  width: fit-content;
  max-width: 80%;
  /* overflow: ; */
}

.result-page-flights-container {
  display: flex;
  align-items: center;
  color: white;
  font-family: "Proxima Nova", sans-serif;
}

/* .result-page-flights-del-new-delhi, .result-page-flights-mel-melbourne {
    flex: 1;
    padding: 0 10px;
  } */

.result-page-flights-exchange-alt {
  margin: 0 10px;
  height: 16px; /* Adjust as needed */
}

.result-page-flights-del-new-delhi {
  /* display: flex; */
  flex-wrap: wrap;
  gap: 8px;
}

.result-page-flights-del-new-delhi p {
  margin-bottom: 0px;
}

.exchange_alt {
  align-self: center;
  margin-left: 12px;
  margin-right: 12px;
}

.result-page-flights-frame-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2px;
  width: 100%;
  justify-content: center;
  /* padding-top: 3px; */
  position: relative;
}


/* .result-page-flights-frame-28 {
  align-items: flex-start;
  background-color: var(--white-2);
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 9px 12px;
  position: relative;
  width: 255px;
} */

.result-page-flights-place-3 {
  align-self: stretch;
  line-height: 16px;
  margin-top: -1px;
  white-space: nowrap;
  width: 78.24px;
}

.result-page-flights-frame-99-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.result-page-flights-exchange-alt {
  height: 13px;
  left: 248px;
  position: absolute;
  top: 21px;
  width: 15px;
}

.result-page-flights-frame-100 {
  align-items: center;
  background-color: var(--white-2);
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.result-page-flights-frame-103 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
  width: 100%;
}

.result-page-flights-tue-30-jan-24 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.result-page-flights-frame-104 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  min-width: 10%;
  justify-content: center;
  padding: 10px 12px;
  position: relative;
}

.result-page-flights-frame-103-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  padding: 12px 0px;
  position: relative;
  min-width: 20%;
}

.result-page-flights-frame-4444 {
  align-items: center;
  border: 1px solid;
  cursor: pointer;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  justify-content: center;
  min-height: 28px;
  min-width: 114.51px;
  padding: 5.5px 16px;
  position: relative;
}
.result-page-flights-frame-4444.proxima {
  border-color: var(--robins-egg-blue);
}
.result-page-flights-frame-4444.averta {
  border-color: var(--medium-pale-border);
}

.frame-205 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  justify-content: center;
  position: relative;
}

/**





.mac-inter-one-way-result-page {
    align-items: flex-start;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .navigation {
    align-items: flex-start;
    align-self: stretch;
    box-shadow: 0px 4px 4px #0067b81f;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-98 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    padding: 12px 85px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-99-1 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 512px;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-99-2 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 54px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .group-167 {
    height: 40px;
    position: relative;
    width: 44.83px;
  }
  
  .mac-inter-one-way-result-page .overlap-group-3 {
    height: 39px;
    position: relative;
    width: 45px;
  }
  
  .mac-inter-one-way-result-page .group-149 {
    background-image: url(../img/exclude.svg);
    background-size: 100% 100%;
    height: 33px;
    left: 0;
    position: absolute;
    top: 0;
    width: 44px;
  }
  
  .mac-inter-one-way-result-page .vector-68 {
    height: 4px;
    left: 2px;
    position: absolute;
    top: 31px;
    width: 42px;
  }
  
  .mac-inter-one-way-result-page .vector-69 {
    height: 4px;
    left: 1px;
    position: absolute;
    top: 35px;
    width: 44px;
  }
  
  .mac-inter-one-way-result-page .stfares {
    color: var(--magic-potion);
    font-family: var(--font-family-proxima_nova-semibold);
    font-size: var(--font-size-24);
    font-weight: 400;
    letter-spacing: 0.44px;
    line-height: normal;
    position: relative;
    text-align: center;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-99-3 {
    align-items: flex-start;
    display: flex;
    gap: 25px;
    position: relative;
    width: 802px;
  }
  
  .mac-inter-one-way-result-page .frame-19 {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    padding: 0px 4px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-99-4 {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 6px;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .group-108 {
    box-shadow: -3.26px 5.43px 2.17px #00000040;
    height: 22.08px;
    position: relative;
    width: 32.31px;
  }
  
  .mac-inter-one-way-result-page .overlap-group4 {
    height: 22px;
    position: relative;
    width: 32px;
  }
  
  .mac-inter-one-way-result-page .group-107 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1px;
    left: 0;
    min-height: 20px;
    padding: 0.1px 0;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .mac-inter-one-way-result-page .overlap-group3 {
    height: 14px;
    margin-right: 0.27px;
    position: relative;
    width: 32px;
  }
  
  .mac-inter-one-way-result-page .vector-2-1 {
    height: 12px;
    width: 32px;
  }
  
  .mac-inter-one-way-result-page .vector-3 {
    height: 7px;
    left: 8px;
    position: absolute;
    top: 0;
    width: 10px;
  }
  
  .mac-inter-one-way-result-page .group-105 {
    align-items: flex-start;
    display: flex;
    height: 4px;
    left: 7px;
    min-width: 4px;
    position: absolute;
    top: 3px;
    transform: rotate(5.49deg);
  }
  
  .mac-inter-one-way-result-page .overlap-group2 {
    height: 5px;
    margin-top: -0.5px;
    position: relative;
    width: 5px;
  }
  
  .mac-inter-one-way-result-page .line-6 {
    height: 1px;
    left: 1px;
    position: absolute;
    top: 0;
    transform: rotate(-5.49deg);
    width: 2px;
  }
  
  .mac-inter-one-way-result-page .line-container {
    height: 3px;
    left: 0;
    position: absolute;
    top: 1px;
    width: 5px;
  }
  
  .mac-inter-one-way-result-page .line-7 {
    height: 1px;
    left: 3px;
    position: absolute;
    top: 2px;
    transform: rotate(-5.49deg);
    width: 2px;
  }
  
  .mac-inter-one-way-result-page .line-8 {
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(-5.49deg);
    width: 4px;
  }
  
  .mac-inter-one-way-result-page .vector-4 {
    height: 3px;
    left: 21px;
    position: absolute;
    top: 2px;
    width: 8px;
  }
  
  .mac-inter-one-way-result-page .group-104 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-right: 2.19px;
    min-height: 4px;
    transform: rotate(21.59deg);
    width: 4px;
  }
  
  .mac-inter-one-way-result-page .line-6-1 {
    height: 1px;
    margin-left: 0.04px;
    transform: rotate(-21.59deg);
    width: 2px;
  }
  
  .mac-inter-one-way-result-page .line-container-1 {
    height: 3px;
    margin-left: -0.57px;
    position: relative;
    width: 5px;
  }
  
  .mac-inter-one-way-result-page .line-7-1 {
    height: 1px;
    left: 3px;
    position: absolute;
    top: 2px;
    transform: rotate(-21.59deg);
    width: 2px;
  }
  
  .mac-inter-one-way-result-page .line-8-1 {
    height: 1px;
    left: 0;
    position: absolute;
    top: 1px;
    transform: rotate(-21.59deg);
    width: 4px;
  }
  
  .mac-inter-one-way-result-page .vector-8 {
    height: 12px;
    left: 16px;
    position: absolute;
    top: 10px;
    width: 6px;
  }
  
  .mac-inter-one-way-result-page .flights {
    color: var(--magic-potion);
    font-family: var(--font-family-proxima_nova-semibold);
    font-size: var(--font-size-20);
    font-weight: 400;
    letter-spacing: 0.16px;
    line-height: 21px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-2 {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-99 {
    align-items: flex-end;
    display: flex;
    flex: 0 0 auto;
    gap: 6px;
    justify-content: center;
    position: relative;
    width: 116px;
  }
  
  .mac-inter-one-way-result-page .frame-128 {
    flex: 0 0 auto;
    margin-bottom: -1.27px;
    margin-top: -0.44px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .hotels {
    color: var(--magic-potion);
    font-family: var(--font-family-proxima_nova-semibold);
    font-size: var(--font-size-20);
    font-weight: 400;
    letter-spacing: 0.16px;
    line-height: 20px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-22 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 6px;
    height: 29.75px;
    justify-content: flex-end;
    padding: 0px 5px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-99-5 {
    align-items: flex-end;
    display: inline-flex;
    flex: 1;
    flex-grow: 1;
    gap: 5px;
    justify-content: center;
    padding: 0px 0px 1px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .group-108-1 {
    box-shadow: -3.26px 5.43px 2.17px #00000040;
    height: 22.08px;
    position: relative;
    width: 32.32px;
  }
  
  .mac-inter-one-way-result-page .group-107-1 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1px;
    left: 0;
    min-height: 20px;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .mac-inter-one-way-result-page .overlap-group3-1 {
    height: 15px;
    margin-right: 0.27px;
    position: relative;
    width: 33px;
  }
  
  .mac-inter-one-way-result-page .vector-2-2 {
    height: 13px;
    width: 33px;
  }
  
  .mac-inter-one-way-result-page .vector-3-1 {
    height: 8px;
    left: 8px;
    position: absolute;
    top: 0;
    width: 10px;
  }
  
  .mac-inter-one-way-result-page .group-105-1 {
    align-items: flex-start;
    display: flex;
    height: 4px;
    left: 7px;
    min-width: 5px;
    position: absolute;
    top: 3px;
    transform: rotate(5.49deg);
  }
  
  .mac-inter-one-way-result-page .vector-4-1 {
    height: 4px;
    left: 21px;
    position: absolute;
    top: 2px;
    width: 9px;
  }
  
  .mac-inter-one-way-result-page .group-104-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-right: 2.19px;
    min-height: 4px;
    transform: rotate(21.59deg);
    width: 5px;
  }
  
  .mac-inter-one-way-result-page .flights-1 {
    letter-spacing: 0.16px;
    line-height: 24px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .hotels-1 {
    letter-spacing: 0.16px;
    line-height: 22px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-23 {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 6px;
    justify-content: flex-end;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-99-6 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    justify-content: center;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .hotel_building_stars {
    height: 29.75px;
    position: relative;
    width: 29.75px;
  }
  
  .mac-inter-one-way-result-page .packages {
    letter-spacing: 0;
    line-height: 26px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-100 {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
    left: 1188px;
    position: absolute;
    top: 4px;
  }
  
  .mac-inter-one-way-result-page .frame-8-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 7px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .login-signup {
    color: var(--black);
    font-family: var(--font-family-proxima_nova-regular);
    font-size: var(--font-size-20);
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .download-1 {
    height: 31px;
    position: relative;
    width: 31px;
  }
  
  .mac-inter-one-way-result-page .flights-section::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  
  .mac-inter-one-way-result-page .flights-section {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--mystic);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    overflow-y: scroll;
    padding: 123px 0px 0px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-102 {
    align-items: flex-start;
    align-self: stretch;
    border-radius: 5px;
    display: flex;
    flex: 0 0 auto;
    gap: 25px;
    justify-content: center;
    overflow: hidden;
    padding: 0px 76px 25px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-232 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    margin-left: -5.00px;
    padding: 25px 0px 0px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-260 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-104-3 {
    align-items: flex-start;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    gap: 20px;
    padding: 20px 0px;
    width: 313px;
  }
  
  .mac-inter-one-way-result-page .frame-175 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 0px 20px 0px 0px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-201 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 0px 0px 0px 15px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .popular-filters {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -3.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-1 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    padding: 0px 15px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-201-6 {
    align-items: center;
    align-self: stretch;
    display: flex;
    height: 21.4px;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-1-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-3 {
    align-items: center;
    border: 1px solid;
    border-color: var(--stack);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .group-46 {
    height: 21.4px;
    position: relative;
    width: 21px;
  }
  
  .mac-inter-one-way-result-page .check {
    height: 15px;
    left: 4px;
    position: absolute;
    top: 3px;
    width: 14px;
  }
  
  .mac-inter-one-way-result-page .non-stop-flights {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-4 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 2px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .vector {
    height: 10.99px;
    position: relative;
    width: 9px;
  }
  
  .mac-inter-one-way-result-page .number {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-201-7 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .address {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .number-1 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .frame-201-8 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .x1-stop-flights {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .number-2 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .frame-201-9 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .group-52 {
    border: 1px solid;
    border-color: var(--stack);
    height: 21.4px;
    position: relative;
    width: 21px;
  }
  
  .mac-inter-one-way-result-page .refundable-flights {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .number-3 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .frame-201-10 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .check-in-baggage-flights {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .number-4 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .frame-232-1 {
    align-self: stretch;
    background-color: var(--white);
    flex-direction: column;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-1-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 35px;
    padding: 30px 0px 15px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .one-way-price {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -3.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-170 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    padding: 0px 15px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-170-1 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--alto);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-169 {
    align-self: stretch;
    flex: 0 0 auto;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-175-1 {
    align-items: center;
    display: flex;
    left: 22px;
    position: relative;
    top: -10px;
    width: 159px;
  }
  
  .mac-inter-one-way-result-page .frame-201-1 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-178 {
    background-color: var(--white);
    border-radius: 21.34px;
    box-shadow: 0px 0px 2.93px 2.2px #00144629;
    position: relative;
    width: 22px;
  }
  
  .mac-inter-one-way-result-page .line-29 {
    flex: 1;
    flex-grow: 1;
    height: 2px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-177 {
    background-color: var(--white);
    border-radius: 21.34px;
    box-shadow: 0px 0px 2.93px 2.2px #00144629;
    position: relative;
    width: 22px;
  }
  
  .mac-inter-one-way-result-page .frame-202-7 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-5 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-170-2 {
    align-items: center;
    background-color: var(--white);
    border-radius: 2px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .vector-1 {
    height: 12.6px;
    position: relative;
    width: 10.32px;
  }
  
  .mac-inter-one-way-result-page .number-5 {
    margin-top: -0.50px;
  }
  
  .mac-inter-one-way-result-page .number-6 {
    margin-top: -0.50px;
  }
  
  .mac-inter-one-way-result-page .flight-duration {
    margin-top: -3.00px;
  }
  
  .mac-inter-one-way-result-page .frame-202-8 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .x2h-10m {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.50px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .x5h-10m {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.50px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-1-3 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    padding: 30px 0px 15px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-201-2 {
    align-items: center;
    display: flex;
    gap: 10px;
    height: 21px;
    padding: 0px 15px;
    position: relative;
    width: 294px;
  }
  
  .mac-inter-one-way-result-page .departure-time {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.50px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-20 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    padding: 0px 15px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-168 {
    align-items: center;
    align-self: stretch;
    background-color: var(--pumice);
    border: 2px solid;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    margin-bottom: -4.00px;
    margin-left: -4.00px;
    margin-right: -4.00px;
    margin-top: -4.00px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-175-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 2px;
    margin-left: -2.00px;
    margin-top: -2.00px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-1-4 {
    align-items: center;
    background-color: var(--white);
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 5px 3px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-1-5 {
    height: 46.6px;
    position: relative;
    width: 46.6px;
  }
  
  .mac-inter-one-way-result-page .frame-1-6 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 1px;
    justify-content: center;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-17 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .before-6 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .am {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-168-1 {
    align-items: center;
    background-color: #fbfbfb;
    border-radius: 35.3px;
    display: flex;
    flex-direction: column;
    gap: 5.04px;
    height: 46.6px;
    justify-content: center;
    padding: 12.61px;
    position: relative;
    width: 46.6px;
  }
  
  .mac-inter-one-way-result-page .frame-195 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .group-182 {
    height: 21px;
    left: -4px;
    position: absolute;
    top: -4px;
    width: 21px;
  }
  
  .mac-inter-one-way-result-page .group-180 {
    background-color: var(--white);
    border: 1px solid;
    border-color: var(--storm-dust);
    border-radius: 6.66px;
    height: 13px;
    left: 0;
    position: absolute;
    top: 0;
    width: 13px;
  }
  
  .mac-inter-one-way-result-page .number-7 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .text-1-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .number-8 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .p-m {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .number-9 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .text-2-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .number-10 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .after-6 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .pm {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .arrival-time {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.50px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .before-6-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-195-1 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .number-11 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .text-3-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .number-12 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .number-13 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .text-4-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .number-14 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .after-6-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .pm-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .layover-duration {
    margin-top: -3.00px;
  }
  
  .mac-inter-one-way-result-page .frame-202-9 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .x2h-10m-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.50px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .x5h-10m-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.50px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .layover-airports {
    margin-top: -1.50px;
  }
  
  .mac-inter-one-way-result-page .group-53 {
    background-color: var(--white);
    border: 1px solid;
    border-color: var(--stack);
    height: 21.4px;
    position: relative;
    width: 21px;
  }
  
  .mac-inter-one-way-result-page .check-1 {
    height: 15px;
    left: 3px;
    position: absolute;
    top: 2px;
    width: 14px;
  }
  
  .mac-inter-one-way-result-page .frame-2-1 {
    align-items: flex-start;
    display: inline-flex;
    gap: 5px;
  }
  
  .mac-inter-one-way-result-page .frame-201-3 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 1px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .place {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .number-15 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .frame-168-3 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .addis-ababa {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .number-16 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .frame-172-6 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .name {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .number-17 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .frame-203 {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 3px;
    height: 21px;
    justify-content: flex-end;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .plus {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .address-1 {
    margin-top: -0.50px;
  }
  
  .mac-inter-one-way-result-page .frame-127 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    padding: 30px 0px 25px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .near-by-arrival-airports {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.50px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .number-18 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .frame-168-4 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .addis-ababa-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .number-19 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .frame-172-7 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .name-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .number-20 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .address-2 {
    margin-top: -0.50px;
  }
  
  .mac-inter-one-way-result-page .frame-117 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    padding: 30px 15px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-201-11 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-202-10 {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 10px;
    height: 21px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .airlines {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.50px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-6 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-17-1 {
    align-items: flex-start;
    gap: 10px;
  }
  
  .mac-inter-one-way-result-page .frame-7 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    justify-content: space-between;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-20-1 {
    align-items: flex-start;
    gap: 8px;
  }
  
  .mac-inter-one-way-result-page .unnamed-1 {
    height: 18.17px;
    width: 18.17px;
  }
  
  .mac-inter-one-way-result-page .air-india {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .number-21 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .frame-172-8 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .x1682263712-xyf4hcdqcxe517q8c4xf-3 {
    height: 18.86px;
    position: relative;
    width: 19px;
  }
  
  .mac-inter-one-way-result-page .color {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .number-22 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .frame-175-5 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .spicejet-1 {
    height: 17.12px;
    object-fit: cover;
    position: relative;
    width: 19px;
  }
  
  .mac-inter-one-way-result-page .spice-jet {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .number-23 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .vistara_-logo-1 {
    height: 19px;
    position: relative;
    width: 19px;
  }
  
  .mac-inter-one-way-result-page .vistara {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .number-24 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .address-3 {
    margin-top: -0.50px;
  }
  
  .mac-inter-one-way-result-page .frame-168-5::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  
  .mac-inter-one-way-result-page .frame-168-5 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 15px;
    height: 1707px;
    margin-right: -5.00px;
    overflow: hidden;
    overflow-y: scroll;
    padding: 18px 0px 0px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-257 {
    align-items: flex-start;
    background-color: var(--white);
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 7px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-169-3 {
    align-items: flex-end;
    background-color: var(--mystic);
    box-shadow: 0px 3px 4px #00000033;
    display: flex;
    flex: 0 0 auto;
    gap: 2px;
    justify-content: center;
    padding: 0px 34px;
    position: relative;
    width: 1032px;
  }
  
  .mac-inter-one-way-result-page .frame-110 {
    height: 54px;
    left: 1000px;
    position: absolute;
    top: 6px;
    width: 32px;
  }
  
  .mac-inter-one-way-result-page .frame-111 {
    height: 54px;
    left: 0;
    position: absolute;
    top: 6px;
    width: 33px;
  }
  
  .mac-inter-one-way-result-page .frame-103-4::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  
  .mac-inter-one-way-result-page .frame-103-4 {
    align-items: flex-end;
    background-color: var(--mystic);
    display: flex;
    flex: 1;
    flex-grow: 1;
    overflow: hidden;
    overflow-x: scroll;
    padding: 0px 2px 0px 1px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-1-7 {
    align-items: center;
    background-color: var(--white);
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    padding: 10px 18px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .tue-30-jan {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .vector-2 {
    height: 9.72px;
    position: relative;
    width: 7.03px;
  }
  
  .mac-inter-one-way-result-page .number-25 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .tue-30-jan-1 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .number-26 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .tue-30-jan-2 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .number-27 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .frame-106 {
    align-items: center;
    background-color: var(--white);
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-color: var(--endeavour);
    box-shadow: 0px 0px 10px 2px #0057b733;
    display: flex;
    flex-direction: column;
    gap: 4.4px;
    height: 59.78px;
    padding: 10px 18px;
    position: relative;
    width: 130.67px;
  }
  
  .mac-inter-one-way-result-page .tue-30-jan-3 {
    color: var(--endeavour);
    font-family: var(--font-family-proxima_nova-regular);
    font-size: 18px;
    font-weight: 400;
    margin-top: -2.00px;
  }
  
  .mac-inter-one-way-result-page .frame-169-4 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 2.36px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .vector-5 {
    height: 10.44px;
    position: relative;
    width: 7.55px;
  }
  
  .mac-inter-one-way-result-page .number-28 {
    margin-top: -1.18px;
  }
  
  .mac-inter-one-way-result-page .tue-30-jan-4 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .number-29 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .tue-30-jan-5 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .number-30 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .tue-30-jan-6 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .number-31 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .tue-30-jan-7 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .number-32 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .tue-30-jan-8 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .number-33 {
    margin-top: -1.00px;
  }
  
  .mac-inter-one-way-result-page .frame-260-1 {
    align-items: center;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    gap: 20px;
    justify-content: flex-end;
    padding: 8px 15px 11px;
    position: relative;
    width: 1032px;
  }
  
  .mac-inter-one-way-result-page .search-bar-hover {
    align-items: center;
    align-self: stretch;
    background-color: var(--white);
    border-bottom-style: solid;
    border-bottom-width: 1.28px;
    border-color: var(--stack);
    display: flex;
    gap: 7px;
    padding: 8px 11px;
    position: relative;
    width: 245px;
  }
  
  .mac-inter-one-way-result-page .search {
    height: 18.58px;
    position: relative;
    width: 18.58px;
  }
  
  .mac-inter-one-way-result-page .sort-airline-by-name-code {
    color: var(--storm-dust);
    font-family: var(--font-family-proxima_nova-regular);
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-259 {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-8 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 7px;
    justify-content: center;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-257-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    justify-content: flex-end;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-263 {
    align-items: center;
    background-color: #e7f2ff;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-color: var(--endeavour);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 12px;
    padding: 8px 18px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-259-1 {
    background-color: var(--endeavour);
    border-radius: 1px;
    gap: 8.81px;
    padding: 4px 6px;
  }
  
  .mac-inter-one-way-result-page .group-259 {
    height: 14.43px;
    position: relative;
    width: 16.95px;
  }
  
  .mac-inter-one-way-result-page .overlap-group-4 {
    height: 14px;
    position: relative;
    width: 17px;
  }
  
  .mac-inter-one-way-result-page .vector-116 {
    height: 13px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 15px;
  }
  
  .mac-inter-one-way-result-page .ellipse-42 {
    height: 1px;
    left: 4px;
    position: absolute;
    top: 6px;
    width: 1px;
  }
  
  .mac-inter-one-way-result-page .ellipse-43 {
    border: 0.57px solid;
    border-color: var(--white);
    border-radius: 4.08px;
    height: 8px;
    left: 9px;
    position: absolute;
    top: 0;
    width: 8px;
  }
  
  .mac-inter-one-way-result-page .vector-6 {
    height: 4px;
    left: 12px;
    position: absolute;
    top: 2px;
    width: 3px;
  }
  
  .mac-inter-one-way-result-page .cheapest {
    color: var(--eerie-black);
    font-family: var(--font-family-proxima_nova-semibold);
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-259-2 {
    background-color: #f2f2f2;
    gap: 12px;
    padding: 8px 18px;
  }
  
  .mac-inter-one-way-result-page .frame-259-3 {
    background-color: var(--pumice);
    border-radius: 1px;
    flex-direction: column;
    gap: 10px;
    padding: 6px 3px;
  }
  
  .mac-inter-one-way-result-page .group-206-2 {
    height: 10.77px;
    position: relative;
    width: 19.74px;
  }
  
  .mac-inter-one-way-result-page .overlap-group-5 {
    align-items: flex-start;
    border: 0.81px solid;
    border-color: var(--white);
    border-radius: 6.19px;
    display: flex;
    height: 12px;
    justify-content: flex-end;
    left: 8px;
    min-width: 12px;
    padding: 2.5px 5.0px;
    position: absolute;
    top: -1px;
  }
  
  .mac-inter-one-way-result-page .vector-50 {
    height: 5px;
    width: 2px;
  }
  
  .mac-inter-one-way-result-page .group-258 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 3px;
    left: 0;
    min-height: 7px;
    position: absolute;
    top: 3px;
    width: 6px;
  }
  
  .mac-inter-one-way-result-page .line-33 {
    height: 1px;
    margin-left: 2.14px;
    width: 4px;
  }
  
  .mac-inter-one-way-result-page .line-32 {
    height: 1px;
    width: 6px;
  }
  
  .mac-inter-one-way-result-page .line-34 {
    height: 1px;
    margin-left: 2.36px;
    width: 4px;
  }
  
  .mac-inter-one-way-result-page .fastest {
    color: var(--gravel);
    font-family: var(--font-family-proxima_nova-semibold);
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-2-2 {
    align-items: center;
    background-color: var(--white);
    border: 1px solid;
    border-color: var(--alto);
    display: flex;
    justify-content: space-between;
    padding: 1px;
    width: 1032px;
  }
  
  .mac-inter-one-way-result-page .frame-201-4 {
    align-items: flex-end;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-17-2 {
    align-items: center;
    justify-content: space-between;
    padding: 32px 20px 22.5px;
  }
  
  .mac-inter-one-way-result-page .frame-104 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 7px;
    justify-content: center;
    position: relative;
    width: 121px;
  }
  
  .mac-inter-one-way-result-page .frame-104-1 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 7px;
    justify-content: center;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-103 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-173 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    position: relative;
    width: 25px;
  }
  
  .mac-inter-one-way-result-page .frame-223 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 1px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-172 {
    align-items: flex-start;
    align-self: stretch;
    background-image: url(../img/frame-172@2x.png);
    background-position: 50% 50%;
    background-size: cover;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 3.31px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-173-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 3.07px;
    height: 12px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .unnamed-2 {
    height: 12px;
    width: 12px;
  }
  
  .mac-inter-one-way-result-page .frame-174 {
    align-items: flex-start;
    align-self: stretch;
    background-image: url(../img/frame-174@2x.png);
    background-position: 50% 50%;
    background-size: cover;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 3.13px;
    justify-content: flex-end;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-224 {
    align-items: flex-start;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 3.13px;
    justify-content: flex-end;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-168-2 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 3px;
    justify-content: flex-end;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-173-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 2px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .indigo-spi {
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .in-123 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-214 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 7px;
    height: 32px;
    justify-content: center;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-202 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: -1.00px;
    position: relative;
    width: 474px;
  }
  
  .mac-inter-one-way-result-page .frame-170-3 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 9px;
    justify-content: center;
    position: relative;
    width: 106px;
  }
  
  .mac-inter-one-way-result-page .text-5 {
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-242 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    margin-right: -52.00px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .trv {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-2 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-1-8 {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-9 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .line {
    height: 1px;
    object-fit: cover;
    position: relative;
    width: 19px;
  }
  
  .mac-inter-one-way-result-page .frame-202-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    justify-content: center;
    padding: 0px 0px 0px 1.5px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .group-206 {
    height: 10.6px;
    position: relative;
    width: 10.6px;
  }
  
  .mac-inter-one-way-result-page .overlap-group {
    align-items: flex-start;
    border: 0.8px solid;
    border-color: var(--eerie-black);
    border-radius: 6.1px;
    display: flex;
    height: 12px;
    justify-content: flex-end;
    left: -1px;
    min-width: 12px;
    padding: 2.5px 4.9px;
    position: relative;
    top: -1px;
  }
  
  .mac-inter-one-way-result-page .x28h-40m {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.20px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-10 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: center;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-175-3 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .address-4 {
    margin-top: -1.00px;
    text-align: center;
  }
  
  .mac-inter-one-way-result-page .frame-214-1 {
    align-items: center;
    align-self: stretch;
    gap: 10px;
    justify-content: center;
    margin-right: -0.20px;
    padding: 1px 0px 0px;
  }
  
  .mac-inter-one-way-result-page .frame-203-1 {
    border-color: var(--eerie-black);
    border-left-style: solid;
    border-left-width: 0.8px;
    height: 10px;
    position: relative;
    width: 1px;
  }
  
  .mac-inter-one-way-result-page .frame-202-2 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 1px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-11 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-177-1 {
    flex: 0 0 auto;
    margin-right: -1.00px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-169-1 {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 9px;
    justify-content: center;
    position: relative;
    width: 106px;
  }
  
  .mac-inter-one-way-result-page .frame-12 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    justify-content: flex-end;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .text-6 {
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-24 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    margin-left: -52.00px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .place-3 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-202-3 {
    align-items: flex-start;
    border-radius: 14px;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .x1-day {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-104-2 {
    align-items: flex-end;
    display: flex;
    gap: 5px;
    width: 218px;
  }
  
  .mac-inter-one-way-result-page .frame-202-4 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-end;
    margin-left: -1.80px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-103-1 {
    align-items: center;
    background-color: var(--white);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 15px;
    justify-content: center;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-172-1 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 9px;
    justify-content: flex-end;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-169-2 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-end;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-1-9 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-169-5 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .text {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -2.74px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .per-adult {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-103-2 {
    align-items: center;
    align-self: stretch;
    background-color: var(--white);
    border: 1px solid;
    border-color: var(--endeavour);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: center;
    margin-bottom: -1.00px;
    margin-right: -1.00px;
    margin-top: -1.00px;
    padding: 10.5px 33px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .book {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-171 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 20px;
    justify-content: center;
    margin-bottom: -0.50px;
    margin-left: -0.50px;
    margin-right: -0.50px;
    padding: 10px 20px 15px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .partially-refundable {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-203-2 {
    align-items: flex-end;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: flex-end;
    padding: 0px 0px 2px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .flight-details {
    margin-top: -1.00px;
    text-align: right;
  }
  
  .mac-inter-one-way-result-page .unnamed-3 {
    height: 12px;
    width: 12px;
  }
  
  .mac-inter-one-way-result-page .text-8 {
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-4 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .address-5 {
    margin-top: -1.00px;
    text-align: center;
  }
  
  .mac-inter-one-way-result-page .text-9 {
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-5 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-202-5 {
    align-items: flex-start;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    position: relative;
    width: 34px;
  }
  
  .mac-inter-one-way-result-page .frame-169-6 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-231 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: flex-end;
    padding: 2.75px 20px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-230 {
    align-items: center;
    background-color: var(--mystic);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: flex-end;
    padding: 0px 11px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-203-3 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    padding: 4px 0px 7px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .group-227 {
    height: 17.5px;
    position: relative;
    width: 21.14px;
  }
  
  .mac-inter-one-way-result-page .group-container {
    height: 18px;
    position: relative;
    width: 21px;
  }
  
  .mac-inter-one-way-result-page .group-226 {
    align-items: flex-start;
    display: flex;
    height: 9px;
    justify-content: flex-end;
    left: 2px;
    min-width: 18px;
    position: absolute;
    top: 4px;
    transform: rotate(-26.17deg);
  }
  
  .mac-inter-one-way-result-page .overlap-group-1 {
    height: 24px;
    margin-top: -7.29px;
    position: relative;
    width: 26px;
  }
  
  .mac-inter-one-way-result-page .vector-90 {
    height: 16px;
    left: 3px;
    position: absolute;
    top: 4px;
    transform: rotate(26.17deg);
    width: 21px;
  }
  
  .mac-inter-one-way-result-page .group-224 {
    align-items: center;
    display: flex;
    gap: 2px;
    height: 8px;
    left: 10px;
    min-width: 11px;
    padding: 0.4px 0;
    position: absolute;
    top: 8px;
  }
  
  .mac-inter-one-way-result-page .line-65 {
    align-self: flex-end;
    height: 7px;
    margin-left: -2px;
    transform: rotate(26.17deg);
    width: 3px;
  }
  
  .mac-inter-one-way-result-page .text-1 {
    margin-bottom: 0.17px;
    min-height: 5px;
    min-width: 6px;
    text-align: right;
    white-space: nowrap;
  }
  
  .mac-inter-one-way-result-page .group-225-1 {
    background-image: url(../img/vector-90-1.svg);
  }
  
  .mac-inter-one-way-result-page .frame-223-1 {
    height: 5px;
    margin-top: 0.02px;
    position: relative;
    width: 2px;
  }
  
  .mac-inter-one-way-result-page .line-65-1 {
    align-self: flex-end;
    height: 8px;
    margin-left: 1px;
    width: 1px;
  }
  
  .mac-inter-one-way-result-page .text-2 {
    margin-bottom: 0.6px;
    margin-left: 2px;
    min-height: 5px;
    min-width: 6px;
    text-align: right;
    white-space: nowrap;
  }
  
  .mac-inter-one-way-result-page .frame-211 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-13 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .use-code-stdeals-for {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .text-3 {
    -webkit-text-stroke: 1px var(--black);
    margin-left: -1.00px;
    margin-top: -2.00px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .get-150-off-on-upi-payments {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-104-4 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 7px;
    justify-content: center;
  }
  
  .mac-inter-one-way-result-page .frame-103-5 {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
    width: 121px;
  }
  
  .mac-inter-one-way-result-page .frame-174-3 {
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
  }
  
  .mac-inter-one-way-result-page .frame-172-9 {
    align-items: flex-start;
    background-image: url(../img/frame-172@2x.png);
    background-position: 50% 50%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    gap: 3.31px;
    height: 12px;
    position: relative;
    width: 13px;
  }
  
  .mac-inter-one-way-result-page .frame-224-3 {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 1px;
    justify-content: flex-end;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-173-5 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 2.69px;
    height: 10.5px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .unnamed-4 {
    height: 10.5px;
    width: 10.5px;
  }
  
  .mac-inter-one-way-result-page .text-14 {
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .blr {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-6 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .group-206-1 {
    height: 10.45px;
    position: relative;
    width: 10.45px;
  }
  
  .mac-inter-one-way-result-page .overlap-group-2 {
    align-items: flex-start;
    border: 0.79px solid;
    border-color: var(--eerie-black);
    border-radius: 6.01px;
    display: flex;
    height: 12px;
    justify-content: flex-end;
    left: -1px;
    min-width: 12px;
    padding: 2.4px 4.9px;
    position: relative;
    top: -1px;
  }
  
  .mac-inter-one-way-result-page .x28h-40m-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.28px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .address-6 {
    margin-top: -1.00px;
    text-align: center;
  }
  
  .mac-inter-one-way-result-page .text-15 {
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .mel {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-7 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-202-6 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-end;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-169-7 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .group-224-1 {
    align-items: center;
    display: flex;
    gap: 2px;
    height: 8px;
    left: 10px;
    min-width: 11px;
    position: absolute;
    top: 8px;
  }
  
  .mac-inter-one-way-result-page .line-65-2 {
    height: 7px;
    margin-left: -2px;
    transform: rotate(26.17deg);
    width: 3px;
  }
  
  .mac-inter-one-way-result-page .group-225-2 {
    background-image: url(../img/vector-90-3.svg);
  }
  
  .mac-inter-one-way-result-page .non-refundable {
    color: var(--crimson);
    font-family: var(--font-family-proximanova-medium-☞);
    font-size: var(--font-size-18);
    font-weight: 400;
  }
  
  .mac-inter-one-way-result-page .frame-172-2 {
    align-items: flex-start;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-172-3 {
    align-items: flex-start;
    display: inline-flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 3.31px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .png-transparent-luft {
    height: 18.1px;
    margin-bottom: -0.60px;
    object-fit: cover;
    position: relative;
    width: 18.1px;
  }
  
  .mac-inter-one-way-result-page .frame-173-3 {
    align-items: flex-end;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 3.13px;
    justify-content: center;
    position: relative;
    width: 25px;
  }
  
  .mac-inter-one-way-result-page .turkish-airlines-symbol-1 {
    height: 16.07px;
    position: relative;
    width: 17.07px;
  }
  
  .mac-inter-one-way-result-page .text-20 {
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-8 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .address-7 {
    margin-top: -1.00px;
    text-align: center;
  }
  
  .mac-inter-one-way-result-page .text-21 {
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-9 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-169-8 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .group-225-3 {
    background-image: url(../img/vector-90-5.svg);
  }
  
  .mac-inter-one-way-result-page .refundable {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-201-5 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-173-4 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    width: 25px;
  }
  
  .mac-inter-one-way-result-page .frame-172-4 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 3.31px;
    margin-right: -0.17px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-172-5 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 6.95px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .unnamed {
    height: 25.17px;
    object-fit: cover;
    position: relative;
    width: 25.17px;
  }
  
  .mac-inter-one-way-result-page .text-26 {
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-10 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .address-8 {
    margin-top: -1.00px;
    text-align: center;
  }
  
  .mac-inter-one-way-result-page .tashkent {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .text-27 {
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-11 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-169-9 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-115 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: flex-end;
    padding: 0px 20px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-231-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: flex-end;
    padding: 2.75px 0px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .group-225-4 {
    background-image: url(../img/vector-90-7.svg);
  }
  
  .mac-inter-one-way-result-page .text-32 {
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-12 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-172-10 {
    align-items: center;
    display: inline-flex;
    gap: 4px;
    height: 16px;
    justify-content: center;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .non-stop {
    margin-top: -1.00px;
    text-align: center;
  }
  
  .mac-inter-one-way-result-page .text-33 {
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-13 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-169-10 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .group-225-5 {
    background-image: url(../img/vector-90-9.svg);
  }
  
  .mac-inter-one-way-result-page .frame-221-1 {
    align-items: center;
    background-color: var(--white);
    border: 1px solid;
    border-color: #d5dee7;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 1px;
    position: relative;
    width: 1032px;
  }
  
  .mac-inter-one-way-result-page .frame-175-4 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 32px 20px 26px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .text-38 {
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-14 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .address-9 {
    margin-top: -1.00px;
    text-align: center;
  }
  
  .mac-inter-one-way-result-page .text-39 {
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-15 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-169-11 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .group-225-6 {
    background-image: url(../img/vector-90-39.svg);
  }
  
  .mac-inter-one-way-result-page .frame-174-4 {
    background-color: var(--gallery);
    border-color: #d5dee7;
    border-top-style: solid;
    border-top-width: 0.5px;
    flex: 0 0 auto;
    gap: 20px;
    margin-bottom: -0.50px;
    margin-left: -0.50px;
    margin-right: -0.50px;
    padding: 8.57px 20px;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-207 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 20px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-220 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    padding: 1px 0px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-214-2 {
    align-items: flex-start;
    border: 0.5px solid;
    border-color: var(--eerie-black);
    border-radius: 1px;
    flex-direction: column;
    gap: 0.13px;
    padding: 3.5px 4px;
  }
  
  .mac-inter-one-way-result-page .frame-213 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-213-1 {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 1.2px;
  }
  
  .mac-inter-one-way-result-page .group {
    height: 5.65px;
    position: relative;
    width: 4.93px;
  }
  
  .mac-inter-one-way-result-page .line-59 {
    height: 1px;
    margin-bottom: -1.00px;
    position: relative;
    width: 5px;
  }
  
  .mac-inter-one-way-result-page .transit-visa-required {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-232-2 {
    border: 1px solid;
    border-color: #cfe5ff;
    justify-content: space-between;
    padding: 1px;
    width: 1032px;
  }
  
  .mac-inter-one-way-result-page .frame-175-6 {
    align-items: center;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 32px 20px 26px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .text-43 {
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-16 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .address-10 {
    margin-top: -1.00px;
    text-align: center;
  }
  
  .mac-inter-one-way-result-page .text-44 {
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-17 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-169-12 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-115-2 {
    background-color: var(--white);
  }
  
  .mac-inter-one-way-result-page .group-225-7 {
    background-image: url(../img/vector-90-13.svg);
  }
  
  .mac-inter-one-way-result-page .frame-174-5 {
    background-color: var(--gallery);
    border-color: #f1f8ff;
    border-top-style: solid;
    border-top-width: 0.5px;
    flex: 0 0 auto;
    gap: 20px;
    margin-bottom: -0.50px;
    margin-left: -0.50px;
    margin-right: -0.50px;
    padding: 8.58px 20px;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .text-48 {
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-18 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .address-11 {
    margin-top: -1.00px;
    text-align: center;
  }
  
  .mac-inter-one-way-result-page .text-49 {
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-19 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-169-13 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .group-225-8 {
    background-image: url(../img/vector-90-15.svg);
  }
  
  .mac-inter-one-way-result-page .frame-174-1 {
    background-color: var(--gallery);
    border-color: var(--pumice);
    border-top-style: solid;
    border-top-width: 0.5px;
    flex: 0 0 auto;
    gap: 20px;
    margin-bottom: -0.50px;
    margin-left: -0.50px;
    margin-right: -0.50px;
    padding: 7px 20px;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-207-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 15px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-208 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    padding: 0px 0px 1px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .text-53 {
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .stfares-icon-5-1 {
    height: 19px;
    position: relative;
    width: 19px;
  }
  
  .mac-inter-one-way-result-page .self-transfer-baggage {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .text-54 {
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-20 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .address-12 {
    margin-top: -1.00px;
    text-align: center;
  }
  
  .mac-inter-one-way-result-page .text-55 {
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-21 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-169-14 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .group-225-9 {
    background-image: url(../img/vector-90-15@2x.png);
  }
  
  .mac-inter-one-way-result-page .text-60 {
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .text-4 {
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .group-222 {
    height: 14px;
    position: relative;
    width: 21px;
  }
  
  .mac-inter-one-way-result-page .frame-container {
    height: 14px;
    position: relative;
    width: 21px;
  }
  
  .mac-inter-one-way-result-page .frame-220-1 {
    align-items: flex-end;
    background-color: var(--gallery);
    display: flex;
    height: 14px;
    justify-content: flex-end;
    left: 0;
    min-width: 17px;
    padding: 0 0.4px;
    position: absolute;
    top: 0;
  }
  
  .mac-inter-one-way-result-page .frame-220-2 {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: -0.02px;
    position: relative;
    width: 16px;
  }
  
  .mac-inter-one-way-result-page .rectangle-59 {
    border-radius: 0.79px 0.79px 0px 0px;
    height: 2.37px;
    width: 6.85px;
  }
  
  .mac-inter-one-way-result-page .frame-220-3 {
    align-items: flex-start;
    align-self: stretch;
    border: 0.4px solid;
    border-color: var(--black);
    border-radius: 0.92px;
    display: flex;
    flex: 0 0 auto;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-220-4 {
    position: relative;
    width: 2.77px;
  }
  
  .mac-inter-one-way-result-page .frame-220-5 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 2.5px;
    padding: 3px 1px 1.9px 0px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .line-6-2 {
    align-self: stretch;
    height: 1px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .line-62 {
    align-self: stretch;
    height: 1px;
    margin-bottom: -1.00px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-224-1 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    padding: 0px 2px;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .rectangle-60 {
    border-radius: 0px 0px 0.79px 0.79px;
    height: 1.37px;
    width: 1px;
  }
  
  .mac-inter-one-way-result-page .rectangle-61 {
    border-radius: 0px 0px 0.79px 0.79px;
    height: 1.37px;
    margin-right: -0.50px;
    width: 1px;
  }
  
  .mac-inter-one-way-result-page .frame-221 {
    height: 7px;
    left: 13px;
    position: absolute;
    top: 4px;
    width: 8px;
  }
  
  .mac-inter-one-way-result-page .recheck-in-baggage {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .text-62 {
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-22 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .address-13 {
    margin-top: -1.00px;
    text-align: center;
  }
  
  .mac-inter-one-way-result-page .text-63 {
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-23 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-169-15 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .group-225-10 {
    background-image: url(../img/vector-90-19@2x.png);
  }
  
  .mac-inter-one-way-result-page .text-68 {
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-220-6 {
    align-items: flex-end;
    background-color: #e5e5e5;
    display: flex;
    height: 14px;
    justify-content: flex-end;
    left: 0;
    min-width: 17px;
    padding: 0 0.4px;
    position: absolute;
    top: 0;
  }
  
  .mac-inter-one-way-result-page .frame-219 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .terminal-change {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .text-71 {
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-24 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .address-14 {
    margin-top: -1.00px;
    text-align: center;
  }
  
  .mac-inter-one-way-result-page .text-72 {
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-25 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-169-16 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .group-225-11 {
    background-image: url(../img/vector-90-9@2x.png);
  }
  
  .mac-inter-one-way-result-page .frame-174-2 {
    background-color: var(--gallery);
    border-color: var(--pumice);
    border-top-style: solid;
    border-top-width: 0.5px;
    flex: 0 0 auto;
    gap: 15px;
    margin-bottom: -0.50px;
    margin-left: -0.50px;
    margin-right: -0.50px;
    padding: 2px 20px;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-224-2 {
    align-items: center;
    display: inline-flex;
    gap: 6px;
    padding: 6px 15px 6px 0px;
  }
  
  .mac-inter-one-way-result-page .frame-228 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .st {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .connect {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-228-1 {
    align-self: stretch;
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-230-1 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 15px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-203-4 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: flex-end;
    padding: 0px 0px 2px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .text-77 {
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-26 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .address-15 {
    margin-top: -1.00px;
    text-align: center;
  }
  
  .mac-inter-one-way-result-page .text-78 {
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-27 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-169-17 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .group-225 {
    align-items: center;
    background-image: url(../img/vector-90-11@2x.png);
    background-size: 100% 100%;
    display: flex;
    height: 9px;
    left: 0;
    min-width: 18px;
    padding: 0.6px 2.8px;
    position: absolute;
    top: 8px;
  }
  
  .mac-inter-one-way-result-page .text-83 {
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .text-84 {
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-28 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .address-16 {
    margin-top: -1.00px;
    text-align: center;
  }
  
  .mac-inter-one-way-result-page .text-85 {
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-29 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-169-18 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .text-90 {
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-236 {
    align-items: center;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .text-92 {
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-30 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .address-17 {
    margin-top: -1.00px;
    text-align: center;
  }
  
  .mac-inter-one-way-result-page .text-93 {
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .place-31 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-169-19 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-174-6 {
    background-color: var(--gallery);
    border-color: var(--pumice);
    border-top-style: solid;
    border-top-width: 0.5px;
    flex: 0 0 auto;
    gap: 15px;
    margin-left: -0.50px;
    margin-right: -0.50px;
    padding: 2px 0px;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-223-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    padding: 6px 15px 6px 20px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .text-97 {
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-219-1 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .terminal-change-1 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-203-5 {
    align-items: flex-end;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: flex-end;
    padding: 0px 20px 2px 0px;
    position: relative;
  }
  

  
  .mac-inter-one-way-result-page .footer {
    align-items: center;
    align-self: stretch;
    background-color: var(--white);
    border-bottom-style: none;
    border-color: #0067b833;
    border-left-style: none;
    border-right-style: none;
    border-top-style: solid;
    border-top-width: 1px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    padding: 50px 85px 20px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .footer-columns {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-66 {
    height: 261px;
    position: relative;
    width: 312px;
  }
  
  .mac-inter-one-way-result-page .frame-65 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .get-latest-updates-offers {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    width: 215px;
  }
  
  .mac-inter-one-way-result-page .group-70 {
    height: 28px;
    margin-right: -2.00px;
    position: relative;
    width: 217px;
  }
  
  .mac-inter-one-way-result-page .overlap-group-6 {
    align-items: flex-start;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--eerie-black);
    display: flex;
    height: 28px;
    min-width: 215px;
    padding: 2px 0;
  }
  
  .mac-inter-one-way-result-page .enter-email-address {
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin-left: -1px;
    white-space: nowrap;
  }
  
  .mac-inter-one-way-result-page .frame-71 {
    align-items: flex-start;
    background-color: var(--deep-sapphire);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 5px 16px 7px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .subscribe {
    color: var(--white);
    font-family: var(--font-family-segoe_ui-semibold);
    font-size: var(--font-size-20);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .group-79 {
    height: 36px;
    position: relative;
    width: 174px;
  }
  
  .mac-inter-one-way-result-page .group-container-1 {
    align-items: flex-start;
    display: flex;
    gap: 10px;
    min-width: 174px;
  }
  
  .mac-inter-one-way-result-page .group-76 {
    align-items: flex-start;
    background-color: var(--white);
    border-radius: 18px;
    display: flex;
    height: 36px;
    min-width: 36px;
    padding: 9px 13px;
  }
  
  .mac-inter-one-way-result-page .vector-7 {
    height: 17px;
    width: 9px;
  }
  
  .mac-inter-one-way-result-page .group-74 {
    align-items: flex-end;
    background-color: var(--white);
    border-radius: 18px;
    display: flex;
    height: 36px;
    min-width: 36px;
    padding: 8.0px 8px;
  }
  
  .mac-inter-one-way-result-page .vector-9 {
    height: 19px;
    width: 19px;
  }
  
  .mac-inter-one-way-result-page .group-77 {
    align-items: flex-end;
    background-color: var(--white);
    border-radius: 18px;
    display: flex;
    height: 36px;
    justify-content: flex-end;
    min-width: 36px;
    padding: 9.7px 7.9px;
  }
  
  .mac-inter-one-way-result-page .vector-10 {
    height: 16px;
    width: 20px;
  }
  
  .mac-inter-one-way-result-page .group-78 {
    align-items: center;
    background-color: var(--white);
    border-radius: 18px;
    display: flex;
    height: 36px;
    justify-content: flex-end;
    min-width: 36px;
    padding: 0 6px;
  }
  
  .mac-inter-one-way-result-page .gle {
    height: 22px;
    width: 22px;
  }
  
  .mac-inter-one-way-result-page .group-72 {
    height: 31.52px;
    position: relative;
    width: 210px;
  }
  
  .mac-inter-one-way-result-page .img2 {
    height: 32px;
    left: 110px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100px;
  }
  
  .mac-inter-one-way-result-page .img1 {
    height: 32px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100px;
  }
  
  .mac-inter-one-way-result-page .frame-67 {
    align-self: stretch;
    position: relative;
    width: 282px;
  }
  
  .mac-inter-one-way-result-page .group-65 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 225px;
    width: 170px;
  }
  
  .mac-inter-one-way-result-page .our-services {
    letter-spacing: 0;
    line-height: normal;
    margin-left: 5px;
    min-height: 27px;
  }
  
  .mac-inter-one-way-result-page .group-64 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 182px;
    width: 168px;
  }
  
  .mac-inter-one-way-result-page .group-61 {
    align-items: flex-start;
    display: flex;
    gap: 5px;
    min-width: 170px;
  }
  
  .mac-inter-one-way-result-page .group-62 {
    align-items: flex-start;
    display: flex;
    gap: 5px;
    min-width: 146px;
  }
  
  .mac-inter-one-way-result-page .domestic-flights {
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 1px;
    white-space: nowrap;
  }
  
  .mac-inter-one-way-result-page .group-63 {
    align-items: flex-start;
    display: flex;
    gap: 5px;
    min-width: 131px;
  }
  
  .mac-inter-one-way-result-page .hotels-in-india {
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 1px;
    white-space: nowrap;
  }
  
  .mac-inter-one-way-result-page .group-64-1 {
    align-items: flex-start;
    display: flex;
    gap: 5px;
    min-width: 169px;
  }
  
  .mac-inter-one-way-result-page .group-65-1 {
    align-items: flex-start;
    display: flex;
    gap: 5px;
    min-width: 129px;
  }
  
  .mac-inter-one-way-result-page .tour-packages {
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 1px;
    white-space: nowrap;
  }
  
  .mac-inter-one-way-result-page .group-66 {
    align-items: flex-start;
    display: flex;
    gap: 5px;
    min-width: 126px;
  }
  
  .mac-inter-one-way-result-page .special-offers {
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 1px;
    white-space: nowrap;
  }
  
  .mac-inter-one-way-result-page .frame-68 {
    align-self: stretch;
    position: relative;
    width: 265px;
  }
  
  .mac-inter-one-way-result-page .group-65-2 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 161px;
    width: 123px;
  }
  
  .mac-inter-one-way-result-page .quick-links {
    letter-spacing: 0;
    line-height: normal;
    margin-right: 9.0px;
    min-height: 27px;
    min-width: 104px;
  }
  
  .mac-inter-one-way-result-page .group-64-2 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-right: 2.0px;
    min-height: 118px;
    width: 121px;
  }
  
  .mac-inter-one-way-result-page .group-61-1 {
    align-items: flex-start;
    min-width: 72px;
  }
  
  .mac-inter-one-way-result-page .place-34 {
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 1px;
    white-space: nowrap;
  }
  
  .mac-inter-one-way-result-page .group-62-1 {
    align-items: flex-start;
    min-width: 73px;
  }
  
  .mac-inter-one-way-result-page .about {
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 1px;
    white-space: nowrap;
  }
  
  .mac-inter-one-way-result-page .group-63-1 {
    align-items: flex-start;
    min-width: 123px;
  }
  
  .mac-inter-one-way-result-page .x247-support {
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 1px;
    white-space: nowrap;
  }
  
  .mac-inter-one-way-result-page .group-64-3 {
    align-items: flex-start;
    display: flex;
    gap: 5px;
    min-width: 84px;
  }
  
  .mac-inter-one-way-result-page .place-35 {
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 1px;
    white-space: nowrap;
  }
  
  .mac-inter-one-way-result-page .frame-69 {
    height: 263px;
    position: relative;
    width: 286px;
  }
  
  .mac-inter-one-way-result-page .group-65-3 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 24px;
    left: 4px;
    min-height: 83px;
    position: absolute;
    top: 175px;
    width: 239px;
  }
  
  .mac-inter-one-way-result-page .we-accept {
    letter-spacing: 0;
    line-height: normal;
    min-height: 27px;
  }
  
  .mac-inter-one-way-result-page .group-80 {
    align-items: flex-start;
    display: flex;
    gap: 10px;
    margin-left: 3px;
    min-width: 234px;
  }
  
  .mac-inter-one-way-result-page .card {
    height: 32px;
    object-fit: cover;
    width: 51px;
  }
  
  .mac-inter-one-way-result-page .group-66-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 11px;
    left: 0;
    min-height: 140px;
    position: absolute;
    top: 0;
    width: 176px;
  }
  
  .mac-inter-one-way-result-page .get-to-know-us {
    letter-spacing: 0;
    line-height: normal;
    margin-left: 5.08px;
    min-height: 27px;
  }
  
  .mac-inter-one-way-result-page .group-64-4 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 102px;
    width: 174px;
  }
  
  .mac-inter-one-way-result-page .group-61-2 {
    align-items: center;
    min-width: 176px;
  }
  
  .mac-inter-one-way-result-page .angle-right {
    height: 18px;
    width: 22px;
  }
  
  .mac-inter-one-way-result-page .login {
    height: 15px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 1.63px;
    white-space: nowrap;
    width: 146px;
  }
  
  .mac-inter-one-way-result-page .group-62-2 {
    align-items: center;
    min-width: 154px;
  }
  
  .mac-inter-one-way-result-page .enquiry {
    height: 15px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 1.63px;
    white-space: nowrap;
    width: 124px;
  }
  
  .mac-inter-one-way-result-page .group-63-2 {
    align-items: center;
    min-width: 135px;
  }
  
  .mac-inter-one-way-result-page .offers {
    height: 15px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 1.63px;
    white-space: nowrap;
    width: 106px;
  }
  
  .mac-inter-one-way-result-page .group-64-5 {
    align-items: center;
    display: flex;
    gap: 5px;
    min-width: 175px;
  }
  
  .mac-inter-one-way-result-page .blog {
    height: 15px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 1.63px;
    white-space: nowrap;
    width: 145px;
  }
  
  .mac-inter-one-way-result-page .frame-74 {
    align-items: flex-start;
    align-self: stretch;
    border-color: var(--deep-sapphire);
    border-top-style: solid;
    border-top-width: 1px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0px 2px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-73 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .copyright-2020-2024 {
    letter-spacing: 0;
    line-height: 18px;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .designed-by-nspl {
    color: transparent;
    font-family: var(--font-family-segoe_ui-regular);
    font-size: var(--font-size-20);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .span1 {
    color: var(--deep-sapphire);
  }
  
  .mac-inter-one-way-result-page .address-19 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .am-4 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .blr-13 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .book-15 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .color-3 {
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .connect-4 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .flight {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .flight-details-15 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .frame-104-5 {
    flex-direction: column;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-115-3 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 0px 20px 5px;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-17-3 {
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-171-3 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .mac-inter-one-way-result-page .frame-174-7 {
    align-items: center;
    align-self: stretch;
    display: flex;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-2-3 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-20-3 {
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-213-11 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-214-3 {
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-224-4 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-232-3 {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .frame-259-4 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .get-150-off-on-upi-payments-10 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .group-225-12 {
    align-items: center;
    background-size: 100% 100%;
    display: flex;
    height: 9px;
    left: 0;
    min-width: 18px;
    padding: 0.6px 2.8px;
    position: absolute;
    top: 8px;
  }
  
  .mac-inter-one-way-result-page .group-61-3 {
    display: flex;
    gap: 5px;
  }
  
  .mac-inter-one-way-result-page .group-62-3 {
    display: flex;
    gap: 5px;
  }
  
  .mac-inter-one-way-result-page .group-63-3 {
    display: flex;
    gap: 5px;
  }
  
  .mac-inter-one-way-result-page .indigo-spi-13 {
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .international {
    height: 18px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 1px;
    white-space: nowrap;
  }
  
  .mac-inter-one-way-result-page .layover {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .mel-13 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .non {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .number-34 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .p-m-6 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .partially-refundable-13 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .per-adult-15 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .plus-3 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .png-transparent-luft-4 {
    height: 12px;
    object-fit: cover;
    position: relative;
    width: 12px;
  }
  
  .mac-inter-one-way-result-page .recheck-in-baggage-4 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .rectangle {
    border-color: var(--black);
    border-left-style: solid;
    border-left-width: 0.4px;
    border-right-style: solid;
    border-right-width: 0.4px;
    border-top-style: solid;
    border-top-width: 0.4px;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .self-transfer-baggage-6 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .st-4 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .stfares-icon-5-1-6 {
    height: 19px;
    position: relative;
    width: 19px;
  }
  
  .mac-inter-one-way-result-page .tashkent-2 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .tashkent-ist-12 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .text-7 {
    letter-spacing: 0;
    line-height: normal;
  }
  
  .mac-inter-one-way-result-page .transit-visa-required-9 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .trv-4 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  

  
  .mac-inter-one-way-result-page .unnamed-5 {
    object-fit: cover;
    position: relative;
  }
  
  .mac-inter-one-way-result-page .use-code-stdeals-for-14 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .mac-inter-one-way-result-page .vector-2-3 {
    left: 0;
    position: absolute;
    top: 2px;
  }
  
  .mac-inter-one-way-result-page .x1-day-13 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  **/
