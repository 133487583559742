
.result-bar-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 15px;
    padding: 1px;
    min-height: 125.5px;
    position: relative;
    width: 499px;
  }


.result-bar-2.non-bordered-bar {
    /* border: 1.5px solid var(--endeavour); */
  }
.result-bar-2.bordered-bar {
    border: 1.5px solid var(--endeavour);
  }
  
  .result-bar-2 .frame-17-1 {
    align-items: flex-start;
    background-color: var(--white);
    border: 1px solid;
    border-color: var(--alto);
    flex: 1;
    flex-grow: 1;
    margin-bottom: -1.00px;
    margin-left: -1.00px;
    margin-right: -1.00px;
    margin-top: -1.00px;
    /* overflow: hidden; */
  }

  .result-bar-2 .frame-232 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 25px;
    padding: 20px 15px;
    position: relative;
    width: 100%;
  }

  
.result-bar-2 .frame-17-4 {
  display: flex;
  flex-direction: column;
  position: relative;
}

.result-bar-2 .frame-104 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  height: 35px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
}


.result-bar-2 .frame-9 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  position: relative;
}



.result-bar-2 .frame-17-2 {
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    width: 25px;
  }
  
  .result-bar-2 .frame-223 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 1px;
    position: relative;
    width: 100%;
  }
  
  .result-bar-2 .frame-172 {
    align-items: flex-start;
    background-position: 50% 50%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    gap: 3.31px;
    height: 12px;
    position: relative;
    width: 13px;
  }
  
  .result-bar-2 .frame-224 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 1px;
    justify-content: flex-end;
    position: relative;
    width: 100%;
  }
  
  .result-bar-2 .frame-173 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 2.69px;
    height: 10.5px;
    position: relative;
  }
  
  .result-bar-2 .unnamed-1 {
    height: 10.5px;
    width: 10.5px;
  }
  
  .result-bar-2 .frame-224-1 {
    align-items: flex-start;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 3.13px;
    justify-content: flex-end;
    position: relative;
  }
  
  .result-bar-2 .png-transparent-luft {
    height: 12px;
    object-fit: cover;
    position: relative;
    width: 12px;
  }
  
  .result-bar-2 .frame-174 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    /* gap: 3px; */
    justify-content: flex-end;
    position: relative;
    width: 80%;
  }
  
  .result-bar-2 .frame-173-1 {
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;
    gap: 2px;
    width: 100%;
  }
  
  
.result-bar-2 .frame-173-3 {
    display: flex;
    position: relative;
  }

  .result-bar-2 .frame-5 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 2px;
    position: relative;
  }

  
  .result-bar-2 .frame-172-1 {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    justify-content: flex-end;
    position: relative;
  }
  
  .result-bar-2 .frame-169 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
  }
  
  .result-bar-2 .frame-10 {
    display: flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
  }
  
  .result-bar-2 .frame-169-4 {
    flex: 0 0 auto;
    position: relative;
  }

  .result-bar-2 .per-adult-1 {
    white-space: nowrap;
    width: fit-content;
  }
  
  .result-bar-2 .per-adult-16 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
  }

  
.result-bar-2 .frame-11 {
  cursor: pointer;
    /* align-items: center; */
    display: flex;
    gap: 7.81px;
    height: 20.89px;
    position: relative;
    width: 20.5px;
  }
  
  .result-bar-2 .rectangle-17 {
    border: 0.98px solid;
    border-color: var(--pumice);
    border-radius: 50%;
    height: 21px;
    position: relative;
    width: 21px;
  }
  .result-bar-2 .rectangle-17-fill {
    /* position: absolute; */
    /* top: 3.5px; */
    /* left: .3px; */
    /* right: 0px; */
    margin-left: 12%;
    margin-top: 13%;
    /* bottom: 0px; */
    background-color: var(--endeavour);
    border-radius: 50%;
    height: 14px;
    width: 13px;
  }
  
  .result-bar-2 .frame-232-1 {
    /* align-items: center;
    display: flex;
    flex: 0 0 auto; */
    gap: 56px;
    position: relative;
    /* width: 442px; */
    min-width: 465px;
    max-width: 465px;
  }
  
  .result-bar-2 .frame-2-2 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 7px;
    justify-content: center;
    position: relative;
  }
  
  .result-bar-2 .frame-20 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 50px;
    position: relative;
  }

  
.result-bar-2 .frame-1-4 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 106px;
  }

  
.result-bar-2 .frame-12 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    justify-content: flex-end;
    position: relative;
  }
  
  .result-bar-2 .text-397 {
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    width: fit-content;
  }

  
  .result-bar-2 .text-1 {
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
  }
  
  .result-bar-2 .frame-13 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    position: relative;
  }

  .result-bar-2 .bom {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  .result-bar-2 .place {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .result-bar-2 .frame-17-3 {
    align-items: center;
    align-self: stretch;
    gap: 4px;
    justify-content: center;
    width: 121px;
  }
  .result-bar-2 .frame-17-4 {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  
.result-bar-2 .frame-168-2 {
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;
    flex-direction: column;
    width: 100%;
  }

  .result-bar-2 .frame-168-6 {
    display: flex;
    position: relative;
  }

  
.result-bar-2 .frame-170-1 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 7px;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  .result-bar-2 .line {
    height: 1px;
    object-fit: cover;
    position: relative;
    width: 19px;
  }

  .result-bar-2 .line.proxima {
    background-color: var(--pumice);
  }
  .result-bar-2 .line.averta {
    background-color: var(--dark-gold);
  }


  .result-bar-2 .line-straight {
    height: 15px;
    width: 3.5px;
    color: var(--pumice);
  }

  .result-bar-2 .frame-202 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    justify-content: center;
    padding: 0px 0px 0px 1.5px;
    position: relative;
  }
  
  .result-bar-2 .group-206 {
    height: 10.45px;
    margin-top: -7px;
    position: relative;
    width: 10.45px;
  }
  
  .result-bar-2 .overlap-group {
    align-items: flex-start;
    border: 0.79px solid;
    border-color: var(--gravel);
    border-radius: 6.01px;
    display: flex;
    height: 12px;
    justify-content: flex-end;
    left: -1px;
    min-width: 12px;
    padding: 2.4px 4.9px;
    position: relative;
    top: -1px;
  }

  
  .result-bar-2 .vector-50 {
    height: 5px;
    width: 2px;
  }
  
  .result-bar-2 .frame-172-8 {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 4px;
    height: 16px;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  
.result-bar-2 .frame-14 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: center;
    position: relative;
  }

  .result-bar-2 .frame-175-2 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    position: relative;
  }
  
  .result-bar-2 .non-stop {
    margin-top: -1.00px;
    text-align: center;
  }

  .result-bar-2 .non {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .result-bar-2 .frame-1-55 {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    position: relative;
  }

  .result-bar-2 .frame-1-5 {
    align-items: flex-end;
    display: flex;
    flex-direction: column;

    justify-content: center;
    position: relative;
    width: 106px;
  }

   .result-bar-2 .frame-1-55 .frame-10 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
  }

   .result-bar-2 .frame-1-55 .frame-8 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 7px;
    justify-content: center;
    position: relative;
  }

   .result-bar-2 .frame-1-55 .frame-202-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    justify-content: center;
    padding: 0px 0px 0px 1.5px;
    position: relative;
  }


   .result-bar-2 .frame-1-55 .group-206 {
    margin-top: -4px;
    height: 10.6px;
    position: relative;
    width: 10.6px;
  }

  
   .result-bar-2 .frame-1-55 .frame-11 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: center;
    position: relative;
  }

   .result-bar-2 .frame-1-55 .frame-175-2 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    position: relative;
  }

   .result-bar-2 .frame-1-55 .address-2 {
    margin-top: -1.00px;
    text-align: center;
  }

  
   .result-bar-2 .frame-1-55 .address-17 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  
   .result-bar-2 .frame-214-1 {
    align-items: center;
    align-self: stretch;
    gap: 10px;
    justify-content: center;
    margin-right: -0.20px;
    padding: 1px 0px 0px;
  }
  
   .result-bar-2 .frame-203-15 {
    height: 18px;
    font-size: smaller;
  }
   .result-bar-2 .frame-203-3 {
    height: 14px;
    /* position: relative; */
    width: 2.2px;
    background-color: var(--pumice);
  }

   .result-bar-2 .frame-202-22 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 1px;
    position: relative;
  }

   .result-bar-2 .frame-99 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    position: relative;
  }

   .result-bar-2 .tashkent-2 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    white-space: pre;
    overflow: hidden;
    max-width: 80px;
    text-overflow: ellipsis;
  }

   .result-bar-2  .frame-1777 {
    flex: 0 0 auto;
    margin-right: -1.00px;
    position: relative;
  }

  /* .result-bar-2 .frame-201-3 {

  } */
  
  .result-bar-2 .x1-day {
    color: #d0021b;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    width: fit-content;
  }






  
  .result-bar-2 .text-398 {
    margin-top: -1.00px;
    position: relative;
    width: fit-content;
  }
  
  .result-bar-2 .text-1 {
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
  }

  .result-bar-2 .frame-13 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    position: relative;
  }
  
  .result-bar-2 .atq {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .result-bar-2 .place-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .result-bar-2 .frame-177 {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    flex: 0 0 auto;
    gap: 20px;
    margin-left: -0.50px;
    padding: 12px 15px;
    position: relative;
    width: 100%;
  }
  
  .result-bar-2 .frame-203 {
    align-items: flex-end;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: flex-end;
    position: relative;
  }
  
  
  .result-bar-2 .four-img-container {
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
  }
  
  .result-bar-2 .four-img-container.frame-174-7 {
    align-items: center;
    /* align-self: stretch; */
    display: flex;
    position: relative;
  }
  
  .result-bar-2 .four-img-container .frame-223-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 1px;
    position: relative;
    width: 100%;
  }
  
  .result-bar-2 .four-img-container .vistara_-logo-1-1 {
    height: 12px;
    margin-bottom: -1px;
    position: relative;
    width: 12px;
  }
  
  .result-bar-2 .four-img-container .frame-224-4 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 1px;
    justify-content: flex-end;
    position: relative;
    width: 100%;
  }
  
  .result-bar-2 .four-img-container .frame-173-4 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 2.69px;
    height: 10.5px;
    position: relative;
  }
  
  .result-bar-2 .four-img-container .unnamed {
    height: 10.5px;
    object-fit: cover;
    position: relative;
    width: 10.5px;
  }
  
  .result-bar-2 .four-img-container .frame-224 {
    align-items: flex-start;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 3.13px;
    justify-content: flex-end;
    position: relative;
  }
  
  .result-bar-2 .four-img-container .png-transparent-luft-5 {
    height: 12px;
    margin-top: -1px;
    width: 12px;
  }
  
  .result-bar-2 .four-img-container .png-transparent-luft-6 {
    object-fit: cover;
    position: relative;
  }

  .result-bar-2 .two-img-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 30px;
    position: relative;
    width: 30px;
  }
  .result-bar-2 .two-img-container .frame-233-5 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    position: relative;
    width: 100%;
  }
  .result-bar-2 .two-img-container .turkish-airlines-symbol-1-1 {
    height: 14.12px;
    position: relative;
    width: 15px;
  }
  
  .result-bar-2 .two-img-container .frame-234-1 {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    width: 100%;
  }

  .result-bar-2 .two-img-container .png-transparent-luft-4 {
    height: 15px;
    width: 15px;
  }

  .result-bar-2 .two-img-container .png-transparent-luft-6 {
    object-fit: cover;
    position: relative;
  }
  
  
.result-bar-2 .single-img-container.png-transparent-luft-5 {
    height: 24px;
    margin-top: -1px;
    width: 24px;
  }
  
  .result-bar-2 .single-img-container.png-transparent-luft-6 {
    object-fit: cover;
    position: relative;
  }

  
.result-bar-2 .three-img-container {
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
  }
  
  .result-bar-2 .three-img-container.frame-174-7 {
    align-items: center;
    align-self: stretch;
    min-width: 20px;
    display: flex;
    position: relative;
  }
  
  .result-bar-2 .three-img-container .frame-223-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 1px;
    position: relative;
    width: 100%;
  }
  
  .result-bar-2 .three-img-container .vistara_-logo-1-1 {
    height: 12px;
    margin-bottom: -1px;
    position: relative;
    width: 12px;
  }
  
  .result-bar-2 .three-img-container .frame-224-4 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 1px;
    justify-content: flex-end;
    position: relative;
    width: 100%;
  }
  
  .result-bar-2 .three-img-container .frame-173-4 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 2.69px;
    height: 10.5px;
    position: relative;
  }
  
  .result-bar-2 .three-img-container .unnamed {
    height: 10.5px;
    object-fit: cover;
    position: relative;
    width: 10.5px;
  }
  
  .result-bar-2 .three-img-container .frame-224 {
    align-items: flex-start;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 3.13px;
    justify-content: flex-end;
    position: relative;
  }
  
  .result-bar-2 .three-img-container .png-transparent-luft-5 {
    height: 12px;
    margin-top: -1px;
    width: 12px;
  }
  
  .result-bar-2 .three-img-container .png-transparent-luft-6 {
    object-fit: cover;
    position: relative;
  }

  .result-bar-2 .frame-173-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 2px;
    position: relative;
    width: 100%;
    /* padding-top: 3px; */
  }
  .result-bar-2 .indigo-spi {
    letter-spacing: 0;
    /* line-height: 16px; */
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .result-bar-2 .in-123-sj-123 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    max-width: 200px;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }

  .coupon-container {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: flex-end;
    padding: 2.75px 0px;
    position: relative;
  }

  .frame-171-5 .coupon-container {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: flex-end;
      padding-top: 2.75px;
    padding-bottom: 2.75px;
    padding-left: 10px;
    padding-right: 0px;
    position: relative;
  }

  .coupon-container .frame-230 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: flex-end;
    padding: 0px 11px;
    position: relative;
  }

  .coupon-container .frame-230.proxima {
    background-color: var(--mystic);
  }

  .coupon-container .frame-230.averta {
    background-color: var(--light-pale-bg);
  }
  
  .coupon-container .frame-203-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    /* padding: 4px 0px 7px; */
    position: relative;
  }  

  .coupon-container .frame-211 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    position: relative;
  }


  .coupon-container .frame-9 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    position: relative;
  }

  
.coupon-container .text-3 {
    -webkit-text-stroke: 1px var(--black);
    margin-left: -1.00px;
    margin-top: -2.00px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .coupon-container .frame-13 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    position: relative;
  }
  
  .coupon-container .frame {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }
  
  
  .coupon-container .deal-text {
margin-top: 2px;
margin-bottom: 2px;
  }

  .result-bar-2 .partially-refundable {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }


  
  .result-bar-2 .tooltip-container {
    position: relative;
    /* display: inline-block; */
  }
  
  .result-bar-2  .tooltip-content {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    top: -80px;
    /* bottom: -18%; */
    right: -120%;
    /* margin-left: -110px; */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .result-bar-2 .tooltip-container:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
  }

  
  
  .result-bar-2 .tags-container {
    min-height: 33px !important;
    background-color: var(--gallery);
    flex: 0 0 auto;
    display: flex;
    gap: 15px;
    padding-left: 15px;
    width: 100%;
    justify-content: space-between;
  }

  .result-bar-2 .tags-container.proxima {

    border-color: var(--pumice);
    border-top-style: solid;
    border-top-width: 0.5px;
  }

  .result-bar-2 .tags-container.averta {

    border-top: none;
  }

  
  .result-bar-2 .tags-container .frame-174-7 {
    align-items: center;
    align-self: stretch;
    display: flex;
    position: relative;
  }

  .result-bar-2 .tags-container .frame-223-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    padding: 6px 0px 6px 0px;
    position: relative;
  }

  .result-bar-2 .tags-container .frame-228 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }
 .result-bar-2  .tags-container .st-4 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    width: fit-content;
  }

  .result-bar-2 .tags-container .frame-228-1 {
    /* align-self: stretch; */
    flex: 0 0 auto;
    position: relative;
  }

  
  .result-bar-2 .tags-container .frame-207-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }


  .result-bar-2 .tags-container .frame-12 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    justify-content: flex-end;
    padding: 6px 0px 6px 0px;
    position: relative;
  }

  .result-bar-2 .tags-container .frame-208 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    padding: 0px 0px 1px;
    position: relative;
  }

  .result-bar-2 .tags-container .line-straight {
    height: 15px;
    width: 3.5px;
    color: var(--pumice);
  }

  .result-bar-2 .tags-container .frame-203-5 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
  }

