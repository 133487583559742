.add-cancellation-bar {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    padding: 0px 15px;
    margin-top: 20px;
    position: relative;
    width: 100%;
  }
  
.add-cancellation-bar .frame-272 {
    align-items: flex-start;
    align-self: stretch;
    background-color: #f1f1f9;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0px 15px;
    position: relative;
    width: 100%;
  }
.add-cancellation-bar .frame-252-1 {
    align-self: stretch;
    display: flex;
    gap: 10px;
    padding: 0px 20px 0px;
    width: 100%;
  }
.add-cancellation-bar .frame-252-3 {
    align-items: flex-start;
    flex: 0 0 auto;
    position: relative;
  }
      
  .add-cancellation-bar .unsure-about-your-trip {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

.add-cancellation-bar .frame-243-16 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    padding: 0px 20px;
    position: relative;
    width: 100%;
  }
  
.add-cancellation-bar .frame-243-17 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
  }

.add-cancellation-bar .frame-24-3 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
  }
.add-cancellation-bar .frame-24-8 {
    flex: 0 0 auto;
    position: relative;
  }

  .add-cancellation-bar .add-zero-cancellation {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .add-cancellation-bar .frame-243-18 {
    align-items: baseline;
    display: flex;
    flex: 0 0 auto;
    gap: 7px;
    position: relative;
  }

  .add-cancellation-bar .get-refund-of-upto {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

.add-cancellation-bar .frame-5 {
    align-items: baseline;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: flex-end;
    position: relative;
  }
  
.add-cancellation-bar .text-260 {
    margin-top: -2.74px;
    position: relative;
    width: fit-content;
  }

  
  .add-cancellation-bar .valign-text-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .add-cancellation-bar .if-you-cancel-before {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
.add-cancellation-bar .address-18 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  

  .add-cancellation-bar .of-departure-due-to-any-reason {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  .add-cancellation-bar .frame-246-6 {
    align-items: center;
    align-self: stretch;
    border-color: #d0d0c8;
    border-top-style: solid;
    border-top-width: 0.8px;
    display: flex;
    flex: 0 0 auto;
    gap: 14px;
    padding: 15px 20px 0px;
    position: relative;
    width: 100%;
  }
.add-cancellation-bar .frame-252-2 {
    display: inline-flex;
    flex-direction: column;
    gap: 1px;
    justify-content: flex-end;
  }

.add-cancellation-bar .frame-252-3 {
    align-items: flex-start;
    flex: 0 0 auto;
    position: relative;
  }


.add-cancellation-bar .text-261 {
    margin-top: -2.74px;
    position: relative;
    width: fit-content;
  }

  
  .add-cancellation-bar .all-travellers {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .add-cancellation-bar .frame-103-1 {
    cursor: pointer;
    align-items: center;
    background-color: var(--white);
    border: 1px solid;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: center;
    padding: 9px 20px;
    position: relative;
  }
  .add-cancellation-bar .frame-103-1.proxima {
    border-color: var(--endeavour);
  }
  .add-cancellation-bar .frame-103-1.averta {
    border-color: var(--burning-gold);
  }
  
  .add-cancellation-bar .add {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
