
.insurance-wrapper .frame-177-1 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--white);
    border-bottom-style: solid;
    border-bottom-width: 1.2px;
    border-color: var(--spindle);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    min-width: 123px;
    padding: 12px 0px;
    padding-right: 15px;
    position: relative;
    width: 100%;
  }
  
  .insurance-wrapper .frame-6 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    /* padding: 0px 15px; */
    position: relative;
    width: 100%;
  }
  
  
  .insurance-wrapper .frame-289-1 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    position: relative;
  }
  
  .insurance-wrapper .insurance {
    letter-spacing: 0;
    line-height: normal;
    /* margin-top: -1.00px; */
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  

  .insurance-wrapper .secure-your-trip-and-avail-benefits {
    letter-spacing: 0;
    /* line-height: 20px;
    margin-top: -1.00px; */
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .insurance-wrapper .v-ra-nbg4-u-rnq-lsn_h-wg3ve-a-1 {
    height: 28px;
    object-fit: cover;
    position: relative;
    width: 45.5px;
  }

  .insurance-wrapper .frame-243-21 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 15px;
    padding: 15px 0px;
    position: relative;
    width: 100%;
  }

  .insurance-wrapper .frame-244 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    position: relative;
  }
  
.insurance-wrapper .btn-wrapper {
    display: flex;
    gap: 8px;
}

.insurance-wrapper .frame-269-1 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 20px;
    overflow: hidden;
    overflow-x: hidden;
    /* overflow-x: scroll; */
    padding: 5px 15px;
    position: relative;
    width: 100%;
  }

  .insurance-wrapper .frame-264 {
    cursor: pointer;
    align-items: flex-start;
    background-color: var(--white);
    border-radius: 3px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 15px;
    overflow: hidden;
    padding: 15px 25px 15px 20px;
    position: relative;
  }
  .insurance-wrapper .frame-264.proxima {
    box-shadow: 0px 0px 5px 1px #0057b733;
  }
  .insurance-wrapper .frame-264.averta {
    box-shadow:  0px 0px 4px 1px var(--light-yellow);
  }

  .insurance-wrapper .frame-4 {
    flex: 0 0 auto;
    position: relative;
    cursor: pointer;
  }
  .insurance-wrapper .frame-2-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    position: relative;
  }

  .insurance-wrapper .trip-cancellation {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .insurance-wrapper .insurance-wrapper .frame-244 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    position: relative;
  }
  
.insurance-wrapper .upto {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .insurance-wrapper .frame-24-4 {
    align-items: normal;
    display: flex;
    gap: 4px;
  }
  .insurance-wrapper .frame-24-8 {
    flex: 0 0 auto;
    position: relative;
  }
  .insurance-wrapper .number-1 {
    margin-top: -1.00px;
  }
  .insurance-wrapper .number-18 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  
.insurance-wrapper .frame-293-3 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    /* gap: 5px; */
    padding: 0px 2px;
    position: relative;
  }


  .insurance-wrapper .frame-244-5 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 15px;
    padding: 15px 0px 5px;
    position: relative;
    width: 100%;
  }
  .insurance-wrapper .frame-244-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.insurance-wrapper .frame-5 {
    align-items: normal;
    display: flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: flex-end;
    position: relative;
  }
  .insurance-wrapper .number-10 {
    margin-top: -2.74px;
  }
  .insurance-wrapper .per-person {
    letter-spacing: 0;
    line-height: 20px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  
.insurance-wrapper .secure-my-trip {
    letter-spacing: 0;
    /* line-height: 20px;
    margin-top: -1.00px; */
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  
.insurance-wrapper .frame {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }
  
.insurance-wrapper .frame-100 {
    align-items: end;
    cursor: pointer;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }
  
.insurance-wrapper .frame-173-6 {
    align-items: center;
    display: flex;
    gap: 8.38px;
    height: 22px;
    position: relative;
    width: 22px;
  }

  .insurance-wrapper .rectangle-17-2 {
    border-color: var(--storm-dust);
  }

  .insurance-wrapper .rectangle-17-3 {
    border: 1.05px solid;
    border-radius: 27.76px;
    height: 22.42px;
    margin-bottom: -0.21px;
    margin-top: -0.21px;
    position: relative;
    width: 22px;
  }

  .insurance-wrapper .frame-3 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    position: relative;
  }

  .insurance-wrapper .place-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .insurance-wrapper .ill-book-without-insurance {
    letter-spacing: 0;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  .insurance-wrapper .please-select-to-proceed {
    height: 14px;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: 193px;
  }
  
  .insurance-wrapper .frame-2-12 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 25px;
    padding: 15px 0px 0px;
    position: relative;
    width: 100%;
  }

  .insurance-wrapper .buttons-1 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
    width: 100%;
  }

  .insurance-wrapper .frame-2-14 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 15px;
    position: relative;
  }

  .insurance-wrapper .frame-246-4 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 14px;
    position: relative;
    width: 100%;
  }

  .insurance-wrapper .by-adding-insurance {
    color: transparent;
    font-family: var(--font-family-proxima_nova-regular);
    font-size: var(--font-size-m);
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.80px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
