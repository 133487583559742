
.flights-section-results-page-1 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 6px 0px 0px;
  position: fixed;
  z-index: 10;
  width: 100%;
}

.flights-section-results-page-1.proxima {
  background-color: var(--mystic);
}
.flights-section-results-page-1.averta {
  background-color: var(--light-pale-bg);
}


.result-main-section-1 {
    /* align-items: flex-start; */
    /* align-self: stretch; */
    border-radius: 5px;
    display: flex;
    flex: 0 0 auto;
    gap: 25px;
    /* justify-content: center; */
    /* overflow: hidden; */
    padding: 5px 0px;
    /* position: relative; */
    flex-direction: column;
    /* width: 100%; */
    width: 1342px;
}


.result-page-flights-section {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    /* padding-bottom: 100px; */
  }

  .result-page-flights-section.proxima {
    padding: 118px 0px 0px;
    background-color: var(--mystic);
  }
  .result-page-flights-section.averta.yesPad {
    padding: 118px 0px 0px;
    background-color: var(--light-pale-bg);
  }
  .result-page-flights-section.averta.noPad {
    padding: 85px 0px 0px;
    background-color: var(--light-pale-bg);
  }

  .result-page-flights-section::-webkit-scrollbar {
    display: none;
    width: 0;
  }

  .plus {
    flex: 0 0 auto;
    position: relative;
  }

  .minus {
    flex: 0 0 auto;
    position: relative;
  }
  