
.flights-section-results-page {
  align-items: center;
  /* align-self: stretch; */
  background-color: var(--mystic);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow-y: hidden;
  padding: 20px 0px 0px;
  position: relative;
  width: 100%;
  }
.flights-section-results-page.proxima {
    background-color: var(--mystic);
  }
.flights-section-results-page.averta {
    background-color: var(--light-pale-bg);
  }


.result-main-section {
  align-items: flex-start;
  /* align-self: stretch; */
  border-radius: 5px;
  display: flex;
  flex: 0 0 auto;
  gap: 25px;
  /* justify-content: center; */
  overflow: hidden;
  /* padding: 0px 70px 25px; */
  position: relative;
  /* flex-direction: column; */
  width: 1342px;
  }

  .result-data-section::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  
  .result-data-section{
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 15px;
    width: 100%;
    height: 1053.33px;
    overflow: hidden;
    overflow-y: scroll;
    /* padding: 18px 0px 0px; */
    position: relative;
  }


.filters-section {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    /* margin-left: -5.00px; */
    position: relative;
  }

.results-section::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  
.results-section {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 15px;
    height: 1707px;
    /* margin-right: -5.00px; */
    overflow: hidden;
    overflow-y: scroll;
    /* padding: 18px 0px 0px; */
    position: relative;
  }

  