.css-gdh49b-MuiAutocomplete-listbox::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
  }
  
  .css-gdh49b-MuiAutocomplete-listbox::-webkit-scrollbar-track {
    background: var(--mystic); /* Color of the scrollbar track */
    border-radius: 10px;
  }
  
  .css-gdh49b-MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
    background-color: var(--rhino); /* Color of the scrollbar thumb */
    border-radius: 10px;
    border: 2px solid var(--mystic); /* Adds space around the thumb */
  }
  
  .css-gdh49b-MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover {
    background-color: var(--rhino); /* Color when hovering over the scrollbar thumb */
  }

  /* Scrollbar Buttons (Up Arrow) */
.css-gdh49b-MuiAutocomplete-listbox::-webkit-scrollbar-button:vertical:decrement {
    background: url('../../../../../Assets/Images/scroll-arrow-up-df.svg') no-repeat center center;
    /* background-size: 100% 100%; */
    transform: rotate(90deg);
    height: 16px;
    width: 10px;
    cursor: pointer;
  }
  
  /* Scrollbar Buttons (Down Arrow) */
  .css-gdh49b-MuiAutocomplete-listbox::-webkit-scrollbar-button:vertical:increment {
    background: url('../../../../../Assets/Images/scroll-arrow-down-df.svg') no-repeat center center;
    background-size: 100% 100%;
    height: 16px;
    width: 10px;
    cursor: pointer;
  }
