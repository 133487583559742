.seats-section {
  padding: 20px 15px;
  border: 0.8px solid var(--spindle-2);
  border-top: none;
  display: flex;
  width: 100%;
}
.seats-section .data-section {
  width: 30%;
  display: flex;
  flex-direction: column;
}
.seats-section .data-section .travellers-content {
  display: flex;
  flex-direction: column;
  min-height: 50%;
  gap: 20px;
}
.seats-section .data-section .travellers-content .traveller-row-data {
  display: flex;
}

.seats-section .data-section .plane-content {
  display: flex;
  /* margin-top: 50px; */
  flex-direction: column;
  gap: 20px;
  padding-left: 12px;
  width: 70%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
}

.seats-section .plane-section {
    width: 70%;
    flex-direction: column;
    max-height: 1000px;
    overflow-y: auto;
    overflow-x: hidden;
}

.seats-section .plane-section::-webkit-scrollbar {
  width: 7px; /* Width of the scrollbar */
}

.seats-section .plane-section::-webkit-scrollbar-track {
  border-radius: 10px;
}

.seats-section .plane-section.proxima::-webkit-scrollbar-track {
  background: var(--mystic); /* Color of the scrollbar track */
}
.seats-section .plane-section.averta::-webkit-scrollbar-track {
  background: var(--light-pale-bg); /* Color of the scrollbar track */
}

.seats-section .plane-section::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.seats-section .plane-section.proxima::-webkit-scrollbar-thumb {
  background-color: var(--rhino); /* Color of the scrollbar thumb */
  border: 2px solid var(--mystic); /* Adds space around the thumb */
}

.seats-section .plane-section.averta::-webkit-scrollbar-thumb {
  background-color: var(--blue-zodiac); /* Color of the scrollbar thumb */
  border: 2px solid var(--light-pale-bg); /* Adds space around the thumb */
}



  

  /* Scrollbar Buttons (Up Arrow) */
  .seats-section .plane-section::-webkit-scrollbar-button:vertical:decrement {
    background: url("../../../../../Assets/Images/scroll-arrow-up-df.svg")
      no-repeat center center;
    /* background-size: 100% 100%; */
    transform: rotate(90deg);
    height: 16px;
    width: 10px;
    cursor: pointer;
  }
  
  /* Scrollbar Buttons (Down Arrow) */
  .seats-section .plane-section::-webkit-scrollbar-button:vertical:increment {
    background: url("../../../../../Assets/Images/scroll-arrow-down-df.svg")
      no-repeat center center;
    background-size: 100% 100%;
    height: 16px;
    width: 10px;
    cursor: pointer;
  }

