.alphabet-row-wrapper {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    /* padding: 10px 0px; */
    position: relative;
    width: 100%;
  }

  .alphabet-row-wrapper .heading {
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

  }