.tripInfo {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px 10px 15px;
  }
.tripInfo.proxima {
  width: 89%;
  margin-left: 20px;
  background-color: var(--dark-purple);
  }
.tripInfo.averta {
  width: 90%;
  margin-left: 17px;
  background-color: var(--dark-galaxy);
  }

  .tripInfo.proxima::after {
    content: '';
    position: absolute;
    top: 83px; /* Adjust this value to position the arrow correctly */
    left: 50%;
    transform: translateX(-50%);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--rhino);
  }
  .tripInfo.averta::after {
    content: '';
    position: absolute;
    top: 88px; /* Adjust this value to position the arrow correctly */
    left: 50%;
    transform: translateX(-50%);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--rhino);
  }


  
.tripInfo .frame-238-5 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 1px 5px;
    position: relative;
    /* width: 100%; */
  }

  
.tripInfo .frame {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 2px;
    position: relative;
  }
  
  .tripInfo .frame-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }

  .tripInfo .flight-details {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .tripInfo .frame-239 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 15px;
    padding: 5px 5px;
    position: relative;
    /* width: 100%; */
  }
  
  .tripInfo .frame-238-6 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 2px;
    position: relative;
  }

  .tripInfo .frame-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }
  
  .tripInfo .place {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  .tripInfo .frame-170-1 {
    align-items: center;
    gap: 2px;
  }
  
  .tripInfo .frame-170-4 {
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    position: relative;
  }
  
  .tripInfo .place-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
    