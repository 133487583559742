

.tooltip-content .x3-stop-proxima-1 {
    align-items: center;
    background-color: var(--rhino);
    /* border: 1px solid; */
    border-color: transparent;
    display: inline-flex;
    flex-direction: column;
    height: max-content;
    justify-content: center;
    position: relative;
  }

  
  .tooltip-content::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--rhino) transparent transparent transparent;
  }

  
  .tooltip-content .x3-stop-proxima-1 .frame-205-1 {
    align-items: flex-start;
    align-self: stretch;
    /* background-color: var(--rhino) !important; */
    background-position: 50% 50%;
    background-size: cover;
    border: 1px solid;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: -0.11px;
    margin-top: -0.11px;
    padding: 14px 17px;
    width: 100%;
  }
  .tooltip-content .x3-stop-proxima-1 .frame-205-1.proxima {
    border-color: #001446;
    box-shadow: 0px 4px 4px #00000033;
  }
  
  .tooltip-content .x3-stop-proxima-1 .frame-205-1.averta {
    border-color: var(--gold-shadow);
    box-shadow: 0px 1px 5px var(--gold-shadow);
  }
  
  .tooltip-content .x3-stop-proxima-1 .frame-206-1 {
    align-items: center;
    display: inline-flex;
    gap: 50px;
    justify-content: center;
  }
  
  .tooltip-content .x3-stop-proxima-1 .frame-205-1-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
  }
  
  .tooltip-content .x3-stop-proxima-1 .frame-206-1-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 2.01px;
    padding: 1.51px;
    position: relative;
  }
  
  .tooltip-content .x3-stop-proxima-1 .vector-1 {
    height: 15.72px;
    margin-bottom: -1.51px;
    margin-left: -1.15px;
    margin-right: -1.15px;
    margin-top: -1.15px;
    position: relative;
    width: 12.28px;
  }
  
  .tooltip-content .x3-stop-proxima-1 .frame-206-2-1 {
    border-radius: 43.07px;
    height: 8px;
    left: 3px;
    position: absolute;
    top: 5px;
    width: 8px;
  }
  
  .tooltip-content .x3-stop-proxima-1 .stops-1 {
    color: var(--white);
    font-family: var(--font-family-proxima_nova-semibold);
    font-size: var(--font-size-20);
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
 .tooltip-content  .x3-stop-proxima-1 .frame-209-1 {
    align-items: center;
    display: inline-flex;
    gap: 7px;
    justify-content: center;
  }
  
 .tooltip-content  .x3-stop-proxima-1 .frame-207-1 {
    align-items: center;
    display: inline-flex;
    gap: 11.78px;
    justify-content: center;
    padding: 0.59px 1.18px;
  }
  
  .tooltip-content .x3-stop-proxima-1 .group-208-1 {
    height: 17.04px;
    position: relative;
    width: 13.32px;
  }
  
 .tooltip-content  .x3-stop-proxima-1 .ellipse-30-1 {
    border: 0.94px solid;
    border-color: var(--white);
    border-radius: 2.82px;
    height: 6px;
    left: 2px;
    position: absolute;
    top: -1px;
    width: 6px;
  }
  
 .tooltip-content  .x3-stop-proxima-1 .vector-container-1 {
    height: 13px;
    left: 0;
    position: absolute;
    top: 5px;
    width: 15px;
  }
  
 .tooltip-content  .x3-stop-proxima-1 .vector-84-1 {
    height: 8px;
    left: 0;
    position: absolute;
    top: 4px;
    width: 7px;
  }
  
  .tooltip-content .x3-stop-proxima-1 .vector-85-1 {
    height: 13px;
    left: 2px;
    position: absolute;
    top: 0;
    width: 12px;
  }
  
 .tooltip-content  .x3-stop-proxima-1 .group-206-1 {
    height: 6px;
    left: 11px;
    position: absolute;
    top: -2px;
    width: 6px;
  }
  
 .tooltip-content  .x3-stop-proxima-1 .overlap-group1-1 {
    align-items: flex-start;
    border: 0.82px solid;
    border-color: var(--white);
    border-radius: 3.96px;
    display: flex;
    height: 8px;
    left: -1px;
    min-width: 8px;
    padding: 1.5px 1.9px;
    position: relative;
    top: -1px;
  }
  
 .tooltip-content  .x3-stop-proxima-1 .vector-50-1 {
    height: 3px;
    width: 2px;
  }
  
 .tooltip-content  .x3-stop-proxima-1 .layover-1 {
    color: var(--white);
    font-family: var(--font-family-proxima_nova-semibold);
    font-size: var(--font-size-20);
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
 .tooltip-content  .x3-stop-proxima-1 .frame-205-2-1 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 9px;
    position: relative;
    width: 100%;
  }
  
 .tooltip-content  .x3-stop-proxima-1 .frame-21-1 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
 .tooltip-content  .x3-stop-proxima-1 .tas-tashkent-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
 .tooltip-content  .x3-stop-proxima-1 .x2h-19m-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
 .tooltip-content  .x3-stop-proxima-1 .ist-istanbul-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
 .tooltip-content  .x3-stop-proxima-1 .x1h-35m-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .x3-stop-proxima .otp-bucharest-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    width: 92px;
  }
  
 .tooltip-content  .x3-stop-proxima-1 .x3h-5m-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
 .tooltip-content .x3-stop-proxima-1 .frame-205-3-1 {
    border-color: var(--rhino);
    border-top-style: solid;
    border-top-width: 1px;
    height: 10px;
    left: 97px;
    position: absolute;
    width: 15px;
  }
  
 .tooltip-content  .x3-stop-proxima-1 .polygon-7-1 {
    height: 17px;
    left: -5px;
    width: 25px;
  }
  
  .tooltip-content .x3-stop-proxima-1 .polygon-8-1 {
    height: 6px;
    left: -1px;
    width: 18px;
  }
  
 .tooltip-content  .x3-stop-proxima-1 .frame-1 {
    flex: 0 0 auto;
    position: relative;
  }
  
 .tooltip-content  .x3-stop-proxima-1 .polygon-1 {
    position: absolute;
    top: 2px;
  }
