.error-message-bar{
    display: flex;
    align-items: end;
    width: max-content;
    z-index: 1;
    background-color: #FFE0DA;
    position: relative;
    top: -15px;
    padding: 4px 8px;
}
.error-message-bar::after{
    top: 100%;
    left: 30%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #ffd3d4;
    border-width: 8px;
    margin-left: -8px;
}