.scrollable-container {
    display: flex;
    align-items: center;

  }
  
  .scroll-content {
    display: flex;
    width: 97%;
    margin: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;

  }
  
  .scroll-btn-img {
 cursor: pointer;
 position: absolute;
 
  }
  .scroll-btn-img.back{
    margin-left: 8px;
    margin-top: 24px;

  }
  .scroll-btn-img.front{
    margin-left: 65%;
    margin-top: 24px;
  }
  
  .scroll-btn.left {
    margin-right: 10px;
  }
  
  .scroll-btn.right {
    margin-left: 10px;
  }
  
  .selector-wrapper {
    padding-right: 15px;
  }
  
  .selector-wrapper .selector-item {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .selector-wrapper .selector-item .trippers {
    border-radius: 4px;
    background-color: var(--winter-sunlight);
    padding: 4px 6px;
    display: flex;
    width: max-content;
  }
  .selector-wrapper .selector-item .trippers .info{
display: flex;
gap: 10px;
  }
  
  .selector-wrapper .selector-item .item {
    cursor: pointer;
    padding: 10px 15px;
    border: 2.5px solid transparent;
  }
  
  .selector-wrapper .selector-item .item.selected.proxima {
    background-color: white;
    border-radius: 4px;
    border: 2.5px solid var(--endeavour);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  .selector-wrapper .selector-item .item.selected.averta {
    background-color: white;
    border-radius: 4px;
    border: 2.5px solid var(--choclate);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .main-trip-container {
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;
  }
  