.fare-upgrade-bar {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  
.fare-upgrade-bar .frame-24 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

.fare-upgrade-bar .frame-24-2 {
    align-items: center;
    display: inline-flex;
    gap: 8px;
  }
  
.fare-upgrade-bar .frame-24-8 {
    flex: 0 0 auto;
    position: relative;
  }

  
.fare-upgrade-bar .frame-2-5 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    position: relative;
  }
  
.fare-upgrade-bar .more-fares-available {
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
.fare-upgrade-bar .with-flexible {
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  
.fare-upgrade-bar .fare-rules-1 {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
.fare-upgrade-bar .frame-250-2.proxima {
    background: linear-gradient(180deg, rgb(36, 153, 149) 0%, rgb(27.39, 126.44, 123.05) 100%);
    padding: 13px 20px;
  }
.fare-upgrade-bar .frame-250-2.averta {
    background: linear-gradient(180deg, rgb(36, 153, 149) 0%, rgb(27.39, 126.44, 123.05));
    padding: 13px 20px;
  }
  
.fare-upgrade-bar .frame-250-3 {
    cursor: pointer;
    padding: 9px 0px;
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }
  
.fare-upgrade-bar .upgrade-fare {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  