/* screen - calendar-departure-overlay-proxima2 */

.calendar-valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.calendar-departure-overlay-proxima2 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    overflow-x: hidden;
    overflow-y: hidden;
    /* padding: 35px 0px 16px; */
    position: relative;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-frame-109 {
    align-items: flex-start;
    display: inline-flex;
    gap: 10px;
    left: 0;
    position: absolute;
    top: 0;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-departure-hover {
    align-items: flex-start;
    background-color: #e7f2ff;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--pumice);
    border-left-style: solid;
    border-left-width: 1px;
    border-top-style: solid;
    border-top-width: 1px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    padding: 12px;
    position: relative;
    width: 165px;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-frame-103 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 6px;
    position: relative;
    width: 100%;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-frame-205 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-frame-205-item {
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-text-7 {
    line-height: 16px;
    margin-top: -1.00px;
    transform: rotate(180deg);
  }

  
  .calendar-departure-overlay-proxima2 .calendar-calendar {
    align-items: flex-start;
    background-color: var(--white);
    border: 1.2px solid;
    border-color: #e6e6e6;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 15px;
    margin-bottom: -1.20px;
    margin-left: -1.20px;
    margin-right: -1.20px;
    margin-top: -1.20px;
    padding: 10px 0px 20px;
    position: relative;
  }
  
  .cal-head {
    margin-top: 10px;
    margin-bottom: 10px;
width: 100%;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-frame-111 {
    height: 37px;
    align-items: center;
    box-shadow: 0 2px 4px #00000012;
    width: 100%;
    display: flex;
    flex: 1;
    flex-grow: 1;
    /* gap: 10px; */
    padding-left: 15px;
    padding-right: 15px;
    justify-content: space-between;
    /* padding: 0px 0px 7px;
    position: relative; */
  }
  
  .calendar-departure-overlay-proxima2 .calendar-frame-109-2 {
    align-items: center;
    margin-top: 10px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-number-of-days {
    letter-spacing: 0;
    line-height: 14px;
    margin-top: -1.00px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-frame-109-3 {
    align-items: flex-start;
    margin-top: 5px;
    border: 1px solid;
    border-color: var(--storm-dust);
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 4px;
    position: relative;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-number {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-check {
    margin-top: 2px;
    cursor: pointer;
    flex: 0 0 auto;
    position: relative;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-frame-109-4 {
    align-items: flex-end;
    display: inline-flex;
    gap: 30px;
    min-height: 30px;

    padding-top: 10px;
    /* left: 15px;
    position: absolute;
    top: 8px; */
  }
  
  .calendar-departure-overlay-proxima2 .calendar-frame-109-5 {
    align-items: center;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--endeavour);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    padding: 0px 5px 8px 0px;
    position: relative;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-frame-203 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-address {
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    width: fit-content;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-frame-110 {
    /* background-color: red; */
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    padding: 0px 5px 8px 0px;
    position: relative;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-calendar-1 {
    height: 20px;
    position: relative;
    width: 20px;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-address-1 {
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-frame-45 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    padding: 0px 15px;
    margin-bottom: 20px;
    position: relative;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-frame-103-1 {
    /* margin-top: 45px; */
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 30px;
    position: relative;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-month-2024-01-february {
    width: 302.84px;
    align-items: center;
    /* border-radius: 5.21px; */
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-header {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-atoms-mouth {
    align-items: center;
    background-color: var(--white);
    border-radius: 4.75px;
    display: flex;
    gap: 5px;
    height: 32.54px;
    justify-content: center;
    padding: 2.48px 9.9px;
    position: relative;
    width: 151.81px;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-text {
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-text.averta:hover {
 color: var(--text-pale-bg);

  }
  
  .calendar-departure-overlay-proxima2 .calendar-arrow {
    flex: 0 0 auto;
    position: relative;
  }

  .calendar-departure-overlay-proxima2 .calendar-arrow-rotated {
    flex: 0 0 auto;
    position: relative;
    rotate: 90degree;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-arrow-1 {
    left: 0;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-grid {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-line {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 6px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-atoms-week-dey {
    align-items: center;
    border-radius: 4.75px;
    display: flex;
    height: 28.52px;
    justify-content: center;
    position: relative;
    width: 38.12px;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-line-1 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    gap: 4px 4px;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-atoms-dey-number {
    border-radius: 4.4px;
    position: relative;
    width: 38.12px;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-atoms-dey-number-1 {
    align-items: center;
    /* border-radius: 4.4px; */
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    padding: 3.71px 5.56px;
    min-height: 33px;
    position: relative;
    /* min-width: 30px !important; */
  }
  
  .calendar-departure-overlay-proxima2 .calendar-text-1 {
    line-height: normal;
    margin-top: -1.10px;
    text-align: center;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-atoms-dey-number-4 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    padding: 3.71px 5.56px;
    position: relative;
  }
  .calendar-departure-overlay-proxima2 .calendar-atoms-dey-number-4.proxima {
    background-color: var(--endeavour);
  }
  .calendar-departure-overlay-proxima2 .calendar-atoms-dey-number-4.averta {
    background-color: var(--optera-galaxy);
  }
  
  .calendar-departure-overlay-proxima2 .calendar-text-2 {
    font-weight: 400;
    line-height: normal;
    margin-top: -1.10px;
    text-align: center;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-text-3 {
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-atoms-dey-number-2 {
    border-radius: 4.4px;
    position: relative;
    width: 35.22px;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-atoms-dey-number-3 {
    border-radius: 4.4px;
    flex: 0 0 auto;
    position: relative;
  }
  .calendar-departure-overlay-proxima2 .calendar-date-box.caledner-active-dates.proxima:hover {
    background-color: var(--light-sky-bg-hov);
    color: rgb(0, 87, 183) !important;
  }
  .calendar-departure-overlay-proxima2 .calendar-date-box.caledner-active-dates.averta:hover {
    background-color: var(--light-pale-bg-hov);
    /* color: RED !important; */
  }
  
  .calendar-departure-overlay-proxima2 .calendar-header-1 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-atoms-mouth-1 {
    align-items: center;
    background-color: var(--white);
    border-radius: 4.75px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    height: 32.54px;
    justify-content: center;
    padding: 2.48px 9.9px;
    position: relative;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-vector {
    height: 4.72px;
    position: relative;
    width: 8.58px;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-arrow-2 {
    left: 294px;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-atoms-dey-number-5 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    padding: 3.71px 5.56px;
    position: relative;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-arrow-3 {
    height: 16px;
    position: absolute;
    top: 8px;
    width: 9px;
  }
  .calendar-departure-overlay-proxima2 .calendar-arrow-33 {
    height: 16px;
    position: absolute;
    top: 8px;
    width: 9px;
    margin-left: -3px;
  }
  
  .calendar-departure-overlay-proxima2 .calendar-text-4 {
    letter-spacing: 0;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  