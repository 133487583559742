 
.search-bar {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    padding: 0px 104.5px;
    position: relative;
    width: 100%;
  }
  
  
  .frame-35 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 700px;
    padding: 0px 25px;
    position: relative;
  }
  
  .frame-28 {
    align-items: flex-start;
    background-color: var(--white);
    border-right: 1px solid;
    border-color: var(--pumice);
    display: flex;
    flex-direction: column;
    gap: 9px;
    /* left: 0; */
    padding: 9px 12px;
    /* position: absolute; */
    top: 0;
    width: 300px;
    min-height: 94px;
  }
  
  .frame-28:hover {
    background-color: #E8F3FF;
  }
  
  
  .place-2 {
    align-self: stretch;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
  }
  
  .del-indira-gandhi-airport {
    align-self: stretch;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
  }
  
  .to {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .airplane-landing-arrival-1 {
    height: 30px;
    left: 148px;
    position: absolute;
    top: 0;
    width: 38px;
  }
  
  .place-3 {
    align-self: stretch;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
  }
  
  .atq-amritsar-airport {
    align-self: stretch;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
  }
  
  .exchange-alt {
    height: 25px;
    left: 290px;
    position: absolute;
    top: 35px;
    width: 17px;
  }
  
  .frame-30 {
    align-items: flex-start;
    /* border-radius: 5px; */
    /* margin-top: 1px; */
    display: flex;
    flex-direction: column;
    gap: 9px;
    justify-content: center;
    overflow: hidden;
    padding: 9px 12px;
    position: relative;
    border-right: 1px solid var(--pumice);
    min-height: 94px;
    /* border-bottom: 1px solid var(--pumice); */
    /* border-top: 1px solid var(--pumice); */
    width: 165px;
  }
  
  .frame-30:hover {
    background-color: #E8F3FF;
  }
  
  .departure {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .frame-31 {
    align-items: center;
    /* margin-top: 1px; */
    display: flex;
    flex-direction: column;
    gap: 9px;
    justify-content: center;
    overflow: hidden;
    padding: 9px 12px;
    min-height: 94px;
    position: relative;
    border-right: 1px solid var(--pumice);
    /* border-bottom: 1px solid var(--pumice);
    border-top: 1px solid var(--pumice); */
    width: 165px;
  }
  
  .frame-31:hover {
    background-color: #E8F3FF;
  }
  
  .return {
    align-self: stretch;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
  }
  
  .travellers-and-class {
    align-self: stretch;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
  }
  
  .place-4 {
    align-self: stretch;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
  }


  
.frame-37 {
  align-items: center;
  align-self: stretch;
  min-height: 45px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 25px;
  
  position: relative;
  width: 100%;
}

.frame-43 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  justify-content: center;
  position: relative;
}



.fare-type {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}



.frame-47 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 21px;
  position: relative;
}

.frame-42 {
  cursor: pointer;
  align-items: center;
  /* min-width: 145px; */
  background-color: var(--white);
  border-right: 1px solid var(--pumice);

  /* margin-right: -2px; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  /* width: 130px; */
}

.special-fares {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: 130px !important;
}

.fares {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 60px;
}

.frame-205 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  justify-content: center;
  position: relative;
}

.frame-205-1 {
  align-items: center;
  /* background-color: var(--pumice); */
  display: inline-flex;
  gap: 1px;
  justify-content: center;
  /* padding: 1px; */
}
  
.frame-205-44 {
  flex: 0 0 auto;
  position: relative;
      border-top: 1px solid var(--pumice);
    border-bottom: 1px solid var(--pumice);
    border-left: 1px solid var(--pumice);
}
.frame-205-4 {
  flex: 0 0 auto;
  position: relative;
}



.frame-43-1 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 0px 25px;
  position: relative;
  width: 100%;
}


.recent-searches {
  letter-spacing: 0;
  line-height: 16px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.long-arrow-alt-right {
  height: 13px;
  position: relative;
  width: 13px;
}

.fri-6-jan-24 {
  letter-spacing: 0;
  line-height: 9.7px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-205-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  width: 100%;
}

.fri-6-jan-24-1 {
  /* flex: 1; */
  letter-spacing: 0;
  line-height: 9.7px;
  margin-top: -1.00px;
  position: relative;
}

.sat-7-jan-24 {
  letter-spacing: 0;
  line-height: 9.7px;
  margin-top: -1.00px;
  position: relative;
}

.frame-44 {
  cursor: pointer;
}


.frame-32-2 {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 9px;
  padding: 9px 12px;
  position: relative;
  width: 412px;
  margin-top: 1px;
}
